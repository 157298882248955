import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { EmployeeService } from '../core/services/employee.service';
import {environment} from '../../environments/environment';

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss']
})
export class LayoutComponent implements OnInit {
  header: string = 'Employee Services';
  isStrategicMode: boolean = false;
  isAdminOfStrategicProject: boolean = false;
  isProd = environment.production
  hrtimesheet = environment.hrtimesheet

  constructor(
    private router: Router,
    private employeeService: EmployeeService,
  ) { }

  ngOnInit(): void {
    let route = this.router.url;
    if(route.includes('strategic-plan')) {
      this.header = 'Strategic plans & Milestone tracking';
      this.isStrategicMode = true;
      this.checkUserRoleOfStrategicProject();
    }
  }

  checkUserRoleOfStrategicProject() {
    this.employeeService.me().toPromise()
    .then(res => {
      if (res.deptRelation.deptID === '100046' || res.deptRelation.deptID === '100060' || res.deptRelation.deptID === '600029') {
      // if (res.deptRelation.deptID === '100046') {
        this.isAdminOfStrategicProject = true;
      }
      // else if(res.deptRelation.deptID === '100060' && !environment.production) {
      //   this.isAdminOfStrategicProject = true;
      // }
    })
    .catch(err => {
      console.log('err',err);
    })
  }

  onRouteToStrategicPlan() {
    let path = 'https://setth.sharepoint.com/sites/intranet/Lists/SPKPI/AllItems.aspx';
    window.open(path, '_blank')
  }

}

import { Component, OnInit, TemplateRef } from '@angular/core';

import { faIcon } from 'src/app/core/icon/fa.icon';
import Swal from 'sweetalert2';
import { EmployeeService } from 'src/app/core/services/employee.service';
import { Employee } from 'src/app/core/models/employee';
import { BsModalRef, BsModalService, isArray, TypeaheadMatch } from 'ngx-bootstrap';
import { PageChangedEvent } from 'ngx-bootstrap/pagination';
import { PagerModel } from 'ngx-bootstrap/pagination/models';
import { StrategicProjectTrackingService } from 'src/app/core/services/strategic-project-tracking.service';
import { StrategicProjectTracking, StrategicProjectWorkflowStatus } from 'src/app/core/models/strategicProjectTracking';
import { ProjectTarget } from 'src/app/core/models/projectTarget';
import { FormBuilder, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { ExportToCsv } from 'export-to-csv';
import { Milestone } from 'src/app/core/models/mileStone';
import { environment } from 'src/environments/environment';
import { RequestTrainingService } from 'src/app/core/services/request-training.service';
import { UserRole } from 'src/app/core/models/userRole';
@Component({
    selector: 'app-strategic-project',
    templateUrl: './strategic-project.component.html',
    styleUrls: ['./strategic-project.component.scss'],
})

export class StrategicProjectComponent implements OnInit {
    faIcon = faIcon;
    modalRef: BsModalRef;

    displayItem: string = "list";
    selectedYear: number;
    allYear: number[];
    allQuarter = ['Q1', 'Q2', 'Q3', 'Q4'];
    selectedQuarter = '';
    strategicProjectList: StrategicProjectTracking[] = [];
    strategicProjectTitle = '';
    employeeDetail: Employee;
    mileStoneDetailByQuarter;
    strategicProjectData;
    strategicProjectName = '';
    strategicForm = this.fb.group({
        projectName: [{ value: '', disabled: false }, [Validators.required]]
    });
    isAdminOfStrategicProject: boolean = false;
    topic: string = 'Strategic projects';
    strategicProjectListPerPage: StrategicProjectTracking[] = [];
    currentPage = 1;
    paginationConfig: PagerModel = {
        itemsPerPage: 10,
        previousText: 'ย้อนกลับ',
        nextText: 'ถัดไป',
        pageBtnClass: 'btn btn-warning',
        align: true,
    };
    isSelectedAll: boolean = false;
    id: string = '';
    allName;
    allNameByEmpID;
    confirmationWording = '';
    confirmationStatus = '';
    isLoading = false;
    optionsJsonToEx = {
        filename: 'strategic-plan',
        fieldSeparator: ',',
        quoteStrings: '"',
        decimalSeparator: '.',
        showLabels: true,
        useTextFile: false,
        useBom: true,
        useKeysAsHeaders: true
    };
    isApprover = false;
    isReviewer = false;
    isCreator = false;
    quarter: string = '';
    userRole: UserRole[] = [];

    constructor(
        private fb: FormBuilder,
        private employeeService: EmployeeService,
        private strategicProjectTrackingService: StrategicProjectTrackingService,
        private router: Router,
        private activateRoute: ActivatedRoute,
        private modalService: BsModalService,
        private requestTraining: RequestTrainingService,
    ) {
        const id = this.activateRoute.snapshot.params.id;
        const quarter = this.activateRoute.snapshot.params.quarter;
        if (id) {
            this.id = id;
            this.quarter = quarter;
        }
    }

    ngOnInit() {
        this.initData();
    }

    async initData() {
        this.employeeDetail = await this.employeeService.me().toPromise();
        this.getRole();
        this.allYear = (await this.strategicProjectTrackingService.getStrategicProjectTrackingYear().toPromise()).filter(item => { return item !== 0; }).sort((a, b) => Number(b) - Number(a));
        this.selectedYear = Array.isArray(this.allYear) && this.allYear.length > 0 ? this.allYear[0] : 0;
        let month = new Date().getMonth();
        this.selectedQuarter = this.selectedQuarter ? this.selectedQuarter : `Q${this.calculateQuarter(+month)}`;
        this.strategicProjectName = '';
        this.strategicForm.patchValue({
            projectName: ''
        });
        this.allName = (await this.strategicProjectTrackingService.getNameByEmail().toPromise());
        this.allNameByEmpID = (await this.strategicProjectTrackingService.getNameByEmpID().toPromise());
        // user for test
        if ((this.employeeDetail.deptRelation.deptID === '100046' || this.employeeDetail.deptRelation.deptID === '100060' || this.employeeDetail.deptRelation.deptID === '600029') && this.router.url.includes('administrative-strategic')) {
            // if((this.employeeDetail.deptRelation.deptID === '100046' || (this.employeeDetail.deptRelation.deptID === '100060' && !environment.production)) && this.router.url.includes('administrative-strategic')) {
            this.isAdminOfStrategicProject = true;
            this.topic = 'Admin page';
            this.getAllStrategicProjectDetail();
            if (this.id) {
                this.getStrategicPlanById();
            }
        } else {
            this.topic = 'Strategic projects';
            this.isAdminOfStrategicProject = false;
            if (this.hrAdminAndHRVP()) {
                this.getAllStrategicProjectDetail();
            } else if (this.employeeDetail.deptRelation) {
                if (this.employeeDetail.deptRelation.department === '100005' || this.employeeDetail.deptRelation.department === '100002' || this.employeeDetail.deptRelation.department === '100018' || this.employeeDetail.cluster.toUpperCase() === 'AAA') {
                    this.getAllStrategicProjectDetail();
                } else {
                    this.getStrategicProjectDetailBySession();
                }
            } else {
                this.getStrategicProjectDetailBySession();
            }

            if (this.id) {
                this.getStrategicPlanById();
            }
        }
    }

    getAllStrategicProjectDetail() {
        let period = this.calculatePeriod();
        this.strategicProjectTrackingService.getAllStrategicProjectTracking(true, false, this.selectedYear, period).toPromise()
            .then(res => {
                this.strategicProjectList = res;
                if (Array.isArray(this.strategicProjectList)) {
                    if (this.isAdminOfStrategicProject) {
                        this.strategicProjectList.forEach(item => {
                            if (Array.isArray(item.targets)) {
                                for (let i = 0; i < item.targets.length; i++) {
                                    if (item.targets[i].corporateKPIs) {
                                        item.isSelected = true;
                                        item.isCorporateKPIs = true;
                                        break;
                                    } else if (this.getMilestoneDetail(item.targets[i])) {
                                        item.isSelected = true;
                                        break;
                                    } else {
                                        item.isSelected = false;
                                    }
                                }
                            }
                        });
                    }
                    const startItem = (this.currentPage - 1) * 10;
                    const endItem = this.currentPage * 10;
                    this.strategicProjectListPerPage = this.strategicProjectList.slice(startItem, endItem);
                }
            })
            .catch(err => {
                console.log('err =>', err);
            });
    }

    getStrategicProjectDetailBySession() {
        let period = this.calculatePeriod();
        this.strategicProjectTrackingService.getAllStrategicProjectTracking(false, false, this.selectedYear, period).toPromise()
            .then(res => {
                this.strategicProjectList = res;
                if (Array.isArray(this.strategicProjectList)) {
                    this.checkIsApprover();
                    this.checkIsReviewer();
                    this.checkIsCreator();
                    this.strategicProjectList.forEach(item => {
                        if (Array.isArray(item.targets)) {
                            for (let i = 0; i < item.targets.length; i++) {
                                if (item.targets[i].corporateKPIs) {
                                    item.isCorporateKPIs = true;
                                    break;
                                }
                            }
                        }
                    });

                    const startItem = (this.currentPage - 1) * 10;
                    const endItem = this.currentPage * 10;
                    this.strategicProjectListPerPage = this.strategicProjectList.slice(startItem, endItem);
                }
            })
            .catch(err => {
                console.log('err =>', err);
            });
    }

    onBack() {
        if (!this.isAdminOfStrategicProject) {
            this.router.navigate(['/strategic-plan']);
        }
        this.displayItem = 'list';
        this.currentPage = this.currentPage ? this.currentPage : 1;
        this.selectedQuarter = this.selectedQuarter ? this.selectedQuarter : '';

        this.initData();
    }

    onChangeYear(data) {
        this.currentPage = 1;
        if (this.isAdminOfStrategicProject) {
            this.getAllStrategicProjectDetail();
        } else {
            if (this.hrAdminAndHRVP()) {
                this.getAllStrategicProjectDetail();
            } else if (this.employeeDetail.deptRelation) {
                if (this.employeeDetail.deptRelation.department === '100005' || this.employeeDetail.deptRelation.department === '100002' || this.employeeDetail.deptRelation.department === '100018' || this.employeeDetail.cluster.toUpperCase() === 'AAA') {
                    this.getAllStrategicProjectDetail();
                } else {
                    this.getStrategicProjectDetailBySession();
                }
            } else {
                this.getStrategicProjectDetailBySession();
            }
        }
    }

    onChangeQuarter(data) {
        if (this.isAdminOfStrategicProject) {
            this.strategicProjectList.forEach(item => {
                if (Array.isArray(item.targets)) {
                    for (let i = 0; i < item.targets.length; i++) {
                        if (item.targets[i].corporateKPIs) {
                            item.isSelected = true;
                            item.isCorporateKPIs = true;
                            break;
                        } else if (this.getMilestoneDetail(item.targets[i])) {
                            item.isSelected = true;
                            break;
                        } else {
                            item.isSelected = false;
                        }
                    }
                }

                if (item.budgetProject.length > 0) {
                    let period = this.calculatePeriod();
                    let year = this.selectedYear - 543;
                    this.strategicProjectTrackingService.getBudgetByProject(year, period, item.budgetProject).toPromise()
                        .then(res => {
                            item.budgetUtilization = res.budgetUtilization;
                            item.opex = res.opex;
                            item.capex = res.capex;
                        })
                        .catch(err => { console.log('err', err); });
                }
            });
        }
        const startItem = (this.currentPage - 1) * 10;
        const endItem = this.currentPage * 10;
        this.strategicProjectListPerPage = this.strategicProjectList.slice(startItem, endItem);
        this.checkIsReviewer();
        this.checkIsApprover();
        this.checkIsCreator();
    }

    getMilestoneStatus(targetData) {
        switch (this.selectedQuarter) {
            case 'Q1':
                return targetData.milestoneQ1 ? targetData.milestoneQ1.status : '';
            case 'Q2':
                return targetData.milestoneQ2 ? targetData.milestoneQ2.status : '';
            case 'Q3':
                return targetData.milestoneQ3 ? targetData.milestoneQ3.status : '';
            case 'Q4':
                return targetData.milestoneQ4 ? targetData.milestoneQ4.status : '';
            default:
                return '';
        }
    }

    hrAdminAndHRVP() {
        return this.userRole.some(r => ['HRAdmin', 'HRVP'].includes(r.role));
    }

    getMilestoneDetail(targetData) {
        switch (this.selectedQuarter) {
            case 'Q1':
                return targetData.milestoneQ1 ? targetData.milestoneQ1.milestoneDetail : '';
            case 'Q2':
                return targetData.milestoneQ2 ? targetData.milestoneQ2.milestoneDetail : '';
            case 'Q3':
                return targetData.milestoneQ3 ? targetData.milestoneQ3.milestoneDetail : '';
            case 'Q4':
                return targetData.milestoneQ4 ? targetData.milestoneQ4.milestoneDetail : '';
            default:
                return '';
        }
    }

    getProgressDetail(targetData) {
        switch (this.selectedQuarter) {
            case 'Q1':
                return targetData.milestoneQ1 ? targetData.milestoneQ1.progressDetail : '';
            case 'Q2':
                return targetData.milestoneQ2 ? targetData.milestoneQ2.progressDetail : '';
            case 'Q3':
                return targetData.milestoneQ3 ? targetData.milestoneQ3.progressDetail : '';
            case 'Q4':
                return targetData.milestoneQ4 ? targetData.milestoneQ4.progressDetail : '';
            default:
                return '';
        }
    }

    getLastUpdate(targetData: ProjectTarget, status: Array<StrategicProjectWorkflowStatus>) {
        let statusByQuarter;
        if (Array.isArray(status)) {
            statusByQuarter = status.find(data => { return data.quarter === this.selectedQuarter; });
        }

        switch (this.selectedQuarter) {
            case 'Q1':
                if (targetData.milestoneQ1 && statusByQuarter) {
                    return targetData.milestoneQ1.updateDate > statusByQuarter.updateDate ? targetData.milestoneQ1.updateDate : statusByQuarter.updateDate;
                } else if (statusByQuarter) {
                    return statusByQuarter.updateDate;
                } else {
                    return targetData.milestoneQ1.updateDate;
                }
            case 'Q2':
                if (targetData.milestoneQ2 && statusByQuarter) {
                    return targetData.milestoneQ2.updateDate > statusByQuarter.updateDate ? targetData.milestoneQ2.updateDate : statusByQuarter.updateDate;
                } else if (statusByQuarter) {
                    return statusByQuarter.updateDate;
                } else {
                    return targetData.milestoneQ2.updateDate;
                }
            case 'Q3':
                if (targetData.milestoneQ3 && statusByQuarter) {
                    return targetData.milestoneQ3.updateDate > statusByQuarter.updateDate ? targetData.milestoneQ3.updateDate : statusByQuarter.updateDate;
                } else if (statusByQuarter) {
                    return statusByQuarter.updateDate;
                } else {
                    return targetData.milestoneQ3.updateDate;
                }
            case 'Q4':
                if (targetData.milestoneQ4 && statusByQuarter) {
                    return targetData.milestoneQ4.updateDate > statusByQuarter.updateDate ? targetData.milestoneQ4.updateDate : statusByQuarter.updateDate;
                } else if (statusByQuarter) {
                    return statusByQuarter.updateDate;
                } else {
                    return targetData.milestoneQ4.updateDate;
                }
            default:
                return '';
        }
    }

    filterDuplicateLastUpdate(targets: Array<ProjectTarget>) {
        return targets.filter((v, i) => targets.findIndex(item => item.updateBy === v.updateBy) === i);
    }

    getLastUpdateBy(targetData: ProjectTarget, status: Array<StrategicProjectWorkflowStatus>) {
        let statusByQuarter;
        if (Array.isArray(status)) {
            statusByQuarter = status.find(data => { return data.quarter === this.selectedQuarter; });
        }

        switch (this.selectedQuarter) {
            case 'Q1':
                if (targetData.milestoneQ1 && statusByQuarter) {
                    return targetData.milestoneQ1.updateDate > statusByQuarter.updateDate ? this.getNameByEmpID(targetData.milestoneQ1.updateBy) : this.getNameByEmpID(statusByQuarter.updateBy);
                } else if (statusByQuarter) {
                    return this.getNameByEmpID(statusByQuarter.updateBy);
                } else {
                    return this.getNameByEmpID(targetData.milestoneQ1.updateBy);
                }
            case 'Q2':
                if (targetData.milestoneQ2 && statusByQuarter) {
                    return targetData.milestoneQ2.updateDate > statusByQuarter.updateDate ? this.getNameByEmpID(targetData.milestoneQ2.updateBy) : this.getNameByEmpID(statusByQuarter.updateBy);
                } else if (statusByQuarter) {
                    return this.getNameByEmpID(statusByQuarter.updateBy);
                } else {
                    return this.getNameByEmpID(targetData.milestoneQ2.updateBy);
                }
            case 'Q3':
                if (targetData.milestoneQ3 && statusByQuarter) {
                    return targetData.milestoneQ3.updateDate > statusByQuarter.updateDate ? this.getNameByEmpID(targetData.milestoneQ3.updateBy) : this.getNameByEmpID(statusByQuarter.updateBy);
                } else if (statusByQuarter) {
                    return this.getNameByEmpID(statusByQuarter.updateBy);
                } else {
                    return this.getNameByEmpID(targetData.milestoneQ3.updateBy);
                }
            case 'Q4':
                if (targetData.milestoneQ4 && statusByQuarter) {
                    return targetData.milestoneQ4.updateDate > statusByQuarter.updateDate ? this.getNameByEmpID(targetData.milestoneQ4.updateBy) : this.getNameByEmpID(statusByQuarter.updateBy);
                } else if (statusByQuarter) {
                    return this.getNameByEmpID(statusByQuarter.updateBy);
                } else {
                    return this.getNameByEmpID(targetData.milestoneQ4.updateBy);
                }
            default:
                return '';
        }
    }

    handleOnDetailStrategicProject(data) {
        // if (this.checkAccess(data)) {
            this.displayItem = 'detail';
            this.strategicProjectData = data;
            this.strategicProjectName = data.projectName;
            this.quarter = this.selectedQuarter;
            this.strategicForm.patchValue({
                projectName: data.projectName
            });
            return;
        // }
    }

    getStrategicPlanById() {
        this.strategicProjectTrackingService.getStrategicProjectTrackingById(this.id).toPromise()
            .then(item => {
                if (this.checkAccess(item)) {
                    this.strategicProjectData = item;
                    this.strategicProjectName = item.projectName;
                    this.strategicForm.patchValue({
                        projectName: item.projectName
                    });
                    this.displayItem = 'detail';
                } else {
                    if (!this.isAdminOfStrategicProject) {
                        this.router.navigate(['/strategic-plan']);
                    }
                }
            })
            .catch(err => {
                console.log('err =>', err);
            });
    }

    checkAccess(item) {
        if (this.isAdminOfStrategicProject) {
            return true;
        }

        if (Array.isArray(item.approver)) {
            if (item.approver.some((data) => data.toUpperCase().includes(this.employeeDetail.email.toUpperCase()))) {
                return true;
            }
        }

        if (Array.isArray(item.coApprover)) {
            if (item.coApprover.some((data) => data.toUpperCase().includes(this.employeeDetail.email.toUpperCase()))) {
                return true;
            }
        }

        if (Array.isArray(item.reviewer)) {
            if (item.reviewer.some((data) => data.toUpperCase().includes(this.employeeDetail.email.toUpperCase()))) {
                return true;
            }
        }

        if (Array.isArray(item.coReviewer)) {
            if (item.coReviewer.some((data) => data.toUpperCase().includes(this.employeeDetail.email.toUpperCase()))) {
                return true;
            }
        }

        if (Array.isArray(item.creator)) {
            if (item.creator.some((data) => data.toUpperCase().includes(this.employeeDetail.email.toUpperCase()))) {
                return true;
            }
        }

        // use for test
        if(this.employeeDetail && this.employeeDetail.deptRelation) {
            if(this.employeeDetail.deptRelation.deptID === '100046' || this.employeeDetail.deptRelation.deptID === '100060' ||
            this.employeeDetail.deptRelation.department === '100005' || this.employeeDetail.deptRelation.department === '100002' ||
            this.employeeDetail.deptRelation.department === '100018' || this.employeeDetail.cluster.toUpperCase() === 'AAA') {
                return true;
            }
        }

        if (this.hrAdminAndHRVP()) {
            return true;
        }

        return false;
    }

    calculateQuarter(month) {
        switch (Number.isInteger(month)) {
            case (month <= 3):
                return 1;
            case (month > 3 && month <= 6):
                return 2;
            case (month > 6 && month <= 9):
                return 3;
            case (month > 9 && month <= 11):
                return 4;
            default:
                return 1;
        }
    }

    switchDisplayItem(event: ({ displayItem: '', page: '', quarter: ''; })) {
        if (!this.isAdminOfStrategicProject) {
            this.router.navigate(['/strategic-plan']);
        }
        this.displayItem = event.displayItem ? event.displayItem : 'list';
        this.currentPage = event.page ? event.page : 1;
        this.selectedQuarter = event.quarter ? event.quarter : '';

        this.initData();
    }

    validateStrategicProjectName() {
        if (!this.strategicForm.controls.projectName.value) this.strategicForm.controls.projectName.setErrors({ null: true });
    }

    changeProjectName() {
        let data = this.strategicForm.getRawValue();
        this.strategicProjectName = data.projectName;
    }

    onNewProject() {
        this.displayItem = 'newProject';
    }

    onExportExcel() {
        let exportExcel = this.strategicProjectList.filter(item => { return item.isSelected === true; });
        if (Array.isArray(exportExcel)) {
            if (exportExcel.length > 0) {
                const json = exportExcel.map(v => {
                    let corporateKPIScore = this.getCorporateKPIScore(v);
                    let stakeholders = '';
                    if (Array.isArray(v.stakeholders)) {
                        if (v.stakeholders.length > 0) {
                            stakeholders = v.stakeholders.join(', ');
                        }
                    }

                    let approver = [];
                    if (Array.isArray(v.approver)) {
                        if (v.approver.length > 0) {
                            v.approver.forEach(item => {
                                approver.push(this.getNameByEmail(item));
                            });
                        }
                    }

                    let coApprover = [];
                    if (Array.isArray(v.coApprover)) {
                        if (v.coApprover.length > 0) {
                            v.coApprover.forEach(item => {
                                coApprover.push(this.getNameByEmail(item));
                            });
                        }
                    }

                    let owners = [...approver, ...coApprover].join(', ');

                    let reviewer = [];
                    if (Array.isArray(v.reviewer)) {
                        if (v.reviewer.length > 0) {
                            v.reviewer.forEach(item => {
                                reviewer.push(this.getNameByEmail(item));
                            });
                        }
                    }

                    let coReviewer = [];
                    if (Array.isArray(v.coReviewer)) {
                        if (v.coReviewer.length > 0) {
                            v.coReviewer.forEach(item => {
                                coReviewer.push(this.getNameByEmail(item));
                            });
                        }
                    }

                    let creator = [];
                    if (Array.isArray(v.creator)) {
                        if (v.creator.length > 0) {
                            v.creator.forEach(item => {
                                creator.push(this.getNameByEmail(item));
                            });
                        }
                    }

                    let reporters = [...reviewer, ...coReviewer, ...creator].join(', ');

                    let status = [];
                    if (Array.isArray(v.targets)) {
                        if (v.targets.length > 0) {
                            v.targets.forEach(item => {
                                status.push(this.getStatus(item));
                            });
                        }
                    }

                    let statusData = '';
                    if (status.length > 0) {
                        status = status.filter(item => { return item; });
                        if (status.length > 0) {
                            statusData = status.join(',');
                        }
                    }

                    let target1: ProjectTarget;
                    let mileStoneQ1Target1: Milestone;
                    let mileStoneQ2Target1: Milestone;
                    let mileStoneQ3Target1: Milestone;
                    let mileStoneQ4Target1: Milestone;
                    if (v.targets[0]) {
                        target1 = v.targets[0];
                        if (target1.milestoneQ1) {
                            mileStoneQ1Target1 = target1.milestoneQ1;
                        }

                        if (target1.milestoneQ2) {
                            mileStoneQ2Target1 = target1.milestoneQ2;
                        }

                        if (target1.milestoneQ3) {
                            mileStoneQ3Target1 = target1.milestoneQ3;
                        }

                        if (target1.milestoneQ4) {
                            mileStoneQ4Target1 = target1.milestoneQ4;
                        }
                    }

                    let target2: ProjectTarget;
                    let mileStoneQ1Target2: Milestone;
                    let mileStoneQ2Target2: Milestone;
                    let mileStoneQ3Target2: Milestone;
                    let mileStoneQ4Target2: Milestone;
                    if (v.targets[1]) {
                        target2 = v.targets[1];
                        if (target2.milestoneQ1) {
                            mileStoneQ1Target2 = target2.milestoneQ1;
                        }

                        if (target2.milestoneQ2) {
                            mileStoneQ2Target2 = target2.milestoneQ2;
                        }

                        if (target2.milestoneQ3) {
                            mileStoneQ3Target2 = target2.milestoneQ3;
                        }

                        if (target2.milestoneQ4) {
                            mileStoneQ4Target2 = target2.milestoneQ4;
                        }
                    }

                    let target3: ProjectTarget;
                    let mileStoneQ1Target3: Milestone;
                    let mileStoneQ2Target3: Milestone;
                    let mileStoneQ3Target3: Milestone;
                    let mileStoneQ4Target3: Milestone;
                    if (v.targets[2]) {
                        target3 = v.targets[2];
                        if (target3.milestoneQ1) {
                            mileStoneQ1Target3 = target3.milestoneQ1;
                        }

                        if (target3.milestoneQ2) {
                            mileStoneQ2Target3 = target3.milestoneQ2;
                        }

                        if (target3.milestoneQ3) {
                            mileStoneQ3Target3 = target3.milestoneQ3;
                        }

                        if (target3.milestoneQ4) {
                            mileStoneQ4Target3 = target3.milestoneQ4;
                        }
                    }
                    let text = `\ -`;

                    return {
                        ['NO.']: v.no ? v.no : 0,
                        ['Project Name']: v.projectName ? v.projectName.replace(/^[-]/g, text) : '',
                        ['Category']: v.category ? v.category.category.replace(/^[-]/g, text) : '',
                        ['Sub Category']: v.category ? v.category.subCategory.replace(/^[-]/g, text) : '',
                        ['Strategy']: v.strategy ? v.strategy.replace(/^[-]/g, text) : '',
                        ['Division']: v.division ? v.division.replace(/^[-]/g, text) : '',
                        ['Stakeholders']: stakeholders ? stakeholders.replace(/^[-]/g, text) : '',
                        ['Owner']: owners,
                        ['Reporter']: reporters,
                        ['Latest Status']: statusData,
                        ['Latest update']: '',
                        ['Corporate KPI Target 1']: target1 ? target1.corporateKPIs : '',
                        ['Target 1']: target1 ? target1.detail.replace(/^[-]/g, text) : '',
                        ['T1: Milestone Q1']: mileStoneQ1Target1 && mileStoneQ1Target1.milestoneDetail ? mileStoneQ1Target1.milestoneDetail.replace(/^[-]/g, text) : '',
                        ['T1: Milestone Q2']: mileStoneQ2Target1 && mileStoneQ2Target1.milestoneDetail ? mileStoneQ2Target1.milestoneDetail.replace(/^[-]/g, text) : '',
                        ['T1: Milestone Q3']: mileStoneQ3Target1 && mileStoneQ3Target1.milestoneDetail ? mileStoneQ3Target1.milestoneDetail.replace(/^[-]/g, text) : '',
                        ['T1: Milestone Q4']: mileStoneQ4Target1 && mileStoneQ4Target1.milestoneDetail ? mileStoneQ4Target1.milestoneDetail.replace(/^[-]/g, text) : '',
                        ['T1: Progress Q1']: mileStoneQ1Target1 && mileStoneQ1Target1.progressDetail ? mileStoneQ1Target1.progressDetail.replace(/^[-]/g, text) : '',
                        ['T1: Progress Q2']: mileStoneQ2Target1 && mileStoneQ2Target1.progressDetail ? mileStoneQ2Target1.progressDetail.replace(/^[-]/g, text) : '',
                        ['T1: Progress Q3']: mileStoneQ3Target1 && mileStoneQ3Target1.progressDetail ? mileStoneQ3Target1.progressDetail.replace(/^[-]/g, text) : '',
                        ['T1: Progress Q4']: mileStoneQ4Target1 && mileStoneQ4Target1.progressDetail ? mileStoneQ4Target1.progressDetail.replace(/^[-]/g, text) : '',
                        ['Corporate KPI Target 2']: target2 ? target2.corporateKPIs : '',
                        ['Target 2']: target2 ? target2.detail.replace(/^[-]/g, text) : '',
                        ['T2: Milestone Q1']: mileStoneQ1Target2 && mileStoneQ1Target2.milestoneDetail ? mileStoneQ1Target2.milestoneDetail.replace(/^[-]/g, text) : '',
                        ['T2: Milestone Q2']: mileStoneQ2Target2 && mileStoneQ2Target2.milestoneDetail ? mileStoneQ2Target2.milestoneDetail.replace(/^[-]/g, text) : '',
                        ['T2: Milestone Q3']: mileStoneQ3Target2 && mileStoneQ3Target2.milestoneDetail ? mileStoneQ3Target2.milestoneDetail.replace(/^[-]/g, text) : '',
                        ['T2: Milestone Q4']: mileStoneQ4Target2 && mileStoneQ4Target2.milestoneDetail ? mileStoneQ4Target2.milestoneDetail.replace(/^[-]/g, text) : '',
                        ['T2: Progress Q1']: mileStoneQ1Target2 && mileStoneQ1Target2.progressDetail ? mileStoneQ1Target2.progressDetail.replace(/^[-]/g, text) : '',
                        ['T2: Progress Q2']: mileStoneQ2Target2 && mileStoneQ2Target2.progressDetail ? mileStoneQ2Target2.progressDetail.replace(/^[-]/g, text) : '',
                        ['T2: Progress Q3']: mileStoneQ3Target2 && mileStoneQ3Target2.progressDetail ? mileStoneQ3Target2.progressDetail.replace(/^[-]/g, text) : '',
                        ['T2: Progress Q4']: mileStoneQ4Target2 && mileStoneQ4Target2.progressDetail ? mileStoneQ4Target2.progressDetail.replace(/^[-]/g, text) : '',
                        ['Corporate KPI Target 3']: target3 ? target3.corporateKPIs : '',
                        ['Target 3']: target3 ? target3.detail.replace(/^[-]/g, text) : '',
                        ['T3: Milestone Q1']: mileStoneQ1Target3 && mileStoneQ1Target3.milestoneDetail ? mileStoneQ1Target3.milestoneDetail.replace(/^[-]/g, text) : '',
                        ['T3: Milestone Q2']: mileStoneQ2Target3 && mileStoneQ2Target3.milestoneDetail ? mileStoneQ2Target3.milestoneDetail.replace(/^[-]/g, text) : '',
                        ['T3: Milestone Q3']: mileStoneQ3Target3 && mileStoneQ3Target3.milestoneDetail ? mileStoneQ3Target3.milestoneDetail.replace(/^[-]/g, text) : '',
                        ['T3: Milestone Q4']: mileStoneQ4Target3 && mileStoneQ4Target3.milestoneDetail ? mileStoneQ4Target3.milestoneDetail.replace(/^[-]/g, text) : '',
                        ['T3: Progress Q1']: mileStoneQ1Target3 && mileStoneQ1Target3.progressDetail ? mileStoneQ1Target3.progressDetail.replace(/^[-]/g, text) : '',
                        ['T3: Progress Q2']: mileStoneQ2Target3 && mileStoneQ2Target3.progressDetail ? mileStoneQ2Target3.progressDetail.replace(/^[-]/g, text) : '',
                        ['T3: Progress Q3']: mileStoneQ3Target3 && mileStoneQ3Target3.progressDetail ? mileStoneQ3Target3.progressDetail.replace(/^[-]/g, text) : '',
                        ['T3: Progress Q4']: mileStoneQ4Target3 && mileStoneQ4Target3.progressDetail ? mileStoneQ4Target3.progressDetail.replace(/^[-]/g, text) : '',
                        ['Estimated Corporate KPI Score as of Qx']: corporateKPIScore ? corporateKPIScore : 0,
                        ['Estimated Year end Corporate KPI Score']: v.corporateKPIScoreQ4 ? v.corporateKPIScoreQ4 : 0,
                    };
                });
                const csvExporter = new ExportToCsv(this.optionsJsonToEx);
                csvExporter.generateCsv(json);
            }
        }
    }

    getCorporateKPIScore(targetData) {
        switch (this.selectedQuarter) {
            case 'Q1':
                return targetData.corporateKPIScoreQ1;
            case 'Q2':
                return targetData.corporateKPIScoreQ2;
            case 'Q3':
                return targetData.corporateKPIScoreQ3;
            case 'Q4':
                return targetData.corporateKPIScoreQ4;
            default:
                return 0;
        }
    }

    getStatus(targetData) {
        let status = '';
        switch (this.selectedQuarter) {
            case 'Q1':
                if (targetData.milestoneQ1) {
                    if (targetData.milestoneQ1.status === 'G') {
                        status = 'Green';
                    } else if (targetData.milestoneQ1.status === 'Y') {
                        status = 'Yellow';
                    } else if (targetData.milestoneQ1.status === 'R') {
                        status = 'Red';
                    }
                }
                return status;
            case 'Q2':
                if (targetData.milestoneQ2) {
                    if (targetData.milestoneQ2.status === 'G') {
                        status = 'Green';
                    } else if (targetData.milestoneQ2.status === 'Y') {
                        status = 'Yellow';
                    } else if (targetData.milestoneQ2.status === 'R') {
                        status = 'Red';
                    }
                }
                return status;
            case 'Q3':
                if (targetData.milestoneQ3) {
                    if (targetData.milestoneQ3.status === 'G') {
                        status = 'Green';
                    } else if (targetData.milestoneQ3.status === 'Y') {
                        status = 'Yellow';
                    } else if (targetData.milestoneQ3.status === 'R') {
                        status = 'Red';
                    }
                }
                return status;
            case 'Q4':
                if (targetData.milestoneQ3) {
                    if (targetData.milestoneQ4.status === 'G') {
                        status = 'Green';
                    } else if (targetData.milestoneQ4.status === 'Y') {
                        status = 'Yellow';
                    } else if (targetData.milestoneQ4.status === 'R') {
                        status = 'Red';
                    }
                }
                return status;
            default:
                return '';
        }
    }

    onShowConfirmationModal(template: TemplateRef<any>, status) {
        this.confirmationStatus = status;
        if (status === 'sendUpdate') {
            this.confirmationWording = 'Do you want to send update ?';
            this.modalRef = this.modalService.show(template, { class: 'modal-lg1' });
        } else if (status === 'onApprove') {
            this.confirmationWording = 'Do you want to approve all selected strategic project plans?';
            this.modalRef = this.modalService.show(template, { class: 'modal-lg1' });
        } else if (status === 'onSubmitForApproval') {
            this.confirmationWording = 'Do you want to submit for approval all selected strategic project plans?';
            this.modalRef = this.modalService.show(template, { class: 'modal-lg1' });
        }
    }

    confirm() {
        this.onSendUpdate();
    }

    onSendUpdate() {
        this.isLoading = true;
        if (Array.isArray(this.strategicProjectList)) {
            let sendMail;
            let action;
            if (this.confirmationStatus === 'sendUpdate') {
                action = 'NEED_UPDATE';
                sendMail = this.strategicProjectList.filter(item => { return item.isSelected === true && this.checkWorkflowStatus(item); });
            }

            if (this.confirmationStatus === 'onApprove') {
                action = 'APPROVE';
                sendMail = this.strategicProjectList.filter(item => { return item.isSelected === true && this.checkApprovable(item) === true; });
            }

            if (this.confirmationStatus === 'onSubmitForApproval') {
                action = 'SUBMIT_FOR_APPROVE';
                sendMail = this.strategicProjectList.filter(item => { return item.isSelected === true && this.checkReviewable(item) === true; });
            }

            let isError = false;
            if (sendMail.length > 0) {
                sendMail.forEach((item, index) => {
                    this.strategicProjectTrackingService.workflow(item.id, action, this.selectedQuarter).toPromise()
                        .then(res => {
                            if (index + 1 === sendMail.length && !isError) {
                                this.isLoading = false;
                                this.modalRef.hide();
                                if (!this.isAdminOfStrategicProject) {
                                    if (this.hrAdminAndHRVP()) {
                                        this.getAllStrategicProjectDetail();
                                    } else if (this.employeeDetail.deptRelation) {
                                        if (this.employeeDetail.deptRelation.department === '100005' || this.employeeDetail.deptRelation.department === '100002' || this.employeeDetail.deptRelation.department === '100018') {
                                            this.getAllStrategicProjectDetail();
                                        } else {
                                            this.getStrategicProjectDetailBySession();
                                        }
                                    } else {
                                        this.getStrategicProjectDetailBySession();
                                    }
                                    if (action === 'APPROVE') {
                                        this.alert(true, 'Successfully approve all strategic project plans.');
                                    } else if (action === 'SUBMIT_FOR_APPROVE') {
                                        this.alert(true, 'Successfully submit for approval all strategic project plans.');
                                    }
                                } else if (action === 'SAVE') {
                                    this.alert(true, 'Successfully sent updates to users.');
                                }
                            }
                        })
                        .catch(err => {
                            console.log('err', err);
                            this.modalRef.hide();
                            isError = true;
                            this.isLoading = false;
                            this.alert(false, 'Can not send an update to users, Please try again.');
                        });
                });
            } else {
                this.isLoading = false;
                this.modalRef.hide();
            }
        }
    }

    onSelectAll() {
        this.isSelectedAll = !this.isSelectedAll;
        if (this.isSelectedAll) {
            this.strategicProjectList.forEach(item => { item.isSelected = true; });
            this.strategicProjectListPerPage.forEach(item => { item.isSelected = true; });
        } else {
            this.strategicProjectList.forEach(item => { item.isSelected = false; });
            this.strategicProjectListPerPage.forEach(item => { item.isSelected = false; });
        }
    }

    handlePaging({ page, itemsPerPage }: PageChangedEvent) {
        const startItem = (page - 1) * itemsPerPage;
        const endItem = page * itemsPerPage;
        this.currentPage = page;
        this.strategicProjectListPerPage = this.strategicProjectList.slice(startItem, endItem);
        if (this.isSelectedAll) {
            this.strategicProjectListPerPage.forEach(item => { item.isSelected = true; });
        } else {
            if (this.strategicProjectList.filter(item => { return item.isSelected === true; }).length === 0) {
                this.strategicProjectListPerPage.forEach(item => { item.isSelected = false; });
            }
        }
    }

    onSelect(strategicProject: StrategicProjectTracking) {
        this.strategicProjectList.forEach(item => {
            if ((strategicProject.id === item.id)) {
                item.isSelected = !item.isSelected;
            }
        });

        if (this.strategicProjectList.filter(item => { return item.isSelected === true; }).length != this.strategicProjectList.length) {
            this.isSelectedAll = false;
        } else {
            this.isSelectedAll = true;
        }
    }

    checkSelect() {
        if (Array.isArray(this.strategicProjectList)) {
            if (this.strategicProjectList.filter(item => { return item.isSelected === true; }).length > 0) {
                return true;
            } else {
                return false;
            }
        }
    }

    getNameByEmail(email) {
        if (this.allName[email.toLowerCase()]) {
            let firstName = this.allName[email.toLowerCase()].split(' ')[0];
            let lastName = this.allName[email.toLowerCase()].split(' ')[1];
            let name = firstName + " " + lastName.charAt(0).toUpperCase() + ".";
            return name;
        } else {
            return email;
        }
    }

    getNameByEmpID(empId) {
        if (this.allNameByEmpID[empId]) {
            let firstName = this.allNameByEmpID[empId.toLowerCase()].split(' ')[0];
            let lastName = this.allNameByEmpID[empId.toLowerCase()].split(' ')[1];
            let name = firstName + " " + lastName.charAt(0).toUpperCase() + ".";
            return name;
        } else {
            return empId;
        }
    }

    displayStatus(status: Array<StrategicProjectWorkflowStatus>) {
        let workflowStatus = '';

        if (Array.isArray(status)) {
            workflowStatus = status.find(data => { return data.quarter === this.selectedQuarter; }).status;
        } else {
            return workflowStatus;
        }

        let displayStatus = '';

        switch (workflowStatus) {
            case 'NEED_UPDATE':
                if (this.isCreator) {
                    displayStatus = 'Need Update';
                } else {
                    displayStatus = 'Pending Update';
                }
                return displayStatus;
            case 'NEED_REVIEW':
                if (this.isReviewer) {
                    displayStatus = 'Need Review';
                } else {
                    displayStatus = 'Pending Review';
                }
                return displayStatus;
            case 'NEED_APPROVAL':
                if (this.isApprover) {
                    displayStatus = 'Need Approval';
                } else {
                    displayStatus = 'Pending Approval';
                }
                return displayStatus;
            case 'APPROVED':
                return 'Approved';
            default:
                return '';
        }
    }

    checkWorkflowStatus(strategicProject): boolean {
        if (Array.isArray(strategicProject.strategicProjectWorkflowStatus)) {
            if (strategicProject.strategicProjectWorkflowStatus.find((data) => { return data.status ? data.status.toUpperCase() === 'NEED_UPDATE' && data.quarter === this.selectedQuarter : null; })) {
                return true;
            } else if (strategicProject.strategicProjectWorkflowStatus.find((data) => { return !data.status && data.quarter === this.selectedQuarter; })) {
                return true;
            } else {
                return false;
            }
        } else {
            return false;
        }
    }

    checkIsApprover(): boolean {
        this.isApprover = false;
        if (Array.isArray(this.strategicProjectList)) {
            for (let i = 0; i < this.strategicProjectList.length; i++) {
                if (Array.isArray(this.strategicProjectList[i].strategicProjectWorkflowStatus)) {
                    if (this.strategicProjectList[i].approver.some((data) => data.toUpperCase().includes(this.employeeDetail.email.toUpperCase())) && this.strategicProjectList[i].strategicProjectWorkflowStatus.some((data) => data.status ? data.status.toUpperCase() === 'NEED_APPROVAL' && data.quarter === this.selectedQuarter : false)) {
                        this.isApprover = true;
                        break;
                    }
                } else {
                    break;
                }
            }
        } else {
            return false;
        }
    }

    checkIsReviewer(): boolean {
        this.isReviewer = false;
        if (Array.isArray(this.strategicProjectList)) {
            for (let i = 0; i < this.strategicProjectList.length; i++) {
                if (Array.isArray(this.strategicProjectList[i].strategicProjectWorkflowStatus)) {
                    if (this.strategicProjectList[i].reviewer.some((data) => data.toUpperCase().includes(this.employeeDetail.email.toUpperCase())) && this.strategicProjectList[i].strategicProjectWorkflowStatus.some((data) => data.status ? data.status.toUpperCase() === 'NEED_REVIEW' && data.quarter === this.selectedQuarter : false)) {
                        this.isReviewer = true;
                        break;
                    }
                } else {
                    break;
                }
            }
        } else {
            return false;
        }
    }

    checkIsCreator(): boolean {
        this.isCreator = false;
        if (Array.isArray(this.strategicProjectList)) {
            for (let i = 0; i < this.strategicProjectList.length; i++) {
                if (Array.isArray(this.strategicProjectList[i].strategicProjectWorkflowStatus)) {
                    if (this.strategicProjectList[i].creator.some((data) => data.toUpperCase().includes(this.employeeDetail.email.toUpperCase())) && this.strategicProjectList[i].strategicProjectWorkflowStatus.some((data) => (!data.status || data.status.toUpperCase() === 'NEED_UPDATE') && data.quarter === this.selectedQuarter)) {
                        this.isCreator = true;
                        break;
                    }
                } else {
                    break;
                }
            }
        } else {
            return false;
        }
    }

    checkApprovable(item: StrategicProjectTracking): boolean {
        if (Array.isArray(item.approver) && Array.isArray(item.strategicProjectWorkflowStatus)) {
            if (item.approver.some((data) => data.toUpperCase().includes(this.employeeDetail.email.toUpperCase())) && item.strategicProjectWorkflowStatus.some((data) => data.status ? data.status === 'NEED_APPROVAL' && data.quarter === this.selectedQuarter : false)) {
                return true;
            } else {
                return false;
            }
        }
    }

    checkReviewable(item: StrategicProjectTracking): boolean {
        if (Array.isArray(item.reviewer) && Array.isArray(item.strategicProjectWorkflowStatus)) {
            if (item.reviewer.some((data) => data.toUpperCase().includes(this.employeeDetail.email.toUpperCase())) && item.strategicProjectWorkflowStatus.some((data) => data.status ? data.status === 'NEED_REVIEW' && data.quarter === this.selectedQuarter : false)) {
                return true;
            } else {
                return false;
            }
        }
    }

    findStakeHolder(status: Array<StrategicProjectWorkflowStatus>) {
        if (Array.isArray(status)) {
            let stakeHoldert = status.find(data => { return data.quarter === this.selectedQuarter; }).updateBy;
            return this.getNameByEmpID(stakeHoldert);
        } else {
            return '';
        }
    }

    findActionTime(status: Array<StrategicProjectWorkflowStatus>) {
        if (Array.isArray(status)) {
            return status.find(data => { return data.quarter === this.selectedQuarter; }).updateDate;
        } else {
            return '';
        }
    }

    calculatePeriod(): number {
        let period = 0;
        let month = new Date().getMonth();
        let quarter = this.selectedQuarter.charAt(this.selectedQuarter.length - 1) ? +this.selectedQuarter.charAt(this.selectedQuarter.length - 1) : 0;
        console.log('quarter', quarter);
        console.log('new Date().getFullYear()', new Date().getFullYear());
        if (this.selectedYear - 543 == new Date().getFullYear()) {
            if (quarter >= this.calculateQuarter(+month)) {
                period = +month + 1;
            } else {
                period = (this.calculateQuarter(+month) * 3);
            }

        } else if (this.selectedYear - 543 > new Date().getFullYear()) {
            period = 0;
        } else {
            period = quarter * 3;
        }

        console.log('period', period);

        return period;
    }

    checkDisplayBudgetProject(strategicProject): boolean {
        // return strategicProject.budgetProject.length > 0 && !strategicProject.budgetProject.every(item => item === 'N/A');
        return false; // hide budget project
    }

    alert(isSuccess: boolean, msg: string) {
        return Swal.fire({
            scrollbarPadding: false,
            text: msg,
            title: isSuccess ? "Successfully" : "Failed",
            icon: isSuccess ? "success" : "error",
            confirmButtonText: "OK",
            focusCancel: true,
            buttonsStyling: false,
            customClass: {
                confirmButton: "btn btn-info btn-lg mr-2 text-light",
            },
        });
    }

    getRole() {
        this.requestTraining.getUserRole().toPromise()
            .then(res => {
                this.userRole = res;
                console.log('this.userRole', this.userRole);
            })
            .catch(err => {
                console.log('err', err);
            });
    }
}

<app-layout>
  <div class="container-fluid mt-4 pl-5 pr-5">
    <div class="row">
      <div class="col">
        <h4>
            <span *ngIf="displayItem == 'list'">
                <b>
                 RECRUIT
                </b>
            </span>
        </h4>
      </div>
      <div class="col-10" style="display: flex; justify-content: flex-end" *ngIf="isAdmin">
        <!--       (click)="sendMailTo('HR_GROUP')-->
        <button class="btn btn-info text-white" [disabled]="hrLevelIsEmpty"
                (click)="sendMailConfirmationModal(confirmTemplate, 'group')">Send mail to HR
          Group
        </button>
        <button class="btn btn-info text-white ml-2" [disabled]="hrLevelIsEmpty"
                (click)="sendMailConfirmationModal(confirmTemplate, 'division')">
          Send mail to HR Division
        </button>
        <button class="btn btn-info text-white ml-2" [disabled]="hrLevelIsEmpty"
                (click)="sendMailConfirmationModal(confirmTemplate, 'president')">Send
          mail to President
        </button>
<!--        <button class="btn btn-info ml-2" routerLink="/man-power/report" target="_blank">REPORT</button>-->
      </div>
    </div>
    <hr *ngIf="displayItem == 'list'" class="set">

    <ng-container [ngSwitch]="displayItem">
      <ng-container *ngSwitchCase="'list'">
        <app-man-power-pending-approve-list *ngIf="!isHrAdmin"
                                            (onNewManPowerListData)="routeToManPowerView($event)"></app-man-power-pending-approve-list>
        <app-man-power-hr-admin-pending *ngIf="isHrAdmin" [isAdmin]="isAdmin"
                                        (onNewManPowerListData)="routeToManPowerView($event)"></app-man-power-hr-admin-pending>
        <hr class="set mt-4 mb-4 ">
        <app-man-power-list (onNewManPowerListData)="routeToManPowerView($event)"></app-man-power-list>
      </ng-container>
      <ng-container *ngSwitchCase="'new'">
        <app-new-man-power (onEmitData)="onBack()"></app-new-man-power>
      </ng-container>
      <ng-container *ngSwitchCase="'edit'">
        <app-new-man-power (onEmitData)="onBack()" [id]="id" [forAction]="forAction"></app-new-man-power>
      </ng-container>
    </ng-container>
  </div>
</app-layout>


<ng-template #confirmTemplate>
  <div class="modal-header thead-primary" style="background-color: #fab232;">
    <h4 class="modal-title pull-left ml-3">Confirmation</h4>
    <button type="button" class="close pull-right text-light" aria-label="Close" (click)="modalRef.hide()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="form-group ml-3">
      <h5>{{ confirmationWording }}</h5>
      <br>
      <p>
        <ng-container *ngFor="let item of selectedFilterItemList; index as i">
          <span>{{ i + 1 }}. {{ item.positionName }}</span> <br>
        </ng-container>
      </p>
    </div>
  </div>
  <div class="modal-footer">
    <div class="d-flex mt-2 justify-content-end">
      <button class="btn btn-success mr-2" (click)="doSendMail()">Confirm</button>
      <button class="btn btn-danger" (click)="modalRef.hide()">Cancel</button>
    </div>
  </div>
</ng-template>

import {ActivatedRoute, Router} from '@angular/router';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';
import {ChangeDetectorRef, Component, OnInit, TemplateRef} from '@angular/core';

import { DatePipe } from '@angular/common';
import {Employee} from 'src/app/core/models/employee';
import {EmployeeService} from 'src/app/core/services/employee.service';
import { ExportToCsv } from 'export-to-csv';
import {FastFeedback} from 'src/app/core/models/fast-feedback';
import {FastFeedbackService} from 'src/app/core/services/fast-feedback.service';
import {PageChangedEvent} from 'ngx-bootstrap/pagination';
import {PagerModel} from 'ngx-bootstrap/pagination/models';
import Swal from 'sweetalert2';
import {faIcon} from 'src/app/core/icon/fa.icon';

@Component({
  selector: 'app-fast-feedback',
  templateUrl: './fast-feedback.component.html',
  styleUrls: ['./fast-feedback.component.scss'],
})
export class FastFeedbackComponent implements OnInit {
  faIcon = faIcon;
  modalRef: BsModalRef;
  selectedEmployee: Employee;
  selectedEmpName: String;
  keyword: string;
  fastFeedbackList: FastFeedback[];
  fastFeedbackDetailData: FastFeedback;
  displayItem: string = 'list';
  fastFeedbackTitle: string = '';
  paginationConfig: PagerModel = {
    itemsPerPage: 10,
    previousText: 'ย้อนกลับ',
    nextText: 'ถัดไป',
    pageBtnClass: 'btn btn-warning',
    align: true,
  };
  currentPage = 1;
  fastFeedbackListPerPage: FastFeedback[] = [];
  isOwner: boolean = false;
  id: number;
  selectedType: string = 'all';
  filterFastFeedbackList: FastFeedback[];
  optionsJsonToEx = {
    filename: 'fast-feedback',
    fieldSeparator: ',',
    quoteStrings: '"',
    decimalSeparator: '.',
    showLabels: true,
    useTextFile: false,
    useBom: true,
    useKeysAsHeaders: true
};
  allType = [
    {
      type: 'all',
      typeName: 'All'
    },
    {
      type: 'fast feedback',
      typeName: 'Fast Feedback'
    },
    {
      type: 'csi',
      typeName: 'CSI'
    },
    {
      type: 'vendor evaluation',
      typeName: 'Vender Evalutation'
    },
    {
      type: 'booking',
      typeName: 'Booking'
    },
    {
      type: 'survey',
      typeName: 'Survey'
    }
  ]
  today;
  allStatus = [
    {
      status: 'ALL_STATUS',
      statusName: 'All Status'
    },
    {
      status: 'ACTIVE',
      statusName: 'Active'
    },
    {
      status: 'INACTIVE',
      statusName: 'Inactive'
    },
    {
      status: 'EXPIRED',
      statusName: 'Expired'
    },
    {
      status: 'NOT_STARTED',
      statusName: 'Not Started'
    }
  ]
  selectedStatus: string = 'ACTIVE';
  confirmationWording : string = '';
  cloneId: number;

  constructor(
    private employeeService: EmployeeService,
    private fastFeedbackService: FastFeedbackService,
    private activateRoute: ActivatedRoute,
    private router: Router,
    private cd: ChangeDetectorRef,
    public datepipe: DatePipe,
    private modalService: BsModalService,
  ) {
    const id = this.activateRoute.snapshot.params.id;
    if (id) {
      this.id = id;
      this.displayItem = 'edit';
    }
  }

  ngOnInit(): void {
    if(!this.id) {
      this.getUserDetail();
      this.getFastFeedbackList();
    }
    this.today = new Date();
  }

  getFastFeedbackList() {
    this.fastFeedbackService.fastFeedBackListAdmin(0,this.selectedStatus).toPromise()
      .then((res: FastFeedback[]) => {
        if(res.length > 0) {
          res.map(item => {
            item.effectiveDate = item.effectiveDate ? new Date(item.effectiveDate) : '';
            item.expiryDate = item.expiryDate ?  new Date(item.expiryDate) : '';
          })
        }
        this.fastFeedbackList = res;
        this.filterFastFeedbackList = res;
        const startItem = (this.currentPage - 1) * 10;
        const endItem = this.currentPage * 10;
        this.fastFeedbackListPerPage = res.slice(startItem, endItem);


      })
      .catch(err => {
        console.log('err', err);
      })
  }

  getUserDetail() {
    this.employeeService.me().toPromise()
      .then(res => {
        this.selectedEmployee = res;
        this.selectedEmpName = res.nameEn;
      })
      .catch(err => {
        console.log('err', err);

      })
  }

  onNewFeedback() {
    this.isOwner = true;
    this.displayItem = 'new';
  }

  onImportFeedback() {
    this.isOwner = true;
    this.displayItem = 'import';
  }

  onBack() {
    this.router.navigate(['/fast-feedback'])

    this.displayItem = 'list';
    this.currentPage = this.currentPage ? this.currentPage : 1;
    this.selectedType = 'all'
    this.getFastFeedbackList();
  }

  switchDisplayItem(event: ({state: string, page: number})) {
    this.displayItem = event.state;
    this.currentPage = event.page ? event.page : 1;
    if (this.displayItem === 'list') {
      this.router.navigate(['/fast-feedback'])
      this.getFastFeedbackList();
    }
  }

  getEmitFastFeedback(event) {
    this.fastFeedbackTitle = event?.title;
  }

  onReceiveTitle(event) {
    this.fastFeedbackTitle = event;
  }

  handleOnEditFastFeedback(data) {
    let checkPermission = false;
    this.isOwner = false;

    if (Array.isArray(data.whoCanSeeResult)) {
      if (data.whoCanSeeResult.some(item => item.targetId === this.selectedEmployee.empID)) {
        checkPermission = true;
        this.isOwner = true;
      }
    }

    if (data.createBy) {
      if (this.selectedEmployee.empID === data.createBy) {
        checkPermission = true;
        this.isOwner = true;
      }
    }

    if (checkPermission) {
      this.displayItem = 'edit';
      this.fastFeedbackTitle = data.title;
      this.fastFeedbackDetailData = data;
      this.router.navigate([`/fast-feedback/${data.id}`])
    }
  }

    checkPermission(data) {
        let checkPermission = false;
        if (Array.isArray(data.whoCanSeeResult)) {
            if (data.whoCanSeeResult.some(item => item.targetId === this.selectedEmployee.empID)) {
                checkPermission = true;
            }
        }

      if (data.createBy) {
        if (this.selectedEmployee.empID === data.createBy) {
          checkPermission = true;
        }
      }

      return checkPermission;
    }

  // handleOnResponseFeedback(data) {
  //   this.displayItem = 'response';
  //   this.fastFeedbackTitle = data.title;
  //   this.fastFeedbackDetailData = data;
  // }

  checkResponsePermission(data) {
    let checkPermission = false;
    if (Array.isArray(data.tags)) {
      if (data.tags.some(item => (item.targetId === this.selectedEmployee.empID && item.targetType === 'EMP')
      || (
            (item.targetId === this.selectedEmployee?.deptRelation.deptID
            || item.targetId === this.selectedEmployee?.deptRelation.department
            || item.targetId === this.selectedEmployee?.deptRelation.subDiv
            || item.targetId === this.selectedEmployee?.deptRelation.division
            || item.targetId === this.selectedEmployee?.deptRelation.company
            ) && item.targetType === 'DEPT'
          )
      ) || data.tags.some(item => (item.targetType.toUpperCase() === 'ALL'))) {
          checkPermission = true;
        }
    }

    if (Array.isArray(data.whoCanSeeResult)) {
      if (data.whoCanSeeResult.some(item => item.targetId === this.selectedEmployee.empID)) {
        checkPermission = true;
      }
    }

    if (data.createBy) {
      if (this.selectedEmployee.empID === data.createBy) {
        checkPermission = true;
      }
    }

    return checkPermission;
  }

  onChangeTypeOrStatus() {
    // this.currentPage = 1

    // if(this.selectedType === 'vendor evaluation') {
    //   this.filterFastFeedbackList = this.fastFeedbackList.filter(item => {return item.feedbackType === this.selectedType});
    // } else {
    //   this.filterFastFeedbackList = this.fastFeedbackList;
    // }

    // this.fastFeedbackListPerPage = this.filterFastFeedbackList.slice(0, this.paginationConfig.itemsPerPage);

    this.fastFeedbackService.fastFeedBackListAdmin(0,this.selectedStatus).toPromise()
    .then((res: FastFeedback[]) => {
      if(res.length > 0) {
        res.map(item => {
          item.effectiveDate = item.effectiveDate ? new Date(item.effectiveDate) : '';
          item.expiryDate = item.expiryDate ?  new Date(item.expiryDate) : '';
        })
      }
      this.fastFeedbackList = res;
      this.filterFastFeedbackList = res;

      if(this.selectedType === 'vendor evaluation' || this.selectedType === 'fast feedback' || this.selectedType === 'csi' || this.selectedType === 'booking' || this.selectedType === 'survey') {
        this.filterFastFeedbackList = this.fastFeedbackList.filter(item => {return item.feedbackType === this.selectedType});
      } else {
        this.filterFastFeedbackList = this.fastFeedbackList;
      }
      if(this.keyword !== '' && this.keyword !== undefined) {
        this.filterFastFeedbackList = this.filterFastFeedbackList.filter(item => item.title.toUpperCase().includes(this.keyword.toUpperCase()));
      }


      this.fastFeedbackListPerPage = this.filterFastFeedbackList.slice(0, this.paginationConfig.itemsPerPage);

      this.cd.detectChanges();

      setTimeout(()=>{
        this.currentPage = 1
      }, 300);
    })
    .catch(err => {
      console.log('err', err);
    })
  }

  onExportFeedback() {
    if (Array.isArray(this.filterFastFeedbackList)) {
      if (this.filterFastFeedbackList.length > 0) {
        const json = this.filterFastFeedbackList.map((v, index) => {
          let createDate = this.datepipe.transform(v.createDate, 'dd MMM yyyy');
          let responseDate = this.datepipe.transform(v.lastResponse, 'dd MMM yyyy');
          let status = '-';

          if (!v.active) {
            status = 'Inactive';
          } else {
            if (v.effectiveDate && v.expiryDate) {
              if (this.today < v.effectiveDate) {
                status = 'Not Started';
              } else if (this.today > v.expiryDate) {
                status = 'Expired';
              } else if (this.today < v.expiryDate && this.today >= v.effectiveDate) {
                status = 'Active';
              }
            } else {
              status = 'Active';
            }
          }

          let rate;
          if (v.feedbackType) {
            if (v.feedbackType === 'csi') {
              rate = `${v.rate ? v.rate.toFixed(2) : 0.00} %`
            } else {
              rate = v.rate ? v.rate.toFixed(2) : 0.00
            }
          } else {
            rate = v.rate ? v.rate.toFixed(2) : 0.00
          }

          return {
            ['NO.']: index + 1,
            ['TITLE']: v.title ? v.title : '-',
            ['STATUS']: status,
            ['RESPONSES']: v.numberOfResponses ? v.numberOfResponses : 0,
            ['SCORE']: rate,
            ['OWNER ']: v.createByName ? v.createByName : '-',
            ['RESPCREATED DATE']: createDate ? createDate : '-',
            ['LAST RESPONSE']: responseDate ? responseDate : '-'
          };
        });
        const csvExporter = new ExportToCsv(this.optionsJsonToEx);
        csvExporter.generateCsv(json);
      }
    }
  }

  handlePaging({ page, itemsPerPage }: PageChangedEvent) {
        const startItem = (page - 1) * itemsPerPage;
        const endItem = page * itemsPerPage;
        this.currentPage = page;
        this.fastFeedbackListPerPage = this.filterFastFeedbackList.slice(startItem, endItem);
    }

  onClone(template: TemplateRef<any>,fastFeedbackId) {
    this.confirmationWording = 'Do you want to clone this fast feedback?';
    this.modalRef = this.modalService.show(template, { class: 'modal-lg1' })
    this.cloneId = fastFeedbackId;
  }

  confirm() {
    this.fastFeedbackService.cloneFastFeedback(this.cloneId).toPromise()
    .then(res => {
      this.alert(true, 'This fast feedback has been cloned.')
      this.modalRef.hide();
      this.onChangeTypeOrStatus();
    })
    .catch(err => {
      this.alert(false, 'Cannot clone this fast feedback, Please try again.')
      this.modalRef.hide();
    })
  }

  findExpiryDate(expiryDate): Date {
    if(expiryDate) {
      return new Date(expiryDate.setHours(23, 59, 59));
    }

    return null;
  }

  alert(isSuccess: boolean, msg: string) {
    return Swal.fire({
        scrollbarPadding: false,
        text: msg,
        title: isSuccess ? "Successfully" : "Failed",
        icon: isSuccess ? "success" : "error",
        confirmButtonText: "OK",
        focusCancel: true,
        buttonsStyling: false,
        customClass: {
            confirmButton: "btn btn-info btn-lg mr-2 text-light",
        },
    });
}
}

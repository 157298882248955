import {Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, TemplateRef, ViewChild,} from '@angular/core';
import {faIcon} from 'src/app/core/icon/fa.icon';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {BsModalRef, BsModalService} from 'ngx-bootstrap';
import {AttendeeService} from '../../../core/services/attendee.service';
import {Employee} from '../../../core/models/employee';
import {RequestTraining} from '../../../core/models/request-training';
import {ApproveLog, IEmployeePipLine, RequestTrainingService} from '../../../core/services/request-training.service';
import {Observable} from 'rxjs';
import Swal from 'sweetalert2';
import {CompetencyService} from '../../../core/services/competency.service';
import {Competency} from '../../../core/models/competency';
import {FileServiceService} from '../../../core/services/file-service.service';
import {RequestAction} from 'src/app/core/models/requestAction';
import {saveAs} from 'file-saver';
import * as moment from 'moment';
import {Vendor, VendorService} from '../../../core/services/vendor.service';
import {IApprovalState} from './approval-state/approval-state.component';
import {map, switchMap} from 'rxjs/operators';
import {UserRole} from '../../../core/models/userRole';
import * as XLSX from 'xlsx';

// import { SSL_OP_LEGACY_SERVER_CONNECT } from 'constants';

@Component({
  selector: 'app-request-detail',
  templateUrl: './request-detail.component.html',
  styleUrls: ['./request-detail.component.scss'],
})
export class RequestDetailComponent implements OnInit, OnChanges {
  @ViewChild('competencyEl') competency: ElementRef;

  faIcon = faIcon;
  attendeesBak: Employee[] = [];
  attendees: Employee[] = [];
  isNoResult = false;
  detail: RequestTraining;
  courseName;
  reqId = '';
  ApproveDate;
  ApproveDatePipeLine;
  approvalState;
  approveLog;
  step;
  reason;
  reqStatus;
  showSubmitButton;
  showEdittButton;
  showWithdrawButton;
  showSaveButton;
  showApprovalSatus;
  allowRemoveAttendee = true;
  requesterRole;
  currentState;
  courseList;
  competencyId;
  isPrecessEnd;

  approvalPipeLine: IApprovalState[] = []

  uploadProgress = 0;
  fileToUpload: File = null;
  fileList: FileTempObj[] = [];
  loading = false;

  requestActionModel: RequestAction;
  isUpdate = false;
  noAttendees;

  learningItemList = this.requestTrainingService.LearningItem;
  learningCatalogList = this.requestTrainingService.LearningCatalog;
  selectedCourse;

  toDay = new Date();

  vendorList: Vendor[] = [{
    vendorName: 'other',
    vendorId: '0',
    taxId: '0'
  }];

  @ViewChild('template') template;
  @ViewChild('reasonTemplate') reasonTemplate;
  @Output() loadData = new EventEmitter();
  @Input() modalRef: BsModalRef;
  @Input() fromSection: string;
  @Input() myEmail: string;
  @Input() openByHRAdmin: boolean;
  @Input() isActive: boolean;
  @Input() serviceReason: string;
  blob: Blob;
  submitType = '';

  @Input()
  set requestDetail(r) {
    console.warn('SET UP REQUEST DETAIL', r);
    if (r) {
      this.requestTrainingService.getHRAdmin().toPromise()
        .then(
          v => {
            this.requesterRole = v.map(o => o.employee.empID).filter(e => e === r.createBy).length == 0
          });
      this.ApproveDate = r.updateDate
      this.isUpdate = true;
      this.isPrecessEnd = false;
      this.detail = r;
      this.courseName = r.course.courseDescr;
      this.reqId = r.id;
      this.reqStatus = r.status;
      this.competencyId = r.course.competency.id;
      this.getApprovePipeLine(this.reqId);
      this.getApproveLog(this.reqId);
      this.form.enable();
      // this.approvalState = 'CANCELED';
      this.approvalState = r.approvalState;

      switch (r.approvalState) {
        case 'CANCELED':
          this.showSubmitButton = true;
          this.showWithdrawButton = false;
          this.showSaveButton = true;
          this.showEdittButton = false;
          this.showApprovalSatus = false;
          this.allowRemoveAttendee = true;
          this.form.enable();
          this.step = 0;
          break;
        case 'DRAFT':
          this.showSubmitButton = true;
          this.showWithdrawButton = true;
          this.showSaveButton = true;
          this.showEdittButton = false;
          this.showApprovalSatus = false;
          this.allowRemoveAttendee = true;
          this.form.enable();
          this.step = 0;
          break;
        case 'HR REJECT':
          this.step = 0;
          this.showSubmitButton = true;
          this.showWithdrawButton = true;
          this.showSaveButton = false;
          this.showEdittButton = false;
          this.showApprovalSatus = true;
          this.allowRemoveAttendee = true;
          this.form.enable();
          break;
        case 'PENDING HR':
          this.step = 0;
          this.showSubmitButton = false;
          this.showWithdrawButton = true;
          this.showSaveButton = false;
          this.showEdittButton = true;
          this.showApprovalSatus = true;
          this.allowRemoveAttendee = false;
          break;
        case 'PENDING HRVP MANAGER':
          this.step = 1;
          this.showSubmitButton = false;
          this.showWithdrawButton = true;
          this.showSaveButton = false;
          this.showEdittButton = true;
          this.showApprovalSatus = true;
          this.allowRemoveAttendee = false;
          break;
        case 'UNIT REJECT ':
          this.step = 1;
          this.showSubmitButton = true;
          this.showWithdrawButton = true;
          this.showSaveButton = false;
          this.showEdittButton = false;
          this.showApprovalSatus = true;
          this.allowRemoveAttendee = true;
          this.form.enable();
          break;

        case 'PENDING UNIT':
          this.step = 1;
          this.showSubmitButton = false;
          this.showWithdrawButton = true;
          this.showSaveButton = false;
          this.showEdittButton = true;
          this.showApprovalSatus = true;
          this.allowRemoveAttendee = false;
          break;

        case 'DEPT REJECT':
          this.step = 2;
          this.showSubmitButton = true;
          this.showWithdrawButton = true;
          this.showSaveButton = false;
          this.showEdittButton = false;
          this.showApprovalSatus = true;
          this.allowRemoveAttendee = true;
          this.form.enable();
          break;

        case 'PENDING DEPT':
          this.step = 2;
          this.showSubmitButton = false;
          this.showWithdrawButton = true;
          this.showSaveButton = false;
          this.showEdittButton = true;
          this.showApprovalSatus = true;
          this.allowRemoveAttendee = false;
          break;

        case 'GROUP REJECT':
          this.step = 3;
          this.showSubmitButton = true;
          this.showWithdrawButton = true;
          this.showSaveButton = false;
          this.showEdittButton = false;
          this.showApprovalSatus = true;
          this.allowRemoveAttendee = true;
          this.form.enable();
          break;

        case 'PENDING GROUP':
          this.step = 3;
          this.showSubmitButton = false;
          this.showWithdrawButton = true;
          this.showSaveButton = false;
          this.showEdittButton = true;
          this.showApprovalSatus = true;
          this.allowRemoveAttendee = false;
          break;

        case 'DIV REJECT':
          this.step = 4;
          this.showSubmitButton = true;
          this.showWithdrawButton = true;
          this.showSaveButton = false;
          this.showEdittButton = false;
          this.showApprovalSatus = true;
          this.allowRemoveAttendee = true;
          this.form.enable();
          break;

        case 'PENDING DIV':
          this.step = 4;
          this.showSubmitButton = false;
          this.showWithdrawButton = true;
          this.showSaveButton = false;
          this.showEdittButton = true;
          this.showApprovalSatus = true;
          this.allowRemoveAttendee = false;
          break;

        case 'AAA REJECT':
          this.step = 5;
          // 23 July 2020
          this.showSubmitButton = true;
          this.showWithdrawButton = true;
          this.showSaveButton = false;
          this.showEdittButton = false;
          this.showApprovalSatus = true;
          this.allowRemoveAttendee = true;

          break;

        case 'PENDING AAA':
          this.step = 5;
          this.showSubmitButton = false;
          this.showWithdrawButton = true;
          this.showSaveButton = false;
          this.showEdittButton = true;
          this.showApprovalSatus = true;
          this.allowRemoveAttendee = false;
          break;

        case 'COMPLETED':
          this.step = 6;
          this.showSubmitButton = false;
          this.showWithdrawButton = true;
          this.showSaveButton = false;
          this.showEdittButton = false;
          this.showApprovalSatus = true;
          this.allowRemoveAttendee = false;
          this.isPrecessEnd = true;
          this.form.disable();
          // this.form.controls.course.disable()
          // this.form.controls.institution.disable()
          // this.form.controls.startDate.disable()
          // this.form.controls.endDate.disable()
          // this.form.controls.objective.disable()
          break;
      }

      // console.log('showApprovalSatus', this.showApprovalSatus);

      // console.log('r.approvalState', r.approvalState);

      // this.fileList = r.requestAttachFileList;
      if (r.requestAttachFileList) {
        this.fileList = r.requestAttachFileList;
      } else {
        this.fileList = [];
      }
      if (this.fileList) {
        this.fileList.map((x) => {
          x.fileNameShort = this.truncate(x.fileName, 12);
          return x;
        });
      }
      this.requestActionModel = {
        requestTrainingId: r.id,
        action: null,
      };

      console.log('start date : ', this.detail.startDate);

      this.detail.startDate = new Date(this.detail.startDate);
      this.detail.endDate = new Date(this.detail.endDate);
      this.form.patchValue(this.detail);

      // this.form.patchValue({
      //   employee: {
      //     id: 10006
      //   }
      // });

      this.attendeeArray = this.fb.array([]);
      this.detail.attendeeList.forEach((o) => {
        if (o.status === 'Approved') {
          status = '(อนุมัติแล้ว)';
        } else {
          status = '(รออนุมัติ)';
        }
        if (o.employee.email === this.myEmail) {
          o.isMe = true;
        }
        this.patchAttendee(o.employee, o.id, status, o.isMe);
        console.log('status', o.status, o);
      });

      // });

    } else {
      // this.form.reset({
      //   employee: {
      //     id: 10006
      //   }
      // });
      this.form.reset();
      this.form.enable();
      this.isPrecessEnd = false;
      this.reqId = '';
      this.attendeeArray = this.fb.array([]);
      this.fileList = [];
      this.isUpdate = false;
      this.showApprovalSatus = false;
      this.showEdittButton = false;
      this.showWithdrawButton = false;
      this.showSubmitButton = true;
      this.showSaveButton = true;
      this.allowRemoveAttendee = true;
      this.attendeeControl.enable();

      this.competencyId = null;
    }

    console.log('isPrecessEnd', this.isPrecessEnd);
    console.log('allowRemoveAttendee', this.allowRemoveAttendee);
    if (this.allowRemoveAttendee) {
      this.form.controls.unitPrice.enable();
      this.form.controls.attendeeList.enable();
      this.form.controls.totalAmount.enable();
      this.attendeeControl.enable();
    } else {
      this.form.controls.unitPrice.disable();
      this.attendeeControl.disable();
      this.form.controls.totalAmount.disable();
    }
    // console.log('competencyId', this.competencyId);

    if (this.competencyId) {
      (this.form.controls.course['controls']
        .competency as FormGroup).controls.competencyDescr.disable();
      this.form.controls.course['controls'].learningItem.disable();
      this.form.controls.course['controls'].learningCatalog.disable();
      console.log('disable');
    } else {
      (this.form.controls.course['controls']
        .competency as FormGroup).controls.competencyDescr.enable();
      this.form.controls.course['controls'].learningItem.enable();
      this.form.controls.course['controls'].learningCatalog.enable();
      console.log('enable');
    }
  }

  form = this.fb.group({
    id: [],
    course: this.fb.group({
      id: [],
      courseCode: [],
      courseDescr: [[], Validators.required],
      learningItem: [[], Validators.required],
      learningCatalog: [[], Validators.required],
      competency: this.fb.group({
        id: [],
        competencyDescr: [[], Validators.required],
      }),
    }),

    institution: ['', Validators.required],
    otherInstitution: [''],
    startDate: ['', Validators.required],
    endDate: ['', Validators.required],
    duration: ['', Validators.required],
    unitPrice: ['', Validators.required],
    objective: ['', Validators.required],
    // approver: [],
    totalAmount: ['', Validators.required],
    company: [],
    approverHR: [],
    approverUnit: [],
    approverDept: [],
    approverGroup: [],
    approverDiv: [],
    reason: [],
    requestAttachFileList: [],
    attendeeList: [[], Validators.required],
    // employee: this.fb.control({
    //   id: 10006
    // })
  });

  competencyList: Competency[];

  attendeeArray = this.fb.array([]);

  attendeeControl = this.fb.control(null);

  reasonForm = this.fb.group({
    text: ['', Validators.required],
  });
  reasonModal: BsModalRef;

  nullErrorMessage = {
    course: 'Couse name is required',
    institution: 'Institution is required.',
    startDate: 'Start date is required.',
    endDate: 'End date is required.',
    duration: 'Duration is required.',
    unitPrice: 'Unit price is required.',
    objective: 'Objective is required.',
    competency: 'Competency is incorrect.',
    totalAmount: 'Total amount is required.',
    attendeeList: 'Attendee is required.',
    learningItem: 'Class Type is required',
    learningCatalog: 'Course Catalog is required',
    otherInstitution: 'Institution is required'
  };

  constructor(
    private fb: FormBuilder,
    private attendeeService: AttendeeService,
    private requestTrainingService: RequestTrainingService,
    private competencyService: CompetencyService,
    private fileServiceService: FileServiceService,
    private modalService: BsModalService,
    private vendorService: VendorService
  ) {
  }

  downloadTemplate() {
    this.requestTrainingService.downloadAttendeeTemplate().subscribe(response => {
      const blob = new Blob([response.body]);
      saveAs(blob, 'attendee_template.xlsx');
    });
  }

  async ngOnInit() {
    await this.getAttendees();
    this.getAllCompetency();
    // this.getHrRoleName();
    this.getCourse();
    this.checkService();

    this.vendorService.getAllVendor()
      .toPromise()
      .then(r => {
        this.vendorList = [...this.vendorList, ...r.sort((a, b) => a.vendorName.localeCompare(b.vendorName))]
      })
  }

  log() {
    console.log(this.form.get('institution').value);
  }


  onlyUnique(value, index, array) {
    return array.indexOf(value) === index;
  }

  getApprovePipeLine(id) {

      this.requestTrainingService.getApprovePipeLine(id).pipe(map(pipeLine => {
        return pipeLine
      })).pipe(switchMap((pipeLine: Employee[]) => this.requestTrainingService.getApproveLog(id).pipe(
        map(logs => {
          const lastRejectedOrCanceledIndex = this.findLastRejectedOrCanceledIndex(logs);
          console.log('lastRejectedOrCanceledIndex ====> ', lastRejectedOrCanceledIndex);
          if (lastRejectedOrCanceledIndex !== -1) {
            logs = logs.slice(lastRejectedOrCanceledIndex + 1);
          }

          return pipeLine
            .map(emp => {
              return this.mappingAction(emp, logs);
            })
        }))
    )).toPromise()
      .then(pipeLine => {
        this.approvalPipeLine = this.filterDuplicate(pipeLine);
      })
  }

  private mappingAction(emp: Employee, logs: ApproveLog[]) {
    const approveLogs = logs.sort((a, b) => new Date(b.createDate).getTime() - new Date(a.createDate).getTime());
    const acc = approveLogs.find(c => (c.approverId === emp.empID || c.pendingAt === emp.empID))

    if (acc) {
      return {
        approverName: emp.nameEn,
        approved: acc.action.includes('APPROVED'),
        rejectReason: acc.reason,
        approveDate: acc.updateDate,
        approverId: emp.empID
      } as IApprovalState
    }
    return {
      approverName: emp.nameEn,
      approved: false,
      approveDate: null,
      rejectReason: null,
      approverId: emp.empID
    } as IApprovalState
  }

  private findLatestWithStatus(logs: ApproveLog[], emp: Employee) {
    const approveLogs = logs.sort((a, b) => new Date(b.createDate).getTime() - new Date(a.createDate).getTime());
    console.log('approveLogs', approveLogs)
    for (const approveLog1 of approveLogs) {
      if (approveLog1.action.includes('REJECTED')) {
        return approveLog1;
      }
      if (approveLog1.approverId === emp.empID && approveLog1.action.includes('APPROVED')) {
        return approveLog1;
      }
    }

  }

  private mergeState(hrvp: Employee[], pipeLine: Employee[]) {
    return [...hrvp, ...pipeLine].filter(e=>e)
  }

  private toArray(pipeLine: IEmployeePipLine) {
    const states = [];
    for (const key of Object.keys(pipeLine)) {
      if (pipeLine[key]) {
        states.push(pipeLine[key])
      }
    }
    return states;
  }

  getApproveLog(id) {
    this.requestTrainingService.getApproveLog(id).subscribe((l) => {
      this.approveLog = l;
      if (this.approveLog) {
        const current = this.approveLog.sort(
          this.requestTrainingService.sortByProperty('id', 'desc')
        );
        const reject = this.approveLog.filter((f) => f.action === 'REJECTED');
        if (reject[0]) {
          reject.sort(this.requestTrainingService.sortByProperty('id', 'desc'));
          this.reason = reject[0].reason;
          // console.log('REJECT', reject[0].reason);
        } else {
          this.currentState = current[0].action;
          this.ApproveDatePipeLine = current[0].updateDate;
          console.log('currentState:', this.currentState);
          console.log('approveDateLine', current);

        }
      }
    });
  }

  getAllCompetency() {
    this.competencyService
      .getAllCompetency()
      .toPromise()
      .then((o) => (this.competencyList = o))
      .catch((e) => console.error(e));
  }

  checkService() {
    this.requestTrainingService.checkExternalTraningIsActive().subscribe(
      (response) => {
        console.log('checkExternalTraningIsActive response', response);
        this.isActive = response.status;
        this.serviceReason = response.reason;
      },
      (e) => console.error(e)
    );
  }

  ngOnChanges() {
    this.filterAttendees();
  }

  async handleUploadAttendees(files: FileList) {
    console.log(files)

    const reader = new FileReader();
    reader.onload = (evt) => { // evt = on_file_select event
      const bstr = evt.target.result;
      const wb = XLSX.read(bstr, {type: 'binary'});
      const wsname = wb.SheetNames[0];
      const ws = wb.Sheets[wsname];
      const data = XLSX.utils.sheet_to_json(ws);
      this.pushAttendees(data);
    };
    reader.readAsBinaryString(files[0]);
  }

  private pushAttendees(data: unknown[]) {
    const attendeesID = data.map(d => d['Emp ID']);
    const atdFiltered = this.attendees.filter(a => {
      if (attendeesID.includes(a.empID)) {
        return true;
      }
      return attendeesID.some(at => Number(at) === Number(a.empID)) // compare without leading zero
    });
    for (const atd of atdFiltered) {
      this.addAttendee(atd);
    }
  }

  handleFileInput(files: FileList) {
    this.fileToUpload = files.item(0);
    // console.log("file", this.fileToUpload);
  }

  downloadFile(fileName: string) {
    this.fileServiceService.downloadFile(fileName).subscribe((data) => {
      saveAs(data, fileName);
    });
  }

  uploadFileToActivity() {
    this.fileServiceService.postFile(this.fileToUpload).subscribe(
      (data) => {
        /* console.log(data);
        console.log(
          data.message ? data.message : data.fileName ? data.fileName : "null"
        );*/

        if (data.status === 'progress') {
          this.uploadProgress = data.message;
        }
        if (data.id) {
          // success
          this.fileToUpload = null;
          const obj = {
            id: data.id,
            fileName: data.fileName,
            fileNameShort: this.truncate(data.fileName, 12),
          };
          this.fileList.push(obj);
          this.form.patchValue({
            requestAttachFileList: this.fileList,
          });
          this.uploadProgress = 0;
        }
      },
      (er) => {
        this.uploadProgress = 0;
        this.alertForFileUpload(
          false,
          er.error.message ? er.error.message : er.statusText
        );
        // console.log("error", er);
      }
    );
  }

  truncate(n, len) {
    const ext = n.substring(n.lastIndexOf('.') + 1, n.length).toLowerCase();
    let filename = n.replace('.' + ext, '');
    if (filename.length <= len) {
      return n;
    }
    filename = filename.substr(0, len) + (n.length > len ? '...' : '');
    return filename + '.' + ext;
  }

  competencySelect({item}) {
    (this.form.controls.course['controls']
      .competency as FormGroup).controls.id.patchValue(item.id);
  }

  resultNotInList(field) {
    switch (field) {
      case 'competency':
        this.form.controls.course['controls'].competency.controls.competencyDescr.patchValue('');
        break;
      case 'learningItem':
        this.form.controls.course['controls'].learningItem.patchValue('');
        break;
      case 'learningCatalog':
        this.form.controls.course['controls'].learningCatalog.patchValue('');
        break;
    }
    // console.log('field', field);
  }

  getCourse() {
    this.requestTrainingService
      .getCourse()
      .toPromise()
      .then((c) => {
        this.courseList = c;
      })
      .catch((e) => {
        this.courseList = [];
      });
  }

  onCourseSelect({item}) {
    const c = this.form.controls.course;
    c.patchValue(item);
    console.log('item', item);
    // console.log('patch c', c);
    if (item.courseCode) {
      this.selectedCourse = item.courseDescr;
    } else {
      this.selectedCourse = '';
    }
    this.competencyId = (c['controls']
      .competency as FormGroup).controls.id.value;
    console.log(
      'nCourseSelect courseId : competency: LearningItem: LearningCatalog',
      this.competencyId,
      c['controls'].learningItem.value,
      c['controls'].learningCatalog.value
    );

    (c['controls'].competency as FormGroup).controls.competencyDescr.disable();

    if (c['controls'].learningItem.value) {
      c['controls'].learningItem.disable();
    } else {
      c['controls'].learningItem.enable();
    }

    if (c['controls'].learningCatalog.value) {
      c['controls'].learningCatalog.disable();
    } else {
      c['controls'].learningCatalog.enable();
    }
  }

  onCourseBlur(event) {
    event.preventDefault();
    const c = this.form.controls.course;
    const learningItem = c['controls'].learningItem;
    const learningCatalog = c['controls'].learningCatalog;
    const courseDescr = c['controls'].courseDescr.value;

    console.log(
      'reset course: ',
      learningItem.value,
      learningCatalog.value,
      courseDescr,
      this.selectedCourse
    );

    if (this.selectedCourse !== courseDescr) {
      c.patchValue({
        id: null,
        courseCode: null,
        learningItem: null,
        learningCatalog: null,
      });
      console.log('line 664');
      learningItem.enable();
      learningCatalog.enable();
      (c['controls'].competency as FormGroup).controls.competencyDescr.enable();
    }

    if (!this.isNoResult) {
      return;
    }
    (c['controls'].competency as FormGroup).reset();
    this.competencyId = (c['controls']
      .competency as FormGroup).controls.id.value;

    if (!this.competencyId) {
      (c['controls'].competency as FormGroup).controls.competencyDescr.enable();

      setTimeout(() => {
        document.getElementById('competency').focus();
      }, 50);
    }
  }

  onCourseNoResult = (e) => (this.isNoResult = e);

  onAttendeeSelect = ({item}) => {
    this.addAttendee(item);
  };

  private addAttendee(item: Employee) {
    this.patchAttendee(item);
    this.form.patchValue({
      attendeeList: this.attendeeArray.getRawValue(),
    });
    this.calGrandTotal();
  }

  patchAttendee = (employee, id?, status?, isMe?) => {
    const f = this.buildAttendeeMapperControl();
    f.patchValue({id, employee, status, isMe});
    const ids = this.attendeeArray.getRawValue().map(a => a.employee.empID);
    if (!ids.includes(employee.empID)) {
      this.attendeeArray.push(f);
    }
    this.attendeeControl.reset();
    this.filterAttendees();
  };

  onAttendeeNoResult = (e) => (this.isNoResult = e);

  onAttendeeBlur = () => this.attendeeControl.reset();

  onAttendeeRemove(index) {
    console.log('onAttendeeRemove');

    this.attendeeArray.removeAt(index);
    this.form.patchValue({
      attendeeList: this.attendeeArray.getRawValue(),
    });
    this.filterAttendees();
    this.calGrandTotal();
  }

  alertForFileUpload(isSuccess: boolean, msg) {
    Swal.fire({
      scrollbarPadding: false,
      title: isSuccess ? 'Successfuly' : 'Failed!',
      text: isSuccess ? 'Save training request successfully.' : msg,
      icon: isSuccess ? 'success' : 'error',
      buttonsStyling: false,
      customClass: {
        confirmButton: 'btn btn-success btn-lg ',
      },
    });
  }

  async getAttendees() {
    try {
      const a = await this.attendeeService
        .getAttendee()
        .toPromise()

      const atd = a.map((it) => {
        it.fullSearch = `${it.nameTh} (${it.nameEn})`;
        return it;
      });
      this.attendeesBak = atd;
      this.attendees = atd;
    } catch (e) {
      console.error(e);
      this.attendeesBak = [];
      this.attendees = [];
    }
  }

  delFile(id: number) {
    this.fileServiceService
      .deleteFile(id)
      .toPromise()
      .then((x) => {
        this.fileList = this.fileList.filter((data) => data.id !== id);
        this.form.patchValue({
          requestAttachFileList: this.fileList,
        });
      })
      .catch(() => {
        // console.log("err", y);
      });
  }

  onSubmit(actionType) {
    let isFormValid = true;

    console.log(this.form.getRawValue());
    console.log(actionType, ' not form valid: ', this.validateForm)
    if (
      this.validateForm &&
      actionType != 'REJECTED' &&
      actionType != 'APPROVED' &&
      actionType != 'WITHDRAW'
    ) {
      return 0;
    }
    console.log('aaaa')
    const fv = this.form.getRawValue();

    if (actionType === 'REJECTED' || actionType === 'APPROVED') {
      const reason = this.reasonForm.getRawValue();
      fv.reason = reason.text;
      console.log('reject season', fv.reason);
      this.reasonModal.hide();
    }

    if (isNaN(this.form.controls.unitPrice.value)) {
      this.alertFormInvalid(false, 'Price/Person must be a number');
      isFormValid = false;
    }

    if (isNaN(this.form.controls.totalAmount.value)) {
      this.alertFormInvalid(false, 'Grand Total must be a number');
      isFormValid = false;
    }

    if (isNaN(this.form.controls.duration.value)) {
      this.alertFormInvalid(false, 'Duration must be a number');
      isFormValid = false;
    }

    if (!this.form.controls.duration.value) {
      this.alertFormInvalid(false, 'Please enter a duration');
      isFormValid = false;
    }

    if (isFormValid) {
      if (fv.id) {
        this.afterSave(
          this.requestTrainingService.update(fv.id, fv, actionType),
          actionType
        );
        console.log('update', fv);
      } else {
        this.afterSave(
          this.requestTrainingService.save(fv, actionType),
          actionType
        );
        console.log('action type new', actionType);
      }
    }
  }

  calGrandTotal() {
    this.noAttendees = this.attendeeArray.getRawValue().length;
    const total = (
      this.attendeeArray.getRawValue().length *
      this.form.controls.unitPrice.value
    ).toFixed(2);

    console.log('length', this.attendeeArray.getRawValue().length);
    this.form.patchValue({
      totalAmount: total,
    });
  }

  recalPricePerPerson() {
    const unitPrice = this.form.controls.unitPrice.value;
    const totalAmount = this.form.controls.totalAmount.value * 1.0;
    // var calUnitPrice = unitPrice ? unitPrice : 0;

    if (this.noAttendees) {
      console.log('calUnitPrice', unitPrice);
      if (this.noAttendees * unitPrice !== totalAmount) {
        const pricePerHead = (totalAmount / this.noAttendees).toFixed(2);
        console.log('unit price :', pricePerHead, this.noAttendees);
        this.form.patchValue({
          unitPrice: pricePerHead,
          totalAmount: totalAmount.toFixed(2),
        });
      }
    }
  }

  get validateForm() {
    if (
      this.form.controls.endDate.errors &&
      this.form.controls.endDate.errors.incorrect
    ) {
      this.form.controls.endDate.setErrors(null);
    }

    this.findInvalidControls();
    let result = false;

    if (this.form.invalid) {
      console.log('a')
      result = true;
    }
    if (this.dateValidation) {
      result = true;
    }
    if (this.validateCompetency) {
      result = true;
    }
    if (this.validateLearningItem) {
      result = true;
    }
    if (this.validateLearningCatalog) {
      result = true;
    }
    if (this.validateInstitution) {
      result = true;
    }
    return result;
  }

  get validateCompetency() {
    const result = this.getCompetency.controls.competencyDescr.invalid;
    if (result) {
      this.getCompetency.controls.competencyDescr.setErrors({
        isNull: true,
        errMsg: this.nullErrorMessage.competency,
      });
    }

    return result;
  }

  get validateLearningItem() {
    const result = this.form.controls.course['controls'].learningItem.invalid;
    if (result) {
      this.form.controls.course['controls'].learningItem.setErrors({
        isNull: true,
        errMsg: this.nullErrorMessage.learningItem,
      });
    }

    return result;
  }

  get validateInstitution() {
    const result = this.form.controls.institution.value
    const r = result === 'other' && !this.form.controls.otherInstitution.value
    if (r) {
      this.form.controls.otherInstitution.setErrors({
        isNull: true,
        errMsg: this.nullErrorMessage.otherInstitution,
      });
    }
    return r;
  }

  get validateLearningCatalog() {
    const result = this.form.controls.course['controls'].learningCatalog
      .invalid;
    if (result) {
      this.form.controls.course['controls'].learningCatalog.setErrors({
        isNull: true,
        errMsg: this.nullErrorMessage.learningCatalog,
      });
    }

    return result;
  }

  get getCompetency() {
    return this.form.controls.course['controls'].competency as FormGroup;
  }

  get startDateInvalid() {
    const result =
      this.form.controls.startDate.hasError('incorrect') ||
      this.form.controls.startDate.hasError('isNull');
    return result;
  }

  get grandTotalInvalid() {
    const result =
      this.form.controls.totalAmount.hasError('isNull') ||
      this.form.controls.totalAmount.hasError('incorrect');
    return result;
  }

  get endDateInvalid() {
    const result =
      this.form.controls.endDate.hasError('isNull') ||
      this.form.controls.endDate.hasError('incorrect');
    return result;
  }

  get dateValidation() {

    const startDate = moment(this.form.controls.startDate.value);
    startDate.set({h: 0, m: 0, s: 0});
    const endDate = moment(this.form.controls.endDate.value);
    endDate.set({h: 23, m: 59, s: 59});

    const compareDate = startDate.isAfter(endDate);

    console.log('compareDate : ', compareDate);

    const currentDate = new Date();
    currentDate.setDate(currentDate.getDate() - 1);

    console.log('currentDate : ', currentDate);

    const pastDay =
      currentDate > startDate.toDate() || currentDate > endDate.toDate();


    console.log('pastDay : ', pastDay);
    if (
      pastDay &&
      this.form.controls.endDate.valid &&
      this.form.controls.startDate.valid
    ) {
      this.form.controls.startDate.setErrors({
        incorrect: true,
        errMsg: 'The date should start from today.',
      });
      this.form.controls.endDate.setErrors({
        incorrect: true,
      });
    }
    if (compareDate && !pastDay) {
      this.form.controls.startDate.setErrors({
        incorrect: true,
        errMsg: 'The start date should be less than the end date.',
      });
      this.form.controls.endDate.setErrors({
        incorrect: true,
      });
    }
    const result = compareDate || pastDay;
    return result;
  }

  public findInvalidControls() {
    const invalid = [];
    const controls = this.form.controls;

    for (const name in controls) {
      // console.log('outer', name)
      if (controls[name].invalid) {
        invalid.push(name);
        this.form.controls[name].setErrors({
          isNull: true,
          errMsg: this.nullErrorMessage[name],
        });
      }
    }

    return invalid;
  }

  afterSave(obs: Observable<any>, actionType) {
    this.form.disable();
    this.loading = true;
    let msg;

    switch (actionType) {
      case 'APPROVED':
        msg = 'Approve training request successfully.';
        break;
      case 'REJECTED':
        msg = 'Reject training request successfully.';
        break;
      case 'SAVE':
        msg = 'Save training request successfully.';
        break;
      case 'SUBMIT':
        msg = 'Submit training request successfully.';

        break;
      case 'WITHDRAW':
        msg = 'Withdraw training request successfully.';
        break;
      case 'CANCEL':
        msg = 'Cancel training request successfully.';
        break;
      case 'EDIT':
        msg = 'Edit training request successfully.';
        break;
    }
    console.log('msg', msg);

    try {
      obs
        .toPromise()
        .then((s) => {
          this.alert_(true, msg);
          this.xCloseModal();
          this.loading = false;
        })
        .catch((e) => {
          this.alert_(false, e.error.msg || e.error.message);
          this.loading = false;
          this.form.enable();

        });
    } catch (e) {
      this.alert_(false, e.error.msg || e.error.message);
      this.loading = false;
      this.form.enable();
    }
  }

  alert_(isSuccess: boolean, msg) {
    if (msg) {
      msg = msg.split(',')[0]
    }
    Swal.fire({
      scrollbarPadding: false,
      // text: isSuccess ? 'Save training request successfully.' : msg,
      text: isSuccess ? msg : msg,
      // : 'Failed to save training request.',
      icon: isSuccess ? 'success' : 'error',
      buttonsStyling: false,
      customClass: {
        // confirmButton: 'btn btn-primary pl-2 ',
        confirmButton: 'btn btn-success pl-2 ',
      },
    }).then((o) => {
      this.getCourse();
      this.loadData.emit();
    });
  }

  filterAttendees() {
    const ids = this.attendeeArray.getRawValue().map((o) => o.EmpID);
    // this.attendees = this.attendeesBak.filter(o => !ids.includes(o.empId));
  }

  buildAttendeeMapperControl = () =>
    this.fb.group({
      id: [],
      status: [],
      isMe: false,
      employee: this.fb.group({
        id: [],
        empID: [],
        nameEn: [],
        nameTh: [],
        email: [],
        mobile: [],
        deptId: [],
        emplType: [],
        company: [],
        status: [],
      }),
    });



  WithdrawRegistration() {
    this.withdrawAlert_().then((o) => {
      console.log(o.value);
      if (o.value) {
        this.loadData.emit();
        this.onSubmit('WITHDRAW');
        this.modalRef.hide();
      }
    });
  }

  editRegistration() {
    this.editAlert_().then((o) => {
      if (o.value) {
        this.loadData.emit();
        this.onSubmit('EDIT');
        this.modalRef.hide();
      }
    });
  }

  onAction(isApprove: boolean) {
    if (isApprove) {
      this.submitType = 'approve';
      this.modalRef.hide();
      this.reasonForm.reset();
      this.reasonModal = this.modalService.show(this.reasonTemplate, {
        ignoreBackdropClick: true,
      });
      // this.approveAlert_(isApprove).then((o) => {
      //   if (o.value) {
      //     this.loadData.emit();
      //     this.action(isApprove);
      //     this.modalRef.hide();
      //   }
      // });
    } else {
      this.submitType = 'reject';
      this.modalRef.hide();
      this.reasonForm.reset();
      this.reasonModal = this.modalService.show(this.reasonTemplate, {
        ignoreBackdropClick: true,
      });
    }
  }

  action(isApprove: boolean) {
    if (isApprove) {
      this.onSubmit('APPROVED');
    } else {
      if (this.reasonForm.invalid) {
        this.reasonForm.controls.text.setErrors({
          isNull: true,
          msg: 'Reason is required.',
        });
        return 0;
      }
      this.onSubmit('REJECTED');
    }
  }

  withdrawAlert_() {
    return Swal.fire({
      scrollbarPadding: false,
      title: 'Please confirm',
      text: `Are you sure to withdraw this request training?`,
      icon: 'question',
      buttonsStyling: false,
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'No',
      focusCancel: true,
      customClass: {
        // confirmButton: 'btn btn-warning  pl-2 mr-2 text-light',
        // cancelButton: 'btn btn-success  pl-3 ml-2',
        confirmButton: 'btn btn-danger  pl-2 mr-2 text-light',
        cancelButton: 'btn btn-success  pl-3 ml-2',
      },
    });
  }

  editAlert_() {
    return Swal.fire({
      scrollbarPadding: false,
      title: 'Please confirm',
      text: `Are you sure to edit this request training?`,
      icon: 'question',
      buttonsStyling: false,
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'No',
      focusCancel: true,
      customClass: {
        confirmButton: 'btn btn-success  pl-2 mr-2 text-light',
        cancelButton: 'btn btn-danger  pl-3 ml-2',
      },
    });
  }

  approveAlert_(isApprove: boolean) {
    const disp = !isApprove ? 'reject' : 'approve';

    return Swal.fire({
      scrollbarPadding: false,
      title: 'Please confirm',
      text: `Are you sure to ${disp} this request training?`,
      icon: 'question',
      buttonsStyling: false,
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'No',
      focusCancel: true,
      customClass: {
        confirmButton: 'btn btn-success  pl-2 mr-2 text-light',
        cancelButton: 'btn btn-danger  pl-3 ml-2',
      },
    });
  }

  alertFormInvalid(isSuccess: boolean, msg) {
    Swal.fire({
      scrollbarPadding: false,
      title: isSuccess ? 'Successfuly' : 'Failed!',
      text: isSuccess ? 'Save training request successfully.' : msg,
      icon: isSuccess ? 'success' : 'error',
      buttonsStyling: false,
      customClass: {
        confirmButton: 'btn btn-success btn-lg ',
      },
    });
  }

  xCloseModal() {
    this.form.reset();
    this.form.enable();
    this.modalRef.hide();
    this.attendeeArray.clear();
    this.attendeeArray.reset();
  }

  private filterDuplicate(pipeLine: IApprovalState[]) {
    const out = []
    for (const approver of pipeLine) {
      if (!out.some(a => a.approverName === approver.approverName)) {
        out.push(approver);
      }
    }
    return out;
  }

  findLastRejectedOrCanceledIndex(logs: ApproveLog[]): number {
    let lastIndex = -1;
    for (let i = logs.length - 1; i >= 0; i--) {
      if (logs[i].action.includes('REJECTED') || logs[i].action.includes('CANCELED')) {
        lastIndex = i;
        break;
      }
    }
    return lastIndex;
  }

  closeReasonModal(isApprove) {
    // if(isApprove) {
    //   this.reasonModal.hide();
    //   this.action(true);
    // }

    this.reasonModal.hide();
  }
}

interface FileTempObj {
  fileNameShort: string;
  id: number;
  fileName: string;
}

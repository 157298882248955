<div class="d-flex justify-content-start">
  <h5 class="mr-3 mt-2">Pending For Approval</h5>
  <div class="col-sm-4">
    <div class="form-inline">
      <input class="input-style form-control mr-1"
             [(ngModel)]="filterPendingAt"
             [typeahead]="managers"
             (typeaheadOnSelect)="onSelectFilter($event)"
             typeaheadOptionField="nameTh"
             [typeaheadScrollable]="true"
             [typeaheadOptionsInScrollableView]="5" [typeaheadMinLength]="0" required/>
      <button *ngIf="filterPendingAt" (click)="clearFilter()" class="btn btn-outline-secondary my-2 my-sm-0"
              type="submit">Clear
      </button>
    </div>
  </div>

</div>
<div class="body-element">

  <table class="table table-hover">
    <thead class="thead-pink">
    <tr>
      <th *ngIf="isAdmin" scope="col" style="width: 15px"></th>
      <th scope="col" style="width: 3%;">ID</th>
      <th scope="col" style="width: 25%;">Position</th>
      <th scope="col" style="width: 10%;">Level</th>
      <th scope="col" style="width: 20%;  align-items: center; text-align:  center;">Status</th>
      <th scope="col" style="width: 18%;">Request</th>
      <th scope="col" style="width: 10%;">Request date</th>
      <th scope="col" style="width: 10%; white-space: nowrap">Pending at</th>
    </tr>
    </thead>
    <tbody>
    <tr *ngFor="let manPower of manPowerListPerPage; index as i"
        (click)="handleOnViewRequisition(manPower)"
        style="cursor: pointer;">

      <td *ngIf="isAdmin" class="text-center" (click)="$event.stopPropagation()">
        <input type="checkbox" [checked]="existingInState(manPower.id)" (click)="handleCheckbox($event, manPower)"/>
      </td>
      <td>{{ manPower?.id }}</td>
      <td>{{ manPower?.positionName || manPower?.position?.positionName }}</td>
      <td>{{ manPower?.level }}</td>
      <td>
        <button *ngIf="manPower?.status === 'DRAFT'"
                class="bg-secondary status-container btn py-1 btn-man-power-status">
          {{ manPower?.status?.toUpperCase() }}
        </button>
        <button *ngIf="['PENDING', 'PENDING_HR', 'PENDING_PRESIDENT'].includes(manPower?.status)"
                class="bg-c-set status-container btn py-1 btn-man-power-status">
          {{ manPower?.status?.toUpperCase() }}
        </button>
        <button *ngIf="manPower?.status === 'APPROVED'"
                class="bg-success status-container btn py-1 btn-man-power-status">
          {{ manPower?.status?.toUpperCase() }}
        </button>
        <button *ngIf="manPower?.status === 'REJECTED'"
                class="bg-danger status-container btn py-1 btn-man-power-status">
          {{ manPower?.status?.toUpperCase() }}
        </button>
      </td>
      <td>{{ manPower?.requester?.nameTh }}</td>
      <td>{{ manPower?.requesterDate | date:'dd MMM yyyy' }}</td>
      <td style="white-space: nowrap"> {{ manPower?.pendingAt?.nameTh }}</td>
    </tr>
    <tr *ngIf="manPowerList.length == 0">
      <td colspan="8" class="text-center">
        <h5><b>No Data To Display</b></h5>
      </td>
    </tr>
    </tbody>
  </table>

  <div *ngIf="manPowerList">
    <div *ngIf="manPowerList.length > paginationConfig.itemsPerPage">
      <pagination
        [nextText]="paginationConfig.nextText"
        [previousText]="paginationConfig.previousText"
        [totalItems]="manPowerList.length"
        [(ngModel)]="currentPage"
        [itemsPerPage]="paginationConfig.itemsPerPage"
        [directionLinks]="true"
        [boundaryLinks]="false"
        [maxSize]="10"
        (pageChanged)="handlePaging($event)">
      </pagination>
    </div>
  </div>
</div>


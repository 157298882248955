import { Injectable } from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {RequestManpower} from '../../../core/models/requestManpower';

@Injectable({
  providedIn: 'root'
})
export class ManpowerSelectedService {
  private selectedRow = new BehaviorSubject<RequestManpower[]>([]);
  currentItems = this.selectedRow.asObservable();

  constructor() { }

  changeItems(items: RequestManpower[]) {
    this.selectedRow.next(items);
  }

  get getItems() {
    return this.selectedRow.value;
  }
}

import {Component, OnInit, TemplateRef} from '@angular/core';
import {SetupManpowerService} from '../../core/services/setup-manpower.service';
import {ManpowerHeadcount} from '../../core/models/SetupManposer';
import {faIcon} from '../../core/icon/fa.icon';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import {FormBuilder, Validators} from '@angular/forms';
import * as dayjs from 'dayjs';
import * as moment from 'moment';

@Component({
  selector: 'app-setup-manpower',
  templateUrl: './setup-manpower.component.html',
  styleUrls: ['./setup-manpower.component.scss']
})
export class SetupManpowerComponent implements OnInit {
  readonly faIcon = faIcon;

  dateFormat = 'DD MMM YYYY';
  datePickerFormat = { dateInputFormat: this.dateFormat };
  list: ManpowerHeadcount[] = []
  modalRef: BsModalRef;
  deleteId: number;
  mode: 'Add' | 'Update'

  f = this.fb.group({
    id: [],
    asof: [new Date(), Validators.required],
    current: [0, Validators.required],
    recruit: [0, Validators.required],
    summary: [0, Validators.required],
  })

  constructor(
    private setupManpowerService: SetupManpowerService,
    private modalService: BsModalService,
    private fb: FormBuilder,
  ) { }

  ngOnInit(): void {
    this.onLoad()
  }

  onLoad = async () => {
    this.list = await this.setupManpowerService.get().toPromise()
  }

  openForm(template: TemplateRef<any>, mode: 'Add' | 'Update', data?: ManpowerHeadcount) {
    this.f.reset({asof: new Date()})
    this.f.enable()
    this.mode = mode
    if (mode === 'Update') {
      data.asof = dayjs(data.asof).toDate()
      this.f.patchValue(data)
    }
    this.modalRef = this.modalService.show(template, {class: 'modal-md'});
  }

  add = async () => {
    this.f.disable()
    await this.setupManpowerService.save(this.f.getRawValue()).toPromise()
    this.modalRef.hide()
    this.onLoad()
  }

  delete = () => {
    this.f.disable()
    this.setupManpowerService.delete(this.deleteId).subscribe(() => {
      this.onLoad()
      this.modalRef.hide()
    })
  }

  update = async () => {
    this.f.disable()
    await this.setupManpowerService.update(this.f.get('id').value, this.f.getRawValue()).toPromise()
    this.modalRef.hide()
    this.onLoad()
  }

  openConfirmDelete(template: TemplateRef<any>, id: number) {
    this.f.reset()
    this.f.enable()
    this.modalRef = this.modalService.show(template, {class: 'modal-sm'});
    this.deleteId = id;
  }

  confirm = () => {
    if (this.f.invalid) {
      console.log(this.f.controls)
      this.f.markAllAsTouched()
      return
    }
    switch (this.mode) {
      case 'Add':
        this.add()
        break
      case 'Update':
        this.update()
        break
    }
  }
}

<app-layout>
    <div class="container-fluid pl-5 pr-5">
        <h4 class="mt-4">
            <b>
                <span (click)="onBack()" class="mr-2" [ngClass]="displayItem === 'new' ?  'cursor-pointer' : ''">Fast Feedback</span>
<!--                <span [routerLink]="['/fast-feedback']" class="mr-2" [ngClass]="displayItem === 'new' ?  'cursor-pointer' : ''">Fast Feedback</span>-->
                <span *ngIf="displayItem === 'new'">
                    <fa-icon [icon]="faIcon.faChevronRight" class="text-c-set"></fa-icon>
                    <b> New Feedback</b>
                </span>
                <span *ngIf="displayItem === 'import'">
                    <fa-icon [icon]="faIcon.faChevronRight" class="text-c-set"></fa-icon>
                    <b> Import</b>
                </span>
                <span *ngIf="displayItem === 'edit'">
                    <fa-icon [icon]="faIcon.faChevronRight" class="text-c-set"></fa-icon>
                    <b> {{fastFeedbackTitle}}</b>
                </span>
                <span *ngIf="displayItem === 'response'">
                    <fa-icon [icon]="faIcon.faChevronRight" class="text-c-set"></fa-icon>
                    <b> {{fastFeedbackTitle}}</b>
                </span>
            </b>
        </h4>
        <hr class="set">
        <ng-container [ngSwitch]="displayItem">
            <ng-container *ngSwitchCase="'list'">
                <div class="d-flex justify-content-between">
                    <div class="form-group row">
                        <h6 class="col-sm-auto col-form-label"><b>Feedback Type</b></h6>
                        <div class="col-sm-auto">
                            <select class="form-control col-sm-auto" [(ngModel)]="selectedType"
                            (change)="onChangeTypeOrStatus()">
                            <option *ngFor="let type of allType" [ngValue]="type.type">
                                {{type.typeName}}
                            </option>
                        </select>
                        </div>

                        <h6 class="col-sm-auto col-form-label"><b>Status</b></h6>
                        <div class="col-sm-auto">
                            <select class="form-control col-sm-auto" [(ngModel)]="selectedStatus"
                            (change)="onChangeTypeOrStatus()">
                            <option *ngFor="let status of allStatus" [ngValue]="status.status">
                                {{status.statusName}}
                            </option>
                        </select>
                        </div>
                        <div class="col-sm-auto">
                            <div class="form-group">
                              <div class="input-group">
                                <input type="text"  class="ml-2 form-control custom-input-2" [(ngModel)]="keyword"/>
                                <span class="input-group-btn">
                                  <button class="btn" style="border-color: #ced4da;" type="submit" (click)="onChangeTypeOrStatus()">
                                    <fa-icon [icon]="faIcon.faSearch"></fa-icon>
                                  </button>
                                </span>
                              </div>
                            </div>
                        </div>
                    </div>
                    <div class="form-group row">
                        <div class="col-sm-auto">
                            <button class="btn btn-success float-right" (click)="onExportFeedback()">EXPORT</button>
                        </div>
                        <div class="col-sm-auto">
                            <button class="btn btn-primary float-right" (click)="onImportFeedback()">IMPORT</button>
                        </div>
                        <div class="col-sm-auto">
                            <button class="btn btn-info float-right" (click)="onNewFeedback()">NEW FEEDBACK</button>
                        </div>
                    </div>
                </div>
                <!-- <div class="row"> -->
                    <table class="table" id="dataTable">
                        <col style="width: 5%;" />
                        <col style="width: 20%;" />
                        <col style="width: 10%;" />
                        <col style="width: 10%;" />
                        <col style="width: 10%;" />
                        <col style="width: 16%;" />
                        <col style="width: 12%;" />
                        <col style="width: 12%;" />
                        <col style="width: 5%;" />
                        <thead class="dt-head">
                            <tr>
                                <th>
                                    NO.
                                </th>
                                <th>
                                    TITLE
                                </th>
                                <th>
                                    STATUS
                                </th>
                                <th class="px-0">
                                   # RESPONSES
                                </th>
                                <th>
                                    SCORE
                                </th>
                                <th>
                                    OWNER
                                </th>
                                <th>
                                    CREATED DATE
                                </th>
                                <th>
                                    LAST RESPONSE
                                </th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody *ngIf="fastFeedbackListPerPage">
                            <tr *ngFor="let fastFeedback of fastFeedbackListPerPage; let i = index">
                                <td>
                                    {{ (i)+((currentPage-1)*paginationConfig.itemsPerPage) +1 }}
                                </td>
                                <td [ngClass]="checkPermission(fastFeedback)? 'text-primary cursor-pointer' : ''"  (click)="handleOnEditFastFeedback(fastFeedback)">
                                    {{fastFeedback.title ? fastFeedback.title : '-'}}
                                </td>
                                <td *ngIf="fastFeedback.active">
                                    <div *ngIf="fastFeedback.effectiveDate && fastFeedback.expiryDate">
                                        <div *ngIf="today < fastFeedback.effectiveDate" class="text-danger">
                                            Not Started
                                        </div>
                                        <div *ngIf="today > findExpiryDate(fastFeedback.expiryDate)" class="text-danger">
                                           Expired
                                        </div>
                                        <div *ngIf="today < fastFeedback.expiryDate && today >= fastFeedback.effectiveDate" class="text-success">
                                           Active
                                        </div>
                                    </div>
                                    <div *ngIf="!fastFeedback.expiryDate || !fastFeedback.effectiveDate" class="text-success">
                                        Active
                                    </div>
                                </td>
                                <td *ngIf="!fastFeedback.active" class="text-danger">
                                    {{fastFeedback.active ? 'Active' : 'Inactive'}}
                                </td>
                                <!-- <td [ngClass]="fastFeedback.active ? 'text-success' : 'text-danger'">
                                    {{fastFeedback.active ? 'Active' : 'Inactive'}}
                                </td> -->
                                <td class="text-right">
                                    {{fastFeedback.numberOfResponses | number}}
                                </td>
                                <td class="text-right">
                                    <div *ngIf="fastFeedback.feedbackType">
                                        <p class="mb-0" *ngIf="fastFeedback.feedbackType.toLowerCase() !== 'csi'">{{fastFeedback.rate | number : '1.2-2'}}</p>
                                        <p class="mb-0" *ngIf="fastFeedback.feedbackType.toLowerCase() === 'csi'">{{fastFeedback.rate | number : '1.2-2'}} %</p>
                                    </div>
                                    <p class="mb-0" *ngIf="!fastFeedback.feedbackType">{{fastFeedback.rate | number : '1.2-2'}}</p>
                                </td>
                                <td>
                                    {{fastFeedback.createByName ? fastFeedback.createByName : '-'}}
                                </td>
                                <td *ngIf="fastFeedback.createDate">
                                    {{fastFeedback.createDate | date: 'dd MMM yyyy'}}
                                </td>
                                <td *ngIf="!fastFeedback.createDate">
                                    -
                                </td>
                                <td *ngIf="fastFeedback.lastResponse">
                                    {{fastFeedback.lastResponse | date: 'dd MMM yyyy'}}
                                </td>
                                <td *ngIf="!fastFeedback.lastResponse">
                                    -
                                </td>
                                <td>
                                  <!-- <a *ngIf="checkResponsePermission(fastFeedback)" [routerLink]="['/fast-feedback',fastFeedback.id]">link</a> -->
                                  <!-- <a *ngIf="checkResponsePermission(fastFeedback)" [routerLink]="['/fast-feedback-send-feedback',fastFeedback.id]">link</a> -->
                                  <a *ngIf="checkResponsePermission(fastFeedback)" [routerLink]="['/fast-feedback-send-feedback',fastFeedback.id]" target="_blank">link</a>
                                  <label *ngIf="checkResponsePermission(fastFeedback)" class="link-style mt-2" (click)="onClone(template,fastFeedback.id)">clone</label>
                                </td>
                            </tr>
                        </tbody>
                        <tbody *ngIf="!filterFastFeedbackList">
                            <td class="text-center" *ngIf="!filterFastFeedbackList" colspan="8">
                                No data.
                            </td>
                        </tbody>
                    </table>
                <!-- </div> -->
                <div *ngIf="filterFastFeedbackList">
                    <div *ngIf="filterFastFeedbackList.length > 10">
                    <pagination [totalItems]="filterFastFeedbackList.length" [(ngModel)]="currentPage" [itemsPerPage]="paginationConfig.itemsPerPage"
                        (pageChanged)="handlePaging($event)">
                    </pagination>
                    </div>
                </div>
            </ng-container>
            <ng-container *ngSwitchCase="'new'">
                <app-new-fast-feedback (onEmitData)="switchDisplayItem($event)" [fastFeedbackData]="null" [isOwner]="isOwner" [displayStatus]="displayItem" [pageData]="currentPage"></app-new-fast-feedback>
            </ng-container>
            <ng-container *ngSwitchCase="'import'">
                <app-new-fast-feedback (onEmitData)="switchDisplayItem($event)" [fastFeedbackData]="null" [isOwner]="isOwner" [displayStatus]="displayItem" [pageData]="currentPage"></app-new-fast-feedback>
            </ng-container>
            <ng-container *ngSwitchCase="'edit'">
                <app-new-fast-feedback (onEmitData)="switchDisplayItem($event)" (onEmitFastFeedback)="getEmitFastFeedback($event)" [fastFeedbackData]="fastFeedbackDetailData" [isOwner]="isOwner" [displayStatus]="displayItem" [pageData]="currentPage"></app-new-fast-feedback>
            </ng-container>
            <ng-container *ngSwitchCase="'response'">
                <app-response-fast-feedback (emitTitle)="onReceiveTitle($event)" [id]="id" (onEmitData)="switchDisplayItem($event)" [fastFeedbackData]="fastFeedbackDetailData"></app-response-fast-feedback>
            </ng-container>
        </ng-container>
    </div>
</app-layout>

<ng-template #template>
    <div class="modal-header thead-primary" style="background-color: #fab232;">
      <h4 class="modal-title pull-left ml-3">Confirmation</h4>
      <button type="button" class="close pull-right text-light" aria-label="Close" (click)="modalRef.hide()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <div class="form-group ml-3">
        <div class="d-flex">
          <h5>{{ confirmationWording }}</h5>
        </div>
      </div>
    </div>
    <div class="modal-footer">
      <div class="d-flex mt-2 justify-content-end">
        <button class="btn btn-success mr-2" (click)="confirm()">Confirm</button>
        <button class="btn btn-danger" (click)="modalRef.hide()">Cancel</button>
      </div>
    </div>
  </ng-template>
import {Component, Input, OnInit, TemplateRef} from '@angular/core';
import {RequestManpower} from '../../../core/models/requestManpower';
import {PagerModel} from 'ngx-bootstrap/pagination/models';
import {ManpowerService} from '../../../core/services/manpower.service';
import {Router} from '@angular/router';
import {BsModalRef, BsModalService, PageChangedEvent, TypeaheadMatch} from 'ngx-bootstrap';
import {Employee} from '../../../core/models/employee';
import {ManpowerSelectedService} from '../services/manpower-selected.service';

@Component({
  selector: 'app-man-power-hr-admin-pending',
  templateUrl: './man-power-hr-admin-pending.component.html',
  styleUrls: ['./man-power-hr-admin-pending.component.scss']
})
export class ManPowerHrAdminPendingComponent implements OnInit {

  manPowerList: RequestManpower[] = []
  managers: Employee[] = []
  currentPage = 1;
  paginationConfig: PagerModel = {
    itemsPerPage: 10,
    previousText: 'ย้อนกลับ',
    nextText: 'ถัดไป',
    pageBtnClass: 'btn btn-warning',
    align: true,
  };
  filterPendingAt: string;
  manPowerListPerPage: RequestManpower[] = []

  // checkboxItems: RequestManpower[] = []
  @Input() isAdmin!: boolean;



  constructor(
    private manpowerService: ManpowerService,
    private router: Router,
    private manpowerSelectedService: ManpowerSelectedService
  ) {

  }



  handleCheckbox(event: any, manPower: RequestManpower) {
    event.stopPropagation()
    if (event.target.checked) {
      this.manpowerSelectedService.changeItems([...this.manpowerSelectedService.getItems, manPower])
    } else {
      this.manpowerSelectedService.changeItems(this.manpowerSelectedService.getItems.filter(item => item.id !== manPower.id))
    }
    // console.log(this.manpowerSelectedService.getItems)
  }

  async ngOnInit() {
    await this.loadList();
    this.managers = await this.manpowerService.finManagers().toPromise()
    const startItem = (this.currentPage - 1) * this.paginationConfig.itemsPerPage;
    const endItem = this.currentPage * this.paginationConfig.itemsPerPage;
    this.manPowerListPerPage = this.manPowerList.slice(startItem, endItem);
  }

  private async loadList() {
    this.manPowerList = await this.manpowerService.getPendingForHRAdmin().toPromise()
  }

  handleOnViewRequisition(manPower: RequestManpower) {
    this.router.navigateByUrl(`man-power/approve/${manPower.id}`)
  }

  handlePaging({page, itemsPerPage}: PageChangedEvent) {
    const startItem = (page - 1) * itemsPerPage;
    const endItem = page * itemsPerPage;
    this.currentPage = page;
    this.manPowerListPerPage = this.manPowerList.slice(startItem, endItem);
  }

  async onSelectFilter({item}: TypeaheadMatch) {
    this.manPowerList = await this.manpowerService.getPendingForHRAdmin(item.empID).toPromise()
    this.handlePaging({page: 1, itemsPerPage: this.paginationConfig.itemsPerPage})
  }

  async clearFilter() {
    await this.loadList();
    this.filterPendingAt = null;
    this.handlePaging({page: 1, itemsPerPage: this.paginationConfig.itemsPerPage})
  }

  existingInState(id: number) {
    return this.manpowerSelectedService.getItems.some(item => item.id === id)
  }
}

<app-layout>
  <div class="container-fluid mt-4 pl-5 pr-5">
    <div class="row">
      <div class="col">
        <h2>
          <span>Setup Manpower</span>
        </h2>
      </div>
    </div>
    <hr class="set" />
    <div class="row justify-content-between mb-1">
      <div class="col"></div>
      <div class="col-sm-auto">
        <button type="button" class="btn btn-primary" style="width: 88px" (click)="openForm(form, 'Add')">
          ADD
        </button>
      </div>
    </div>
    <div class="row justify-content-between">
      <div class="col">
        <table class="table">
          <thead>
          <tr>
            <th>วันที่มีผล</th>
            <th>พนักงานปัจจุบัน</th>
            <th>อยู่ระหว่างกำเนินการสรรหา</th>
            <th>อัตรากำลังรวม</th>
            <th>หมายเหตุ</th>
            <th></th>
            <th></th>
          </tr>
          </thead>
          <tbody>
          <tr *ngFor="let d of list">
            <td>{{d.asof | date: 'dd MMM yyyy'}}</td>
            <td style="text-align: right">{{d.current}}</td>
            <td style="text-align: right">{{d.recruit}}</td>
            <td style="text-align: right">{{d.summary}}</td>
            <td style="text-align: center">-</td>
            <td>
              <fa-icon [icon]="faIcon.faEdit" class="text-c-set icon-size" (click)="openForm(form, 'Update', d)"></fa-icon>
            </td>
            <td>
              <fa-icon [icon]="faIcon.faTrash" class="text-danger icon-size" (click)="openConfirmDelete(del, d.id)"></fa-icon>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</app-layout>

<ng-template #form>
  <div class="modal-header thead-primary" style="background-color: #fab232">
    <h4 class="modal-title pull-left ml-3">{{mode}}</h4>
    <button type="button" class="close pull-right text-light" aria-label="Close" (click)="modalRef.hide()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <form [formGroup]="f">
      <div class="form-group">
        <label for="asof">Asof</label>
        <input
          id="asof"
          type="text"
          class="form-control custom-input"
          formControlName="asof"
          bsDatepicker
          [bsConfig]="datePickerFormat"
        />

      </div>
      <div class="form-group">
        <label for="current">Current</label>
        <input formControlName="current" type="number" class="form-control" id="current" aria-describedby="emailHelp">
      </div>
      <div class="form-group">
        <label for="recruit">Recruit</label>
        <input formControlName="recruit" type="number" class="form-control" id="recruit" aria-describedby="emailHelp">
      </div>
      <div class="form-group">
        <label for="summary">Summary</label>
        <input formControlName="summary" type="number" class="form-control" id="summary" aria-describedby="emailHelp">
      </div>
    </form>
  </div>
  <div class="modal-footer">
    <div class="d-flex mt-2 justify-content-end">
      <button [disabled]="f.disabled" class="btn btn-success mr-2" (click)="confirm()">Save</button>
      <button [disabled]="f.disabled" class="btn btn-danger" (click)="modalRef.hide()">Cancel</button>
    </div>
  </div>
</ng-template>

<ng-template #del>
  <div class="modal-header thead-primary" style="background-color: #fab232">
    <h4 class="modal-title pull-left ml-3">Confirmation</h4>
    <button type="button" class="close pull-right text-light" aria-label="Close" (click)="modalRef.hide()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="form-group ml-3">
      <div class="d-flex">
        <h5>Do you want to remove this row ?</h5>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <div class="d-flex mt-2 justify-content-end">
      <button [disabled]="f.disabled" class="btn btn-success mr-2" (click)="delete()">Confirm</button>
      <button [disabled]="f.disabled" class="btn btn-danger" (click)="modalRef.hide()">Cancel</button>
    </div>
  </div>
</ng-template>

<app-layout>
  <div class="left-menu">
    <ul>
      <li class="item active" [ngClass]="{ active: menuSwitch === 'external' }">
        <a (click)="selectTap('external')">External Traning</a>
      </li>
      <li
        class="item active"
        [ngClass]="{ active: menuSwitch === 'uploadfile' }"
      >
        <a (click)="selectTap('uploadfile')">Online Course</a>
      </li>
      <li class="item" [ngClass]="{ active: menuSwitch === 'allowance' }">
        <a (click)="selectTap('allowance')">WFH Allowance</a>
      </li>
      <li class="item" [ngClass]="{ active: menuSwitch === 'medical' }">
        <a (click)="selectTap('medical')">Medical Claim</a>
      </li>
      <li class="item" [ngClass]="{ active: menuSwitch === 'userrole' }">
        <a (click)="selectTap('userrole')">User Role Settings</a>
      </li>
      <li class="item" [ngClass]="{ active: menuSwitch === 'intern' }">
        <a (click)="selectTap('intern')">Intern Timesheet</a>
      </li>
      <li class="item" [ngClass]="{ active: menuSwitch === 'outsource' }">
        <a (click)="selectTap('outsource')">Outsource Timesheet</a>
      </li>
      <!-- <li
        class="item"
        [ngClass]="{ active: menuSwitch === 'outsourceProject' }"
      >
        <a (click)="selectTap('outsourceProject')">Outsource Project</a>
      </li> -->
      <li
        class="item"
        [ngClass]="{ active: menuSwitch === 'additional-information' }"
      >
        <a (click)="selectTap('additional-information')"
          >Additional Information</a
        >
      </li>
      <li
        class="item"
        [ngClass]="{ active: menuSwitch === 'deptAuthorization' }"
      >
        <a (click)="selectTap('deptAuthorization')">Dept Authorization</a>
      </li>
      <li class="item" [ngClass]="{ active: menuSwitch === 'special-holiday' }">
        <a (click)="selectTap('special-holiday')">Special Holiday</a>
      </li>
    </ul>
  </div>
  <div class="content" [ngSwitch]="menuSwitch">
    <div *ngSwitchCase="'external'">
      <div *ngIf="allowAccess">
        <app-external-training-list></app-external-training-list>
      </div>
    </div>

    <div *ngSwitchCase="'uploadfile'">
      <div *ngIf="allowAccess">
        <app-upload-external-file></app-upload-external-file>
      </div>
    </div>

    <div *ngSwitchCase="'userrole'">
      <div *ngIf="allowAccess">
        <app-user-management></app-user-management>
      </div>
    </div>
    <div *ngSwitchCase="'allowance'">
      <div *ngIf="allowAccess">
        <app-allowance></app-allowance>
      </div>
    </div>
    <div *ngSwitchCase="'medical'">
      <div *ngIf="allowAccess">
        <app-admin-medical></app-admin-medical>
      </div>
    </div>
    <div *ngSwitchCase="'intern'">
      <div *ngIf="allowAccess">
        <app-intern-timesheet></app-intern-timesheet>
      </div>
    </div>
    <div *ngSwitchCase="'outsource'">
      <div *ngIf="allowAccess">
        <app-wrapper-outsource-timesheet></app-wrapper-outsource-timesheet>
      </div>
    </div>
    <div *ngSwitchCase="'outsourceProject'">
      <div *ngIf="allowAccess">
        <app-admin-project-wrapper></app-admin-project-wrapper>
      </div>
    </div>
    <div *ngSwitchCase="'additional-information'">
      <div *ngIf="allowAccess">
        <app-admin-additional-information></app-admin-additional-information>
      </div>
    </div>
    <div *ngSwitchCase="'deptAuthorization'">
      <div *ngIf="allowAccess">
        <app-admin-dept-authorization></app-admin-dept-authorization>
      </div>
    </div>
    <div *ngSwitchCase="'special-holiday'">
      <div *ngIf="allowAccess">
        <special-holiday></special-holiday>
      </div>
    </div>
    <div *ngSwitchDefault>
      <div class="text-center align-items-center mt-5">
        <hr style="border-top: 1px solid #ffa553" />
        NO PERMISSION
      </div>
    </div>
  </div>
</app-layout>

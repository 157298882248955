import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from '../../../environments/environment';
import {ManpowerHeadcount} from '../models/SetupManposer';

@Injectable({
  providedIn: 'root'
})
export class SetupManpowerService {

  constructor(private http: HttpClient) { }

  get(): Observable<ManpowerHeadcount[]> {
    return this.http.get<ManpowerHeadcount[]>(`${environment.api_endpoint}//manpower-headcount`);
  }

  detail(id: number): Observable<ManpowerHeadcount> {
    return this.http.get<ManpowerHeadcount>(`${environment.api_endpoint}//manpower-headcount/${id}`);
  }

  save(body: ManpowerHeadcount): Observable<ManpowerHeadcount> {
    return this.http.post<ManpowerHeadcount>(`${environment.api_endpoint}//manpower-headcount`, body);
  }

  update(id: number, body: ManpowerHeadcount): Observable<ManpowerHeadcount> {
    return this.http.put<ManpowerHeadcount>(`${environment.api_endpoint}//manpower-headcount/${id}`, body);
  }

  delete(id: number): Observable<any> {
    return this.http.delete<ManpowerHeadcount>(`${environment.api_endpoint}//manpower-headcount/${id}`);
  }
}

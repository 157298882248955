<div class="row">
  <div class="col-sm-auto">
    <h5>
      Pending For Approval
    </h5>
  </div>
</div>
<div class="body-element">

  <table class="table table-hover">
    <thead class="thead-pink">
    <tr>
      <th scope="col" style="width: 3%;">ID</th>
      <th scope="col" style="width: 25%;">Position</th>
      <th scope="col" style="width: 10%;">Level</th>
      <th scope="col" style="width: 20%;  align-items: center; text-align:  center;">Status</th>
      <th scope="col" style="width: 18%;">Request</th>
      <th scope="col" style="width: 10%;">Request date</th>
      <th scope="col" style="width: 10%; white-space: nowrap">Pending at</th>
    </tr>
    </thead>
    <tbody>
    <tr *ngFor="let manPower of manPowerListPerPage;index as i" (click)="handleOnViewRequisition(manPower)"
        style="cursor: pointer;">
      <td>{{ manPower?.id }}</td>
      <td>{{ manPower?.positionName || manPower?.position?.positionName }}</td>
      <td>{{ manPower?.level }}</td>
      <td>
        <button *ngIf="manPower?.status === 'DRAFT'"
                class="bg-secondary status-container btn py-1 btn-man-power-status">
          {{ manPower?.status?.toUpperCase() }}
        </button>
        <button
          *ngIf="manPower?.status === 'PENDING' || manPower?.status === 'PENDING_HR' || manPower?.status === 'PENDING_PRESIDENT'"
          class="bg-c-set status-container btn py-1 btn-man-power-status">
          {{ manPower?.status?.toUpperCase() }}
        </button>
        <button *ngIf="manPower?.status === 'APPROVED'"
                class="bg-success status-container btn py-1 btn-man-power-status">
          {{ manPower?.status?.toUpperCase() }}
        </button>
        <button *ngIf="manPower?.status === 'REJECTED'"
                class="bg-danger status-container btn py-1 btn-man-power-status">
          {{ manPower?.status?.toUpperCase() }}
        </button>
      </td>
      <td>{{ manPower?.requester?.nameTh }}</td>
      <td> {{ manPower?.requesterDate | date:'dd MMM yyyy' }}</td>
      <td style="white-space: nowrap"> {{ manPower?.pendingAt?.nameTh }}</td>
    </tr>
    <tr>
      <td *ngIf="manPowerList.length == 0" colspan="8" class="text-center">
        <h5>
          <b>
            No Data To Display
          </b>
        </h5>
      </td>

    </tr>
    </tbody>
  </table>
  <div *ngIf="manPowerList">
    <div *ngIf="manPowerList.length > 10">
      <pagination
        [directionLinks]="true"
        [boundaryLinks]="false"
        [maxSize]="10"
        [previousText]="paginationConfig.previousText"
        [nextText]="paginationConfig.nextText"
        [totalItems]="manPowerList.length"
        [(ngModel)]="currentPage"
        [itemsPerPage]="paginationConfig.itemsPerPage"
        (pageChanged)="handlePaging($event)">
      </pagination>
    </div>
  </div>
</div>

<form [formGroup]="manPowerForm">
  <div class="row justify-content">
    <h4 class="col-sm-auto">แบบขออนุมัติอัตรากำลังพนักงาน</h4>
    <div id="action-flow" class="line col-9" *ngIf="status && status != 'DRAFT'">
      <ul id="line" class="mb-0 mt-0">
        <li *ngFor="let item of allCommandChain;index as i"
            [class.active]="indexOfApprover > i || status === 'APPROVED'">
          <p
            style="font-size: 14px; margin-bottom: 0"
          >
            <small>{{ item.nameTh ? item.nameTh : '' }}</small>
          </p>
        </li>
      </ul>
    </div>
  </div>
  <div class="row mt-2" style="margin-bottom: 16px">
    <div class="col-1">
      <button type="button" class="btn btn-block btn-secondary" (click)="onBack(template)">BACK</button>
    </div>
    <ng-container *ngIf="isHrAdmin && 'approve' === forAction && displayEdit">
      <div class="col-1 mr-2 ml-auto pr-1">
        <button type="button" (click)="confirmCancelWorkflow(cancelModal)" class="btn btn-block btn-warning text-light">
          Cancel
        </button>
      </div>
      <div class="col-1 pl-1">
        <button type="button" class="btn btn-block btn-success"
                (click)="onShowModalForHrAdmin(templateAdminApproval,'approve')">APPROVE
        </button>
      </div>
      <div class="col-1 pl-1">
        <button type="button" class="btn btn-block btn-danger"
                (click)="onShowModalForHrAdmin(templateAdminApproval,'reject')">REJECT
        </button>
      </div>
    </ng-container>
    <ng-container *ngIf="!isHrAdmin">
      <div class="col-auto ml-auto pr-1">
        <button type="button" class="btn btn-block btn-success" *ngIf="'approve' === forAction && isPendingMe"
                (click)="onShowConfirmationModal(template,'approve')">APPROVE
        </button>
      </div>
      <div class="col-1 pl-1">
        <button type="button" class="btn btn-block btn-danger"
                *ngIf="'approve' === forAction && isPendingMe && !this.checkIsPresident()"
                (click)="onShowConfirmationModal(templateReject,'reject')">REJECT
        </button>
        <!--        //เดิม president president reject ไม่มี model confirm -->
        <button type="button" class="btn btn-block btn-danger"
                *ngIf="'approve' === forAction && isPendingMe && checkIsPresident()"
                (click)="onShowConfirmationModal(templatePresidentReject,'reject')">REJECT
        </button>
      </div>
    </ng-container>
  </div>

  <hr class="set">

  <div>
    <div class="row">
      <p class="col-sm-2 text-right">หน่วยงาน :</p>
      <div class="col-sm-4">
        <div class="form-row">
          <div class="col-sm-10">
            <input type="text" autocomplete="off" class="form-control"
                   [formControl]="manPowerForm.get('department').get('deptName')" [typeahead]="filterDept"
                   typeaheadOptionField="deptName" [typeaheadScrollable]="true" [typeaheadOptionsInScrollableView]="6"
                   [typeaheadMinLength]="0" (typeaheadOnSelect)="onChangDept($event.item)"
                   (typeaheadOnBlur)="onDeptBlur($event)"
            />
            <!-- <p class="col-sm-4">{{getDeptName(requester?.deptRelation?.department)}}</p> -->
          </div>
        </div>
      </div>
      <p class="col-sm-2 text-right">Request Date :</p>
      <p class="col-sm-4">{{ now | date: 'dd MMM yyyy HH:mm' }}</p>
    </div>

    <div class="row">
      <p class="col-sm-2 text-right">สายงาน :</p>
      <p class="col-sm-4">{{ divName }}</p>
      <p class="col-sm-2 text-right">Requester :</p>
      <p class="col-sm-4">{{ requester?.nameTh }}</p>
    </div>

    <div class="row">
      <p class="col-sm-2 text-right">อัตรากำลัง :</p>
      <p class="col-sm-4">{{ manpowerDept?.amount || '-' }} อัตรา</p>
      <p class="col-sm-2 text-right">Status :</p>
      <p class="col-sm-4">{{ status }}</p>
    </div>

    <div class="row">
      <p class="col-sm-2 text-right">จำนวนพนักงานปัจจุบัน :</p>
      <p class="col-sm-4">{{ manpowerDept?.actual || '0' }} อัตรา</p>
    </div>
  </div>

  <hr class="set">

  <div>
    <h5>
      ความประสงค์ขอรับพนักงาน
    </h5>

    <div class="row  mt-4">
      <p class="col-sm-2 text-right">เหตุผลในการรับพนักงาน</p>
      <div class="col-5">
        <div class="row">
          <div class="col-auto">
            <div class="form-check form-check-inline">
              <input class="form-check-input" type="radio" name="reason" id="replace" value="อัตราทดแทน"
                     formControlName="reason"
                     (change)="onChangeReason()"
              >
              <label class="form-check-label" for="replace">อัตราทดแทน</label>
            </div>
            <div class="form-check form-check-inline">
              <input class="form-check-input" type="radio" name="reason" id="new" value="อัตราใหม่"
                     formControlName="reason"
                     (change)="onChangeReason()"
              >
              <label class="form-check-label" for="new">อัตราใหม่</label>
            </div>
          </div>

        </div>
      </div>

      <p class="col-sm-1 text-right mt-1 mb-0">สังกัด</p>
      <div class="col-sm-4">
        <input type="text" class="form-control" [formControl]="manPowerForm.get('unit').get('deptName')"
               [typeahead]="filterUnit"
               [typeaheadScrollable]="true" [typeaheadOptionsInScrollableView]="6"
               [typeaheadItemTemplate]="customItemTemplate"
               typeaheadOptionField="deptName"
               [typeaheadMinLength]="0" (typeaheadOnSelect)="onChangUnit($event)"/>
      </div>
    </div>

    <!--    <div class="row mt-3" *ngIf="manPowerForm.get('reason').value === 'อัตราทดแทน'">-->
    <!--      <div class="col-sm-2"></div>-->
    <!--      <div class="col-sm-5">-->
    <!--      </div>-->
    <!--      <div class="col-sm-5">-->

    <!--      </div>-->
    <!--    </div>-->
    <div class="row mt-4">
      <p class="col-sm-2 text-right mt-1 mb-0">ตำแหน่งที่รับสมัคร</p>
      <div class="col-sm-4">
        <div class="row">
          <div class="col-sm-9 pr-0">
            <select class="form-control"
                    [disabled]="manPowerForm.get('reason').disabled"
                    [(ngModel)]="selectedReplacePosition"
                    (change)="onSelectPosition()"
                    [ngModelOptions]="{standalone: true}"
            >
              <option [ngValue]="null">- ระบุตำแหน่ง -</option>
              <option *ngFor="let ap of filterPositionV2" [ngValue]="ap">
                {{ ap.positionName }} ({{ ap.positionCode }})
              </option>
            </select>

          </div>
          <!--          <p class="col-sm-2 text-right mt-1 mb-0">ระดับ</p>-->
          <div class="col-sm-3">
            <!--                   (typeaheadOnSelect)="onChangeLevel($event)"-->
            <input type="text" class="form-control" formControlName="level"
                   autocomplete="off"
                   placeholder="ระดับ"
                   [typeahead]="filterLevel"
                   typeaheadOptionField="level"
                   [typeaheadScrollable]="true"
                   [typeaheadOptionsInScrollableView]="6"
                   [typeaheadMinLength]="0"
            />
          </div>
          <!--          &#45;&#45;&#45;&#45;-->
        </div>
      </div>
      <p class="col-sm-2 text-right mt-1 mb-0">ชื่อสำหรับประกาศรับสมัคร</p>
      <div class="col-sm-4">
        <input type="text" class="form-control" [formControl]="manPowerForm.get('positionName')"
               [typeahead]="filterPosition"
               typeaheadOptionField="positionName" [typeaheadScrollable]="true"
               [typeaheadOptionsInScrollableView]="6"
               [typeaheadMinLength]="0" (typeaheadOnSelect)="onChangePosition($event)"
        />
      </div>
    </div>

    <div class="row mt-4">
      <div class="col-sm-2"></div>
      <div class="col-sm-4">
        <input type="text" class="form-control" formControlName="replaceName" placeholder="ระบุชื่อพนักงาน"
               *ngIf="manPowerForm.get('reason').value === 'อัตราทดแทน'"
               [typeahead]="empInDept"
               typeaheadOptionField="extraName"
               autocomplete="off"
               id="courseName"
               [typeaheadMinLength]="0"
               [typeaheadScrollable]="true"
               [typeaheadOptionsInScrollableView]="10"/>
      </div>
    </div>

    <div class="row mt-4">
      <div class="col-sm-2 text-right">
        <p class="mb-0">เหตุผลในการรับพนักงาน</p>
        <p>( รายละเอียด )</p>
      </div>
      <div class="col-sm-10 pr-0">
        <textarea class="form-control" formControlName="detail" rows="5"></textarea>
      </div>
    </div>

    <div class="row mt-4">
      <p class="col-sm-2 text-right">หน้าที่ความรับผิดชอบ</p>
      <div class="col-sm-10 pr-0">
        <textarea class="form-control" formControlName="responsibility" rows="5"></textarea>
      </div>
    </div>

    <div class="row mt-4">
      <p class="col-sm-2 text-right">คุณสมบัติ</p>
      <div class="col-sm-10 pr-0">
        <textarea class="form-control" formControlName="qualification" rows="5"></textarea>
      </div>
    </div>


    <div class="row mt-4">
      <p class="col-sm-2 text-right" style="top:5px">ภาษาอังกฤษ (TOEIC) </p>
      <div class="col-sm-4">
        <div class="form-check form-check-inline">
          <input class="form-check-input" type="radio" name="englishSkill" id="v600" value="600"
                 formControlName="englishSkill">
          <label class="form-check-label" for="v600">600 คะแนน</label>
        </div>
        <div class="form-check form-check-inline">
          <input class="form-check-input" type="radio" name="englishSkill" id="other" value="other"
                 formControlName="englishSkill">
          <label class="form-check-label" style="margin-right: 12px;" for="other">ระบุ</label>
          <input type="number" class="form-control" formControlName="englishScore" [min]="0">
        </div>
      </div>
    </div>
    <div class="row mt-4" *ngIf="!status ||  ['DRAFT'].includes(status)">
      <p class="col-sm-2 text-right">หมายเหตุ</p>
      <div class="col-sm-10 pr-0">
        <textarea class="form-control" formControlName="requestRemark" rows="5"></textarea>
      </div>
    </div>
    <!--   <div class="row mt-4" *ngIf="['PENDING_HR'].includes(status)">-->
    <!--      <p class="col-sm-2 text-right">ความคิดเห็น HR</p>-->
    <!--      <div class="col-sm-10 pr-0">-->
    <!--        <textarea class="form-control"  [(ngModel)]="reject" [ngModelOptions]="{standalone: true}" rows="5"></textarea>-->
    <!--      </div>-->
    <!--    </div>-->

  </div>
  <hr class="set">

  <div class="row mt-4 mb-4"
       *ngIf="(this.isHRVPObj?.isHRVP || this.isHRVPObj?.isHRVPManager ) && manPowerForm.get('hrvpComment').enabled">
    <p class="col-sm-2 text-right">ความคิดเห็น HR</p>
    <div class="col-sm-10 pr-0">
      <textarea class="form-control" formControlName="hrvpComment" rows="5"></textarea>
    </div>
  </div>
  <div class="row mt-4 mb-4" *ngIf="checkIsPresident() && manPowerForm.get('mdComment').enabled">
    <p class="col-sm-2 text-right">ความคิดเห็น ผู้จัดการ</p>
    <div class="col-sm-10 pr-0">
      <textarea class="form-control" formControlName="mdComment" rows="5"></textarea>
    </div>
  </div>
  <hr class="set" *ngIf="(isAdmin || isPresident) && manPowerForm.enabled">

  <div class="row mt-2" *ngIf="isPendingPresident" style="margin-bottom: 16px">
    <div class="col-1">
      <button type="button" class="btn btn-block btn-secondary" (click)="onBack(template)">BACK</button>
    </div>
    <div class="col-auto ml-auto pr-1">
      <!-- <button type="button" class="btn btn-block btn-success" *ngIf="('approve' === forAction && isPendingMe) || (status === 'PENDING' && isAdmin)" (click)="onShowConfirmationModal(template,'approve')">APPROVE</button> -->
      <button type="button" class="btn btn-block btn-success" *ngIf="'approve' === forAction && isPendingMe"
              (click)="onShowConfirmationModal(template,'approve')">APPROVE
      </button>
    </div>
    <div class="col-1 pl-1">
      <button type="button" class="btn btn-block btn-danger"
              *ngIf="'approve' === forAction && isPendingMe && !checkIsPresident()"
              (click)="onShowConfirmationModal(templateReject,'reject')">REJECT
      </button>

      <!--      //president-->
      <button type="button" class="btn btn-block btn-danger"
              *ngIf="'approve' === forAction && isPendingMe && checkIsPresident()"
              (click)="onShowConfirmationModal(templatePresidentReject,'reject')">REJECT
      </button>
    </div>
  </div>

  <div class="row justify-content-end" *ngIf="baseButtonCondition" style="margin-bottom: 16px">
    <div class="col-sm-auto row">
      <button type="button"
              *ngIf="displayEdit"
              class="col-sm-auto btn btn-outline-primary mr-2"
              (click)="toggleEdit()"> {{ this.manPowerForm.disabled ? 'EDIT' : 'SAVE' }}
      </button>
      <button type="button" class=" btn btn-secondary mr-2"
              (click)="onShowConfirmationModal(template,'back')">BACK
      </button>
      <button type="button" class=" btn btn-outline-primary mr-2" *ngIf="canDraft"
              (click)="onShowConfirmationModal(template,'save')">SAVE AS DRAFT
      </button>
      <button *ngIf="(canDraft) && 'approve' !== forAction" type="button" class=" btn btn-primary"
              [ngClass]="isPendingMe ?  'mr-2' : 'mr-3'" (click)="onShowConfirmationModal(template,'submit')">
        <fa-icon [icon]="faIcon.faCheck" class="text-white"></fa-icon>
        SUBMIT
      </button>
      <!-- <button type="button" class="col-sm-auto btn btn-success mr-2" *ngIf="('approve' === forAction && isPendingMe) || (status === 'PENDING' && isAdmin)" (click)="onShowConfirmationModal(template,'approve')">APPROVE</button> -->
      <!--for hr admin-->
      <button type="button" class="btn btn-success mr-2" *ngIf="adminApprove"
              (click)="onShowConfirmationModal(approveWithReason,'approve')">APPROVE
      </button>

      <button type="button" class="btn btn-success mr-2" *ngIf="('approve' === forAction && isPendingMe)"
              (click)="onShowConfirmationModal(template,'approve')">APPROVE
      </button>
      <button type="button" class="btn btn-danger mr-2"
              *ngIf="('approve' === forAction && isPendingMe)|| adminApprove"
              (click)="onShowConfirmationModal(templateReject,'reject')">REJECT
      </button>
      <button type="button" class="btn btn-outline-danger mr-2" *ngIf="canCancel"
              (click)="onShowConfirmationModal(template,'cancel')">CANCEL
      </button>
    </div>
  </div>

  <div class="mt-3 mb-3">
    <h5>ประวัติการดำเนินงาน</h5>

    <div class="body-element">

      <table class="table table-hover">
        <thead class="thead-pink">
        <tr>
          <th scope="col" style="width: 30%;">ACTION BY</th>
          <th scope="col" style="width: 10%;">ACTION</th>
          <th scope="col" style="width: 15%;">DATE</th>
          <th scope="col" style="width: 40%;">COMMENT</th>
          <th scope="col" style="width: auto;">Attachments</th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let log of manPowerLogs;index as i">
          <th>{{ log.actionByName }}</th>
          <td>{{ log.action }}</td>
          <td>{{ log.actionDate ? (log.actionDate | date: 'dd MMM yyyy HH:mm') : '-' }}</td>
          <td>{{ log.comment ? log.comment : '-' }}</td>
          <td class="text-center">
            <fa-icon *ngIf="log.attachments && log.attachments.length > 0" [icon]="faIcon.faPaperclip" class="text-info"
                     (click)="onShowAttachment(log.attachments, attachmentListModal)"></fa-icon>
          </td>
        </tr>
        <tr>
          <td *ngIf="manPowerLogs.length == 0" colspan="8" class="text-center">
            <h5>
              <b>
                No Data To Display
              </b>
            </h5>
          </td>

        </tr>

        </tbody>
      </table>
    </div>
  </div>

  <hr class="set">
</form>

<ng-template #template>
  <div class="modal-header thead-primary" style="background-color: #fab232;">
    <h4 class="modal-title pull-left ml-3">Confirmation</h4>
    <button type="button" class="close pull-right text-light" aria-label="Close" (click)="modalRef.hide()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="form-group ml-3">
      <div class="d-flex">
        <h5>{{ confirmationWording }}</h5>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <div class="d-flex mt-2 justify-content-end">
      <button class="btn btn-success mr-2" (click)="confirm()">Confirm</button>
      <button class="btn btn-danger" (click)="modalRef.hide()">Cancel</button>
    </div>
  </div>
</ng-template>


<ng-template #cancelModal>
  <div class="modal-header thead-primary" style="background-color: #fab232;">
    <h4 class="modal-title pull-left ml-3">Confirmation</h4>
    <button type="button" class="close pull-right text-light" aria-label="Close" (click)="modalRef.hide()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="form-group ml-3">
      <div class="d-flex">
        <h6>Are you sure you want to <u>cancel</u> this request ? </h6>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <div class="d-flex mt-2 justify-content-end">
      <button class="btn btn-success mr-2" (click)="confirmCancel()">Confirm</button>
      <button class="btn btn-danger" (click)="modalRef.hide()">Cancel</button>
    </div>
  </div>
</ng-template>

<ng-template #attachmentListModal>
  <div class="modal-header thead-primary" style="background-color: #fab232;">
    <h4 class="modal-title pull-left ml-3">Attachments</h4>
    <button type="button" class="close pull-right text-light" aria-label="Close" (click)="modalRef.hide()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <ul class="attachment-list">
      <li class="clickable"
          (click)="downloadAttachmentWithId(file.id)"
          [title]="file.originFileName.concat('.').concat(file.fileType)"
          *ngFor="let file of attachmentInfoList; index as i">
        {{ i + 1 }}. {{ file.originFileName.concat('.').concat(file.fileType) | textTruncate: 40 }}
      </li>
    </ul>
  </div>
</ng-template>


<ng-template #approveWithReason>
  <div class="modal-header thead-primary" style="background-color: #fab232;">
    <h4 class="modal-title pull-left ml-3">Confirmation</h4>
    <button type="button" class="close pull-right text-light" aria-label="Close" (click)="modalRef.hide()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="form-group">
      <label for="approve-reason">Are you sure you want to approve this request ?<br/><u><b>Please fill
        reason</b></u></label>
      <textarea [(ngModel)]="approveReason" class="form-control" id="approve-reason" rows="3"></textarea>
    </div>
  </div>
  <div class="modal-footer">
    <div class="d-flex mt-2 justify-content-end">
      <button class="btn btn-success mr-2" (click)="confirm()">Confirm</button>
      <button class="btn btn-danger" (click)="modalRef.hide()">Cancel</button>
    </div>
  </div>
</ng-template>

<ng-template #templateReject>
  <div class="modal-header thead-primary" style="background-color: #fab232;">
    <h4 class="modal-title pull-left ml-3">Reject reason</h4>
    <button type="button" class="close pull-right text-light" aria-label="Close" (click)="modalRef.hide()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="form-group ml-3">
      <div class="d-flex">
        <textarea [(ngModel)]="reject" class="form-control" name="reason" id="rejectReason" rows="10"></textarea>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <div class="d-flex mt-2 justify-content-end">
      <button class="btn btn-success mr-2" [disabled]="!reject || (reject && reject.trim() === '')" (click)="confirm()">
        Confirm
      </button>
      <button class="btn btn-danger" (click)="modalRef.hide()">Cancel</button>
    </div>
  </div>
</ng-template>

<ng-template #templatePresidentReject>
  <div class="modal-header thead-primary" style="background-color: #fab232;">
    <h4 class="modal-title pull-left ml-3">Reject</h4>
    <button type="button" class="close pull-right text-light" aria-label="Close" (click)="modalRef.hide()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    Do you want to reject?
    <!--    <div class="form-group ml-3">-->
    <!--      <div class="d-flex">-->
    <!--        <textarea [(ngModel)]="reject" class="form-control" name="reason" id="rejectReason" rows="10"></textarea>-->
    <!--      </div>-->
    <!--    </div>-->
  </div>
  <div class="modal-footer">
    <div class="d-flex mt-2 justify-content-end">
      <button class="btn btn-success mr-2" (click)="presidentConfirmReject()">
        Reject
      </button>
      <button class="btn btn-danger" (click)="modalRef.hide()">Cancel</button>
    </div>
  </div>
</ng-template>

<ng-template #templateAdminApproval>
  <div class="modal-header thead-primary" style="background-color: #fab232;">
    <h4 class="modal-title pull-left ml-3">Reason for {{ hrAdminActionModel }}</h4>
    <button type="button" class="close pull-right text-light" aria-label="Close" (click)="modalRef.hide()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="form-group ml-3 mr-3">
      <div>
        <textarea [(ngModel)]="hrAdminActionReason" class="form-control" name="reason" id="rejectReasonx"
                  rows="6"></textarea>
      </div>
      <div>
        <h6 style="margin-top: 6px;">
          Attachments
        </h6>
        <input type="file" class="form-control" (change)="onFileChange($event)" multiple>
        <ul class="attachment-list">
          <li *ngFor="let file of adminAttachmentList; index as i">
            {{ i + 1 }}.
            {{ file.name | textTruncate: 40 }}
            <fa-icon [icon]="faIcon.faTrash" (click)="handlerDeleteAttachment(i)" class="del-attachment">
            </fa-icon>
          </li>
        </ul>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <div class="d-flex mt-2 justify-content-end">
      <button class="btn btn-success mr-2"
              [disabled]="!hrAdminActionReason || (hrAdminActionReason && hrAdminActionReason.trim() === '')"
              (click)="onConfirmHrAdminApproval()">
        Confirm
      </button>
      <button class="btn btn-danger" (click)="onCancelHrAdminApproval()">Cancel</button>
    </div>
  </div>
</ng-template>

<app-loading-overlay [display]="loading"></app-loading-overlay>

<ng-template #customItemTemplate let-model="item" let-index="index">
  <h6>{{ model.deptName }}</h6>
</ng-template>

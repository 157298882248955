import {Component, EventEmitter, Input, OnInit, Output, TemplateRef} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {OutsourceTimesheetService} from '../../../../core/services/outsource-timesheet.service';
import {
  CalculateAmount,
  Log,
  OutsourceTimesheetInfo,
  OutsourceWeeklyModel,
  UpdateApprovalReviewer
} from '../../../../modules/outsource-time-sheet/core/models/outsource.weekly.model';
import * as dayjs from 'dayjs';
import {map} from 'rxjs/operators';
import {FormArray, FormBuilder, FormGroup} from '@angular/forms';
import {DailyOptionEnum, dailyOptions} from '../../../../modules/outsource-time-sheet/config/option.config';
import {EmployeeService} from '../../../../core/services/employee.service';
import {Employee} from '../../../../core/models/employee';
import Swal from 'sweetalert2';
import {InternStatusEnum, TimesheetKeyInfo} from '../../../../core/models/timesheetInfo';
import {combineLatest} from 'rxjs';
import {ExportToCsv} from 'export-to-csv';
import {HolidayService} from '../../../../core/services/holiday.service';
import {jsPDF} from 'jspdf';
import {FontList} from '../font.config';
import autoTable from 'jspdf-autotable';
import {BsModalRef, BsModalService} from 'ngx-bootstrap';
import {saveAs} from 'file-saver';

@Component({
  selector: 'app-monthly-detail',
  templateUrl: './monthly-detail.component.html',
  styleUrls: ['./monthly-detail.component.scss']
})
export class MonthlyDetailComponent implements OnInit {
  readonly months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];

  loading = false;
  options = dailyOptions
  detail: OutsourceWeeklyModel;
  projectInfo: OutsourceTimesheetInfo;
  config = {
    columnLength: 11
  }
  f0ntList = FontList;
  reviewForm: FormGroup
  @Output()
  name: EventEmitter<string> = new EventEmitter<string>()
  employee: Employee
  @Input()
  IsInternPage: boolean;
  @Input()
  public openByAdminPage: boolean;
  onLoadApprovalForm = false;

  logsList: Log[] = []

  @Input('timesheetKeyInfo')
  set prepareDate(d: TimesheetKeyInfo) {
    if (d) {
      this.timesheetKeyInfo = d;
      this.doLoad();
    }
  }

  pdfLoader = false;


  modalRef?: BsModalRef;

  updateApprovalReviewerForm = this.fb.group({

    employeeName: '',
    empId: '',
    month: 0,
    projectName: '',
    approval: '',
    reviewer: '',
    year: 0
  })
  employeeList: Employee[] = [];
  typeAheadApproval: string;
  typeAheadReviewer: string;

  timesheetKeyInfo: TimesheetKeyInfo

  calAmount: CalculateAmount;

  constructor(
    private activateRoute: ActivatedRoute,
    private outsourceTimesheet: OutsourceTimesheetService,
    private fb: FormBuilder,
    private empService: EmployeeService,
    private holidayService: HolidayService,
    private modalService: BsModalService,
  ) {

  }

  ngOnInit(): void {
    this.snapshotParams();
    this.clearForm();

  }

  toggle(d: Log) {
    d.logShowDetail = !d.logShowDetail
    // console.log(d)
  }

  private doLoad() {
    const {empID, month, year, projectName} = this.timesheetKeyInfo;
    this.doLoadList(empID, month, year, projectName);
    this.logs(empID, month, year, projectName);
  }

  async retreat() {
    const {value} = await this.confirmAction('retreat status');
    if (!value) {
      return;
    }
    const {month, year, empId, projectName} = this.detail;
    this.outsourceTimesheet.retreatStatus(empId, projectName, month, year)
      .toPromise()
      .then(() => {
        this.alert(true, 'Successfully')
        this.snapshotAndRequest();
      })
      .catch((e) => this.alert(false, 'Status cannot be retrieved.'))
  }

  clearForm() {
    this.reviewForm = this.fb.group(this.initialFormValue())
  }

  private snapshotParams() {
    this.snapshotAndRequest();
  }

  get isReviewer(): boolean {
    return (['PENDING_REVIEW', 'REVIEWED'].includes(this.detail?.status) && this.detail?.reviewer === this.employee?.empID)
  }

  get isNotPayment(): boolean {
    return (this.detail?.status !== 'PAYMENT')
  }

  get isApproval(): boolean {
    return this.detail?.status === 'REVIEWED' && this.employee?.empID === this.detail?.approval;
  }

  get isOwner(): boolean {
    return this.detail?.empId === this.employee?.empID &&  ['PENDING_REVIEW', 'NOT_SUBMIT'].includes(this.detail?.status);
  }

  get showSaveBTN() {
    return (this.isNotPayment && !this.isApproval && this.IsInternPage) && this.hrCanSave || this.isReviewer || this.isOwner;
  }

  get showRetreat() {
    return this.openByAdminPage && !['PENDING_REVIEW', 'NOT_SUBMIT', 'PAYMENT'].includes(this.detail?.status)
  }

  get hrCanSave() {
    const notPayment = this.detail?.status !== InternStatusEnum.PAYMENT
    const filter = this.employee?.userRoles_?.filter(r => r.role.toLowerCase() === 'hradmin' || r.role.toLowerCase() === 'outsourceadmin');
    return filter && filter.length > 0 && notPayment
  }

  private snapshotAndRequest() {
    const {params: {empId, month, year}, queryParams: {projectName}} = this.activateRoute.snapshot;
    if (empId && month && year && projectName) {
      this.timesheetKeyInfo = {
        empID: empId,
        month: +month,
        year: +year,
        projectName
      }
      this.doLoadList(empId, month, year, projectName);
      this.logs(empId, month, year, projectName);
      this.loadCalculateAmount(empId, month, year, projectName);
    }

  }

  private loadCalculateAmount(empId, month, year, projectName) {
    this.outsourceTimesheet.getCalculateAmount(empId, month, year, projectName)
      .toPromise()
      .then(r => {
        this.calAmount = r
      })
  }

  public openTimesheet() {
    if (!this.timesheetKeyInfo || this.pdfLoader) {
      return;
    }
    this.pdfLoader = true;

    const {empID, month, year, projectName} = this.timesheetKeyInfo;
    this.outsourceTimesheet.downloadTimesheet(empID, month, year, projectName).toPromise()
      .then(res => {
        const blob = new Blob([res.body], {type: 'application/pdf'});
        const reader = new FileReader();
        const url = window.URL.createObjectURL(blob);

        const anchor = document.createElement('a');
        anchor.href = url;
        anchor.download = `${this.projectInfo.nameEn}-${this.detail.month + 1}-${this.detail.year}.pdf`;
        anchor.target = '_blank';

        anchor.click();
      }).catch(err => {
      console.error(err);
    }).finally(() => {
      this.pdfLoader = false;
    })
  }

  public openTimesheetCSV() {
    const {empID, month, year, projectName} = this.timesheetKeyInfo;
    console.log('export new csv')
    this.outsourceTimesheet.downloadTimesheetCSV(empID, month, year, projectName).toPromise()
      .then(res => {
        saveAs(res, `${empID}-${month + 1}-${year}.csv`);
        // const blob = new Blob([res.body], {type: 'application/pdf'});
        // const reader = new FileReader();
        // const url = window.URL.createObjectURL(blob);
        //
        // const anchor = document.createElement('a');
        // anchor.href = url;
        // anchor.target = '_blank';
        //
        // anchor.click();
      }).catch(err => {
      console.error(err);
    })
  }

  private logs(empId, month, year, projectName) {
    this.outsourceTimesheet.logs(empId, month, year, projectName)
      .toPromise()
      .then(l => {
        this.logsList = l;
      })
      .catch(e => {
        this.logsList = [];
      })

  }

  private doLoadList(empId, month, year, projectName) {
    this.loading = true;
    this.outsourceTimesheet.getMonthlyTimesheetInfo(empId, month, year, projectName)
      .toPromise()
      .then(r => {
        this.projectInfo = r
        this.name.emit(r.nameEn)
      })
      .catch(() => {
      })
      .finally(() => {
        this.loading = false;
        this.loadHolidays();
      })


    //   .toPromise().then(e => {
    //   this.employee = e;
    // })
    combineLatest([
      this.empService.me(),
      this.outsourceTimesheet.getMonthlyTimesheetDetail(empId, month, year, projectName)])
      .pipe(map(c => {
        c[1].days = c[1].days.filter(d => d.date);
        this.employee = c[0];
        return c;
      }))
      .toPromise()
      .then(data => {
        const r = data[1];
        this.clearForm();
        this.detail = r;
        const {days, ...rest} = r;
        this.reviewForm.patchValue({
          ...rest,
        });
        for (const day of days) {
          this.arrayDays.push(this.fb.group(this.buildArrEl(day)))
        }

        if (this.showSaveBTN) {
          this.enableDays();
        } else {
          this.disableDays();
        }
      })

    // this.outsourceTimesheet.getMonthlyTimesheetDetail(empId, month, year, projectName)
    //   .pipe(map(r => {
    //     r.days = r.days.filter(d => d.date);
    //     return r;
    //   }))
    //   .toPromise().then(r => {
    //   this.clearForm();
    //   this.detail = r;
    //   const {days, ...rest} = r;
    //   this.reviewForm.patchValue({
    //     ...rest,
    //   });
    //   for (const day of days) {
    //     this.arrayDays.push(this.fb.group(this.buildArrEl(day)))
    //   }
    //
    //   if (this.showReviewButton()) {
    //     this.enableDays();
    //   } else {
    //     this.disableDays();
    //   }
    //
    // })
  }

  private loadHolidays() {
    this.holidayService.getHoliday().toPromise()
      .then((hl) => {
        for (const control of this.arrayDays.controls) {
          const found = hl.find(h => h.holiday === control.get('date').value);
          if (found && (!control.get('daily').value || control.get('daily').value === DailyOptionEnum.NOT_SELECT)) {
            control.patchValue({
              daily: DailyOptionEnum.HOLIDAY
            })
          }
        }
      })
      .catch(console.error)
  }

  private disableDays() {
    this.arrayDays.controls.forEach(d => d.disable())
  }

  private enableDays() {
    return this.arrayDays.controls.forEach(d => d.enable())
  }

  clearFormArray = (formArray: FormArray) => {
    while (formArray.length !== 0) {
      formArray.removeAt(0)
    }
  }

  initialFormValue() {
    return {
      id: '',
      year: '',
      month: '',
      weekOfMonth: '',
      weekOfYear: '',
      empId: '',
      projectName: '',
      status: '',
      days: this.fb.array([])
    }
  }

  get arrayDays(): FormArray {
    return this.reviewForm.get('days') as FormArray
  }

  public roundAsArray(value: number) {
    if (value < 0) {
      value = 0;
    }
    const rounded = Math.round(value)
    return Array(rounded);
  }

  buildArrEl({dayOfWeekNo, date, daily, remark}) {
    return {
      dayOfWeekNo,
      date,
      daily,
      remark
    }
  }

  async submit(isReview: boolean) {

    if (isReview) {
      const {value} = await this.confirmAction('review');
      if (value) {
        this.doSubmit(true);
      }
    } else {
      this.doSubmit(false);
    }

  }


  private doSubmit(isReview) {
    this.loading = true;
    this.outsourceTimesheet.save(this.reviewForm.getRawValue() as OutsourceWeeklyModel, isReview)
      .toPromise()
      .then(() => {
        this.alert(true, 'The data has been submitted')
        this.snapshotParams();
        this.loading = false;
      })
      .catch(() => {
        this.alert(false, 'something went wrong ');
        this.loading = false;
      })
  }

  getDay(date: string) {
    return dayjs(date).format('dd')
  }

  isWeekend(day: string): boolean {
    return day === 'Su' || day === 'Sa'
  }

  async approve() {
    const {value} = await this.confirmAction('approve');
    if (value) {
      const {days, ...data} = this.reviewForm.getRawValue();
      data.status = 'APPROVED'
      this.outsourceTimesheet.saveAction(data).toPromise()
        .then(() => {
          this.alert(true, 'The data has been submitted')
          this.snapshotParams()
        })
        .catch(() => this.alert(false, 'Something went wrong '))
    }
  }

  async reject() {
    const {value} = await this.confirmReject();
    if (value) {
      const {days, ...data} = this.reviewForm.getRawValue();
      data.status = 'REJECTED'
      data.comment = value;
      this.outsourceTimesheet.saveAction(data).toPromise()
        .then(() => {
          this.alert(true, 'The data has been submitted')
          this.snapshotParams()
        })
        .catch(() => this.alert(false, 'something went wrong '))

    }

  }

  confirmReject() {
    return Swal.fire({
      scrollbarPadding: false,
      input: 'textarea',
      inputPlaceholder: 'Comment',
      showCancelButton: true,
      confirmButtonText: 'REJECT',
      buttonsStyling: false,
      customClass: {
        confirmButton: 'btn btn-danger text-light mr-2',
        cancelButton: 'btn btn-secondary text-light',
      },
    })
  }

  confirmAction(action: 'approve' | 'review' | 'retreat status') {
    return Swal.fire({
      scrollbarPadding: false,
      icon: 'question',
      text: `Are you sure you want to ${action} this timesheet`,
      buttonsStyling: false,
      showCancelButton: true,
      confirmButtonText: `${action.toUpperCase()}`,
      focusCancel: true,
      customClass: {
        confirmButton: 'btn btn-success text-light mr-2',
        cancelButton: 'btn btn-secondary text-light',
      },
    });
  }


  alert(isSuccess: boolean, msg) {
    return Swal.fire({
      scrollbarPadding: false,
      title: isSuccess ? 'Successfully' : 'Failed',
      icon: isSuccess ? 'success' : 'error',
      text: msg,
      buttonsStyling: false,
      confirmButtonText: 'OK',
      customClass: {
        confirmButton: 'btn btn-warning text-light',
      },
    });
  }

  exportExcel() {
    const optionsJsonToEx = {
      filename: this.genExportFileName(),
      fieldSeparator: ',',
      quoteStrings: '"',
      decimalSeparator: '.',
      showLabels: true,
      useTextFile: false,
      useBom: true,
      useKeysAsHeaders: true
    };
    const json = this.prepareJsonData();
    const csvExporter = new ExportToCsv(optionsJsonToEx);
    csvExporter.generateCsv(json);
  }

  private genExportFileName() {
    return `Timesheet-${this.projectInfo.nameEn}-${this.timesheetKeyInfo.month + 1}-${this.timesheetKeyInfo.year}`;
  }

  private prepareJsonData() {
    const json = this.arrayDays.value.map((t, index) => {
      return {
        name: index === 0 ? this.projectInfo.nameEn : '',
        vendor: index === 0 ? this.projectInfo.vendor : '',
        date: t.date,
        'daily routine': t.daily.replace('NOT_SELECT', '-'),
        remark: t.remark ?? ''
      }
    })
    // console.log(json)
    const fullDay = json.filter(d => d['daily routine'] === DailyOptionEnum.FULL_DAY).length * 8
    const haftDay = json.filter(d => d['daily routine'] === DailyOptionEnum.HALF_DAY).length * 4
    const one = json.filter(d => d['daily routine'] === DailyOptionEnum.ONE_HOUR).length
    const two = json.filter(d => d['daily routine'] === DailyOptionEnum.TWO_HOURS).length * 2
    const three = json.filter(d => d['daily routine'] === DailyOptionEnum.THREE_HOURS).length * 3
    const five = json.filter(d => d['daily routine'] === DailyOptionEnum.FIVE_HOURS).length * 5
    const six = json.filter(d => d['daily routine'] === DailyOptionEnum.SIX_HOURS).length * 6
    const seven = json.filter(d => d['daily routine'] === DailyOptionEnum.SEVEN_HOURS).length * 7
    json.push({
      name: '',
      vendor: '',
      date: 'Total Man-Days',
      'daily routine': (fullDay + haftDay + one + two + three + five + six + seven) / 8,
      remark: ''
    })
    return json;
  }

  onSelectTypeAhead({item}, target: string) {
    // console.log('select item', item);
    if (target === 'reviewer') {
      this.updateApprovalReviewerForm.patchValue({
        reviewer: item.empID
      })
    } else if (target === 'approval') {
      this.updateApprovalReviewerForm.patchValue({
        approval: item.empID
      })
    }
  }

  onUpdateApprovalReviewer() {
    const body = this.updateApprovalReviewerForm.getRawValue() as UpdateApprovalReviewer;
    this.outsourceTimesheet.changeApprovalReviewer(body)
      .toPromise()
      .then(r => {
        this.alert(true, 'Save the data successfully')
        this.doLoad();
        this.modalRef.hide();
      })
      .catch(() => {
        this.alert(true, 'Something went wrong.')
      })
  }

  openModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template, {class: 'modal-lg'});
    this.onLoadApprovalForm = true;
    if (!this.employeeList.length) {
      this.empService.employeeList()
        .toPromise()
        .then(e => {
          this.employeeList = e;
        })
        .catch(() => {
          this.employeeList = []
        })
        .finally(() => {
          this.doPatchApprovalReviewerForm();
        })
    } else {
      this.doPatchApprovalReviewerForm();
    }

  }

  private doPatchApprovalReviewerForm() {
    this.onLoadApprovalForm = false;
    const {nameEn} = this.projectInfo;
    const {projectName, empId, approval, reviewer, month, year} = this.detail;
    const approvalEmp = this.employeeList.find(e => e.empID === approval);
    const reviewerEmp = this.employeeList.find(e => e.empID === reviewer);
    this.updateApprovalReviewerForm.patchValue({
      employeeName: nameEn,
      empId,
      projectName,
      approval,
      reviewer,
      month,
      year
    })
    this.typeAheadApproval = approvalEmp ? approvalEmp.nameEn : '-'
    this.typeAheadReviewer = reviewerEmp ? reviewerEmp.nameEn : '-'
  }


  exportPdf() {
    const doc = new jsPDF('p', 'mm', 'a4');
    for (const key of Object.keys(this.f0ntList)) {
      doc.addFileToVFS(key, this.f0ntList[key]);
    }
    doc.addFont('TH-Krub.ttf', 'TH-Krub', 'normal');
    doc.setFont('TH-Krub');
    const prepareJsonData = this.prepareJsonData();
    const [firstRow] = prepareJsonData;
    const toArrData = prepareJsonData.map(day => {
      const keys = Object.keys(day);
      return keys.map(key => day[key])
    })
    doc.text(`Timesheet: ${this.projectInfo.nameEn} ${this.timesheetKeyInfo.month + 1}-${this.timesheetKeyInfo.year}`, 13, 15);
    autoTable(doc, {
        startY: 20,
        head: [Object.keys(firstRow)],
        body: [...toArrData],
        styles: {font: 'TH-Krub'}
      },
    )

    doc.save(this.genExportFileName())
  }
}

<div class="row justify-content-between">
  <div class="col-sm-6">
    <div class="row">
      <div class="col-sm-8 input-group">
        <input
          type="text"
          class="form-control"
          placeholder="Position"
          [(ngModel)]="keyword"
        />
        <div class="input-group-append">
          <button
            class="btn btn-primary"
            type="button"
            id="button-addon2"
            (click)="searchRequest()"
          >
            <fa-icon [icon]="faIcon.faSearch"></fa-icon>
          </button>
        </div>
      </div>
      <div class="col-sm-4">
        <select class="form-control" [(ngModel)]="selectedStatus"
                (change)="onChangeStatus($event.target.value)">
          <option *ngFor="let status of allStatus" [ngValue]="status">
            {{status}}
          </option>
        </select>
      </div>
    </div>
  </div>
  <div class="col-sm-auto">
    <button type="button" class="btn btn-primary" (click)="onNewRequest()">NEW REQUEST</button>
  </div>
</div>
<div class="body-element">

  <table class="table table-hover">
    <thead class="thead-pink">
    <tr>
      <th scope="col" style="width: 2%;">ID</th>
      <th scope="col" style="width: 25%;">Position</th>
      <th scope="col" style="width: 5%;">Level</th>
      <th scope="col" style="width: 20%;  align-items: center; text-align:  center;">Status</th>
      <th scope="col" style="width: 18%;">Request</th>
      <th scope="col" style="width: 10%;">Request date</th>
      <th scope="col" style="width: 10%;">Pending at</th>
    </tr>
    </thead>
    <tbody>
    <tr *ngFor="let manPower of manPowerListPerPage;index as i" (click)="handleOnViewRequisition(manPower)" style="cursor: pointer;">
      <td>{{ manPower?.id}}</td>
      <td>{{manPower?.positionName || manPower?.position?.positionName}}</td>
      <td>{{manPower?.level}}</td>
      <td class="text-center">
        <button *ngIf="manPower?.status === 'DRAFT'" class="bg-secondary status-container btn py-1 btn-man-power-status">
          {{manPower?.status?.toUpperCase()}}
        </button>
        <button *ngIf="manPower?.status === 'PENDING' || manPower?.status === 'PENDING_HR' || manPower?.status === 'PENDING_PRESIDENT'" class="bg-c-set status-container btn py-1 btn-man-power-status">
          {{manPower?.status?.toUpperCase()}}
        </button>
        <button *ngIf="manPower?.status === 'APPROVED'" class="bg-success status-container btn py-1 btn-man-power-status">
          {{manPower?.status?.toUpperCase()}}
        </button>
        <button *ngIf="manPower?.status === 'REJECTED'" class="bg-danger status-container btn py-1 btn-man-power-status">
          {{manPower?.status?.toUpperCase()}}
        </button>
        <button *ngIf="manPower?.status === 'CANCELED'" class="bg-danger status-container btn py-1 btn-man-power-status">
          {{manPower?.status?.toUpperCase()}}
        </button>
      </td>
      <td>{{manPower?.requester?.nameTh}} </td>
      <td> {{ manPower?.requesterDate | date:'dd MMM yyyy'}}</td>
      <td> {{ manPower?.pendingAt?.nameTh}}</td>
    </tr>
    <tr>
      <td *ngIf="manPowerList.length == 0" colspan="8" class="text-center">
        <h5>
          <b>
            No Data To Display
          </b>
        </h5>
      </td>

    </tr>

    </tbody>
  </table>
  <div *ngIf="manPowerList">
    <div *ngIf="manPowerList.length > 10">
    <pagination
      [directionLinks]="true"
      [boundaryLinks]="false"
      [maxSize]="10"
      [totalItems]="manPowerList.length"
      [previousText]="paginationConfig.previousText"
      [nextText]="paginationConfig.nextText"
      [(ngModel)]="currentPage"
      [itemsPerPage]="paginationConfig.itemsPerPage"
        (pageChanged)="handlePaging($event)">
    </pagination>
    </div>
  </div>
</div>

import { Component, OnInit } from '@angular/core';
import { ExportToCsv } from 'export-to-csv';
import { RequestTrainingService } from '../../core/services/request-training.service';
import { requestTrainingSearch } from 'src/app/core/models/requestTrainingSearch';
import { FormGroup, FormControl } from '@angular/forms';
import { DatePipe } from '@angular/common';
import { faIcon } from 'src/app/core/icon/fa.icon';
import { CompetencyService } from 'src/app/core/services/competency.service';
import { AttendeeService } from 'src/app/core/services/attendee.service';
import { RequestTraining } from 'src/app/core/models/request-training';
import { UserRole } from 'src/app/core/models/userRole';
import { DepartmentServiceService } from 'src/app/core/services/department-service.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-external-training-list',
  templateUrl: './external-training-list.component.html',
  styleUrls: ['./external-training-list.component.scss'],
})
export class ExternalTrainingListComponent implements OnInit {
  constructor(
    private requestTrainingServices: RequestTrainingService,
    public datepipe: DatePipe,
    private competencyService: CompetencyService,
    private employeeService: AttendeeService,
    private departmentService: DepartmentServiceService
  ) {}
  employeeList;
  competencyList;
  departmentList;
  HRVPList;
  /**
   * attendeeObj
   * requesterObject
   * ใช้แค่ bimd ไม่ให้ input ของ attendee & requester error
   */
  attendeeObj = 'All attendees';
  requesterObj = 'All requesters';
  userRoles: UserRole[] = [];

  faIcon = faIcon;
  today: Date = new Date();
  dateFormat = 'DD MMM YYYY';
  searchForm = new FormGroup({
    start: new FormControl(this.today),
    end: new FormControl(this.today),
    keyword: new FormControl(''),
    status: new FormControl(''),
    deptId: new FormControl(''),
    competenciesId: new FormControl(''),
    staffGrade: new FormControl(''),
    attendeeId: new FormControl(''),
    requesterId: new FormControl(''),
  });
  datePickerFormat = { dateInputFormat: this.dateFormat };
  requestTrainingList;

  ngOnInit(): void {
    //this.search();
    this.getHR();
    this.competencie();
    this.getEmployee();
    this.loadDepartmentList();
  }
  competencie() {
    this.competencyService
      .getAllCompetency()
      .toPromise()
      .then((o) => (this.competencyList = o))
      .catch((e) => console.error(e));
  }
  getEmployee() {
    this.employeeService
      .getEmployeeByStatus(true)
      .toPromise()
      .then((a) => {
        this.employeeList = a;
      })
      .catch(console.error);
  }
  formatDate(date: Date) {
    return this.datepipe.transform(date, 'dd MMM yyyy');
  }
  onSelectAttendee({ item }) {
    this.searchForm.patchValue({
      attendeeId: item.empID,
    });
  }
  onSelectRequester({ item }) {
    this.searchForm.patchValue({
      requesterId: item.empID,
    });
  }
  clearRequester() {
    this.requesterObj = undefined;
    this.searchForm.patchValue({
      requesterId: undefined,
    });
  }
  clearAttendee() {
    this.attendeeObj = undefined;
    this.searchForm.patchValue({
      attendeeId: undefined,
    });
  }
  clearFilter() {
    this.attendeeObj = 'All attendees';
    this.requesterObj = 'All requesters';
    this.searchForm.reset({
      start: this.today,
      end: this.today,
      deptId: '',
      status: '',
      competenciesId: '',
      staffGrade: '',
      attendeeId: '',
      requesterId: '',
    });
  }
  search() {
    this.requestTrainingServices
      .searchRequestTraining(this.searchForm.getRawValue())
      .subscribe(
        (resp) => {
          console.log('request list :', resp);
          let state;
          const ext = [];
          resp.forEach((s) => {
            if (s.approvalState) {
              switch (s.approvalState) {
                case 'CANCELED':
                  state = 'CANCELED';
                  break;
                case 'DRAFT':
                  state = 'DRAFT';
                  break;
                case 'PENDING HR':
                case 'PENDING UNIT':
                case 'PENDING DEPT':
                case 'PENDING GROUP':
                case 'PENDING DIV':
                case 'PENDING AAA':
                  state = 'PENDING';
                  break;
                case 'HR REJECT':
                case 'UNIT REJECT ':
                case 'DIV REJECT':
                case 'DEPT REJECT':
                case 'GROUP REJECT':
                case 'AAA REJECT':
                  state = 'REJECTED';
                  break;
                case 'COMPLETED':
                  state = 'APPROVED';
                  break;
              }
            }
            ext.push({
              ...s,
              DisplayState: state,
            });
            state = '';
          });
          this.requestTrainingList = ext;
        },
        (err) => {
          console.log('error', err);
          switch (err.status) {
            case 403: // permission
              this.alert(false, err.error.message);
              break;
            case 404: //not found
              this.alert(false, 'Request training list not found!');
              break;
          }
        }
      );
  }

  debug() {
    this.search();
    console.log('values', this.searchForm.value);
  }
  loadList() {
    this.search();
  }
  /**
   * @return
   * name1
   * name2
   */
  getPendingHrList() {
    let hrNameStr = '';
    this.HRVPList.forEach((data) => {
      hrNameStr = hrNameStr + data.employee.nameTh + '\n';
    });
    return hrNameStr;
  }
  getHRVPList(): string[] {
    let hrName: string[] = [];
    this.HRVPList.forEach((data) => {
      hrName.push(data.employee.nameTh);
    });
    return hrName;
  }
  exportData() {
    let hrPending = false;
    const getHRVPList = this.getHRVPList();
    if (!this.requestTrainingList) return 0;
    let manHour = 7.5;
    let data = this.requestTrainingList.flatMap((data) => {
      if (data.approvalState == 'PENDING HR') hrPending = true;

      let NewObj = {
        'REQUEST DATE': this.datepipe.transform(data.createDate, 'dd/MM/yyyy'),
        COURSE: data.course.courseDescr,
        'START DATE': this.datepipe.transform(data.startDate, 'dd/MM/yyyy'),
        'END DATE': this.datepipe.transform(data.endDate, 'dd/MM/yyyy'),
        AMOUNT: data.totalAmount,
        STATUS: data.DisplayState,
        'PENDING AT': data.pendingName,
        REQUESTER: data.employee?.nameTh,
        'ATTENDEE NAME': data?.attendeeList
          ? data?.attendeeList.find((x) => x)?.employee?.nameTh
          : '',
        DEPARTMENT: data?.attendeeList
          ? data?.attendeeList.find((x) => x)?.employee?.deptRelation.deptName
          : '',
        'ATTENDEE LEVEL': data?.attendeeList
          ? data?.attendeeList.find((x) => x)?.employee?.grade
          : '',
        'MAN-HOUR': data.duration * manHour,
        'PR ID': data?.prId ? data?.prId : ''
      }; // deptRelation
      let appendAttendee = [NewObj];
      let duration = data.duration;
      if (hrPending && getHRVPList.length > data.attendeeList) {
        getHRVPList.forEach(function (HRVPObj, i) {
          appendAttendee.push({
            'REQUEST DATE': '',
            COURSE: '',
            'START DATE': '',
            'END DATE': '',
            AMOUNT: '',
            STATUS: '',
            'PENDING AT': data.pendingName,
            REQUESTER: '',
            'ATTENDEE NAME': data.attendeeList[i + 1]
              ? data.attendeeList[i + 1]?.employee?.nameTh
              : '',
            DEPARTMENT: data.attendeeList[i + 1]
              ? data.attendeeList[i + 1]?.employee?.deptRelation.deptName
              : '',
            'ATTENDEE LEVEL': data.attendeeList[i + 1]
              ? data.attendeeList[i + 1]?.employee?.grade
              : '',
            'MAN-HOUR': duration * manHour,
            'PR ID': ''
          });
        });
      } else if (data.attendeeList.length > 1) {
        // attendee greater than 1 row
        data.attendeeList.forEach(function (attObj, i) {
          if (i > 0) {
            appendAttendee.push({
              'REQUEST DATE': '',
              COURSE: '',
              'START DATE': '',
              'END DATE': '',
              AMOUNT: '',
              STATUS: '',
              'PENDING AT': hrPending
                ? getHRVPList[i]
                  ? getHRVPList[i]
                  : ''
                : '',
              REQUESTER: '',
              'ATTENDEE NAME': attObj.employee?.nameTh,
              DEPARTMENT: attObj.employee?.deptRelation.deptName,
              'ATTENDEE LEVEL': attObj.employee?.grade,
              'MAN-HOUR': duration * manHour,
              'PR ID': ''
            });
          }
        });
      }
      console.log('in loop', appendAttendee);
      return appendAttendee;
    });

    const optionsJsonToEx = {
      filename: this.generateFileName,
      fieldSeparator: ',',
      quoteStrings: '"',
      decimalSeparator: '.',
      showLabels: true,
      useTextFile: false,
      useBom: true,
      useKeysAsHeaders: true,
    };

    const csvExporter = new ExportToCsv(optionsJsonToEx);
    console.log('result', data);
    csvExporter.generateCsv(data);
  }
  get generateFileName() {
    const cDate = new Date();
    const name = `Request_training_${this.datepipe.transform(
      this.today,
      'dd MMM yyyy'
    )}`;
    return name;
  }
  loadDepartmentList() {
    this.departmentService.getAllDepartment().subscribe(
      (resp) => {
        this.departmentList = resp;
      },
      (err) => console.error
    );
  }
  alert(isSuccess, msg) {
    Swal.fire({
      scrollbarPadding: false,
      title: isSuccess ? 'Successfully' : 'Error',
      icon: isSuccess ? 'success' : 'error',
      text: msg,
      buttonsStyling: false,
      confirmButtonText: 'OK',
      customClass: {
        confirmButton: 'btn btn-primary',
      },
    });
  }
  getHR() {
    this.requestTrainingServices.findHrApprover().subscribe(
      (s) => {
        this.HRVPList = s;
      },
      (e) => console.error(e)
    );
  }
}

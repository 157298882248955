import {Component, OnDestroy, OnInit, TemplateRef} from '@angular/core';
import {faIcon} from 'src/app/core/icon/fa.icon';
import {ActivatedRoute} from '@angular/router';
import {ManPowerState} from 'src/app/core/enums/man-power.enum';
import {ManpowerService, MT} from '../../core/services/manpower.service';
import {EmployeeService} from '../../core/services/employee.service';
import {RequestTrainingService} from '../../core/services/request-training.service';
import {ManpowerSelectedService} from './services/manpower-selected.service';
import {BsModalRef, BsModalService} from 'ngx-bootstrap';
import {HRLevelInfo, HRLevelRes, RequestManpower} from '../../core/models/requestManpower';
import Swal from 'sweetalert2';
import {Subscription} from 'rxjs';

export type HRLevel = 'group' | 'division' | 'president';

@Component({
  selector: 'app-man-power',
  templateUrl: './man-power.component.html',
  styleUrls: ['./man-power.component.scss'],
})
export class ManPowerComponent implements OnInit, OnDestroy {

  displayItem = ManPowerState.LIST;
  id: string;
  forAction: string;
  isAdmin = false;
  isHrAdmin = false;
  readonly allowList = ['00155', '00511', '00202', '00370', '00409'];

  confirmationWording: string;
  modalRef: BsModalRef;
  hrLevel: HRLevelRes;
  selectedHRLevel: HRLevelInfo;
  selectedItemList: RequestManpower[] = [];
  selectedFilterItemList: RequestManpower[] = [];
  sentMailType: MT;
  subscription: Subscription
  constructor(
    private manpowerService: ManpowerService,
    private activatedRoute: ActivatedRoute,
    private employeeService: EmployeeService,
    private requestTrainingService: RequestTrainingService,
    private manpowerSelectedService: ManpowerSelectedService,
    private modalService: BsModalService
  ) {
  }

  async ngOnInit() {
    this.getHRLevel();
    this.subscribeToSelectedItems();
    await this.setInitialState();
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  private subscribeToSelectedItems() {
    this.subscription = this.manpowerSelectedService.currentItems.subscribe((data) => {
      this.selectedItemList = data;
    });
  }

  private async setInitialState() {
    const {id} = this.activatedRoute.snapshot.params;
    const {action} = this.activatedRoute.snapshot.data

    if (id) {
      this.id = id;
      this.forAction = action;
      this.displayItem = ManPowerState.EDIT;
    } else {
      await this.loadProfileData();
    }
  }

  private async loadProfileData() {
    const currentProfile = await this.employeeService.me().toPromise();
    this.isAdmin = this.allowList.includes(currentProfile.empID);
    const roles = await this.requestTrainingService.getUserRole().toPromise();
    this.isHrAdmin = roles.some((role) => role.role === 'HRAdmin');
  }

  private getHRLevel() {
    this.manpowerService.getHrLevel().toPromise().then(
      (hrLevel) => (this.hrLevel = hrLevel),
      () => console.error('Failed to get HR Level')
    );
  }

  get hrLevelIsEmpty() {
    return !this.hrLevel || !Object.keys(this.hrLevel).length;
  }

  routeToManPowerView(data: any) {
    if (!data) {
      this.displayItem = ManPowerState.NEW;
    }
  }

  onBack() {
    this.displayItem = ManPowerState.LIST;
  }

  sendMailConfirmationModal(template: TemplateRef<any>, level: HRLevel) {
    this.selectedHRLevel = this.hrLevel[level];
    this.confirmationWording = `Do you want to send mail to ${this.selectedHRLevel.nameTh}?`;
    this.filterSelectedItems(level);

    if (!this.selectedFilterItemList.length) {
      return this.alert(false, 'No data to send mail');
    }

    this.sentMailType = this.getSentMailType(level);
    this.modalRef = this.modalService.show(template, {class: 'modal-lg1'});
  }

  private filterSelectedItems(level: HRLevel) {
    this.selectedFilterItemList = this.selectedItemList.filter(
      (r) => r.pendingAt.empID === this.selectedHRLevel.empId
    );
  }

  private getSentMailType(level: HRLevel): MT {
    switch (level) {
      case 'group':
        return 'HR_GROUP';
      case 'division':
        return 'HR_DIVISION';
      case 'president':
        return 'PRESIDENT';
      default:
        throw new Error('Invalid HR Level');
    }
  }

  alert(isSuccess: boolean, msg: string) {
    return Swal.fire({
      scrollbarPadding: false,
      title: isSuccess ? 'Successfully' : 'Error',
      icon: isSuccess ? 'success' : 'error',
      text: msg,
      buttonsStyling: false,
      confirmButtonText: 'OK',
      customClass: {
        confirmButton: 'btn btn-warning text-light',
      },
    });
  }

  async doSendMail() {
    this.modalRef.hide();
    const ids = this.selectedFilterItemList.map((a) => a.id);
    console.log('Call send mail', ids);
    try {
      await this.manpowerService.sendMail(this.sentMailType, ids).toPromise();
      this.alert(true, 'Send mail success');
    } catch (error) {
      this.alert(false, 'Send mail fail');
    } finally {
      this.manpowerSelectedService.changeItems([]);
    }
  }
}

export const environment = {
  production: false,
  api_endpoint: 'https://test-hr.setstage.or.th/api',
  pdpa: {
    api: 'https://test-hr.setstage.or.th/api/pdpa',
    documentTypeId: 750,
  },
  local: false,
  bcpWeb: 'https://test-bcp.set.or.th',
  hrtimesheet: 'https://test.portal.setstage.or.th/hr-portal/outsource-timesheet',
};

import { Component, OnInit, TemplateRef, Output, EventEmitter, Input, OnChanges } from '@angular/core';

import { faIcon } from 'src/app/core/icon/fa.icon';
import Swal from 'sweetalert2';
import { EmployeeService } from 'src/app/core/services/employee.service';
import { Employee } from 'src/app/core/models/employee';
import { TypeaheadMatch, BsModalRef, BsModalService } from 'ngx-bootstrap';
import { FastFeedbackService } from 'src/app/core/services/fast-feedback.service';
import { FastFeedback } from 'src/app/core/models/fast-feedback';
import { FormBuilder, Validators, FormArray, FormControl } from '@angular/forms';
import { TargetGroup } from 'src/app/core/models/target-group';
import { FastFeedbackResult } from 'src/app/core/models/fast-feedback-result';
import { ExportToCsv } from 'export-to-csv';
import { DatePipe } from '@angular/common';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-new-fast-feedback',
  templateUrl: './new-fast-feedback.component.html',
  styleUrls: ['./new-fast-feedback.component.scss'],
})
export class NewFastFeedbackComponent implements OnInit, OnChanges {
  modalRef: BsModalRef;
  faIcon = faIcon;
  @Output() onEmitData = new EventEmitter();
  @Output() onEmitFastFeedback = new EventEmitter();
  @Input() fastFeedbackData: FastFeedback;
  @Input() isOwner: boolean;
  @Input() displayStatus: string;
  @Input() pageData: String;
  fastFeeadbackForm = this.fb.group({
    title: [{ value: '', disabled: false }, [Validators.required]],
    active: [{ value: false, disabled: false }, [Validators.required]],
    targetGroup: [{ value: '', disabled: false }],
    whoCanSee: [{ value: '', disabled: false }],
    targetGroupList: this.fb.array([]),
    whoCanSeeList: this.fb.array([]),
    description: [{ value: '', disabled: false }],
    expiryDate: [{ value: '', disabled: false }],
    effectiveDate: [{ value: '', disabled: false }],
    requiredComment: [{ value: false, disabled: false }],
    feedbackType: [{ value: 'vendor evaluation', disabled: false }],
    referenceId: [{ value: '', disabled: false }],
    referenceName: [{ value: '', disabled: false }],
    isAllowAnyone: [{ value: false, disabled: false }],
    questionList: this.fb.array([]),
    itemList: this.fb.array([]),
    recordDepartment: [{ value: false, disabled: false }],
    allowOneResponse: [{ value: false, disabled: false }],
  });
  selectedTargetGroup: TargetGroup[] = [];
  allTargetGroups: TargetGroup[] = [];
  allWhoCanSee: TargetGroup[] = [];
  selectedWhoCanSee: TargetGroup[] = [];
  confirmationWording: string = '';
  confirmationStatus: string = '';
  imageSrc: string = '';
  targetGroupList: FormArray;
  whoCanSeeList: FormArray;
  questionList: FormArray;
  itemList: FormArray;
  selectedFile;
  status: string = 'create';
  allPeriod = [
    {
      id: 1,
      period: 'Today',
      periodType: 'TODAY',
    },
    {
      id: 2,
      period: 'This week',
      periodType: 'THIS_WEEK',
    },
    {
      id: 3,
      period: 'This month',
      periodType: 'THIS_MONTH',
    },
    {
      id: 4,
      period: 'All time',
      periodType: 'GET_ALL',
    },
  ];
  allFeedbackType = [
    {
      id: 1,
      feedbackTypeName: '',
      feedbackType: '',
    },
    {
      id: 2,
      feedbackTypeName: 'Vendor Evaluation',
      feedbackType: 'vendor evaluation',
    },
    {
      id: 3,
      feedbackTypeName: 'CSI',
      feedbackType: 'csi',
    },
    {
      id: 4,
      feedbackTypeName: 'Fast Feedback',
      feedbackType: 'fast feedback',
    },
    {
      id: 5,
      feedbackTypeName: 'Booking',
      feedbackType: 'booking',
    },
    {
      id: 6,
      feedbackTypeName: 'Survey',
      feedbackType: 'survey',
    },
  ];
  selectedPeriod = 'GET_ALL';
  fastFeedbackResult: FastFeedbackResult;
  barChart: string;
  dataOfBarChart: any;
  optionsBarChart: any;
  lineChart: string;
  dataOfLineChart: any;
  optionsLineChart: any;
  horizontalBarChart: string;
  dataOfHorizontalBarChart: any;
  optionsHorizontalBarChart: any;
  selectedFastFeedbackFile;
  optionsJsonToEx = {
    filename: 'Response-fast-feedback',
    fieldSeparator: ',',
    quoteStrings: '"',
    decimalSeparator: '.',
    showLabels: true,
    useTextFile: false,
    useBom: true,
    useKeysAsHeaders: true,
  };
  isLoading: boolean = false;
  possibleList = [];
  deleteQuestionId = 0;
  deleteQuestionIndex = 0;
  deleteItemId = 0;
  deleteItemIndex = 0;
  employees: Employee[] = [];
  fastFeeadbackId = 0;

  constructor(
    private fb: FormBuilder,
    private fastFeedbackService: FastFeedbackService,
    private modalService: BsModalService,
    private datepipe: DatePipe,
    private employeeService: EmployeeService,
    private activateRoute: ActivatedRoute
  ) {
    const id = this.activateRoute.snapshot.params.id;
    if (id) {
      this.fastFeeadbackId = id;
      this.getFastFeedback();
    }
  }

  ngOnChanges() {
    if (this.fastFeedbackData) {
      this.status = 'edit';
      this.initFastFeedback();
      if (this.fastFeedbackData.image) {
        this.getFastFeedbackImage();
      }
      if (this.fastFeedbackData.numberOfResponses > 0) {
        this.getFastFeedbackResult();
      }
    }
  }

  ngOnInit(): void {
    this.getAllTargetGroups();
    this.getAllWhoCanSee();
    this.getAllEmployee();

    this.targetGroupList = this.fastFeeadbackForm.get(
      'targetGroupList'
    ) as FormArray;
    this.whoCanSeeList = this.fastFeeadbackForm.get(
      'whoCanSeeList'
    ) as FormArray;
    this.questionList = this.fastFeeadbackForm.get('questionList') as FormArray;
    this.itemList = this.fastFeeadbackForm.get('itemList') as FormArray;

    // console.log('this.displayStatus', this.displayStatus);

    if (this.displayStatus === 'new') {
      this.onAddQuestion();
      this.onAddItem();
      this.fastFeeadbackForm.patchValue({
        feedbackType: 'fast feedback',
      });
    }
  }

  async getFastFeedback() {
    let session = await this.employeeService.me().toPromise();
      this.fastFeedbackService.fastFeedBackListAdmin(this.fastFeeadbackId).toPromise()
      .then((res : FastFeedback) => {
        this.onEmitFastFeedback.emit(res);
        this.fastFeedbackData = res;
        this.isOwner  = res.createBy === session.empID;
        this.status = 'edit';
        this.initFastFeedback();
        if (this.fastFeedbackData.image) {
          this.getFastFeedbackImage();
        }
        if (this.fastFeedbackData.numberOfResponses > 0) {
          this.getFastFeedbackResult();
        }
      })
      .catch(err => {
          console.log('err', err);
      })
  }

  get targetGroupListData() {
    return <FormArray>this.fastFeeadbackForm.get('targetGroupList');
  }

  get whoCanSeeListData() {
    return <FormArray>this.fastFeeadbackForm.get('whoCanSeeList');
  }

  get questionListData() {
    return <FormArray>this.fastFeeadbackForm.get('questionList');
  }

  get itemListData() {
    return <FormArray>this.fastFeeadbackForm.get('itemList');
  }

  get fastFeeadbackFormGroup() {
    return this.fastFeeadbackForm.controls;
  }

  initFastFeedback() {
    this.fastFeeadbackForm.patchValue({
      title: this.fastFeedbackData.title,
      active: this.fastFeedbackData.active,
      targetGroup: '',
      whoCanSee: '',
      description: this.fastFeedbackData.description,
      expiryDate: this.fastFeedbackData.expiryDate
        ? this.assignDateToString(this.fastFeedbackData.expiryDate)
        : '',
      effectiveDate: this.fastFeedbackData.effectiveDate
        ? this.assignDateToString(this.fastFeedbackData.effectiveDate)
        : '',
      requiredComment: this.fastFeedbackData.requiredComment,
      feedbackType: this.fastFeedbackData.feedbackType,
      referenceId: this.fastFeedbackData.referenceId,
      referenceName: this.fastFeedbackData.referenceName,
      isAllowAnyone: this.fastFeedbackData.isAllowAnyone,
      recordDepartment: this.fastFeedbackData.recordDepartment,
      allowOneResponse: this.fastFeedbackData.allowOneResponse,
    });

    this.onChangeFeedbackType();
    // if(this.displayStatus !== 'import') {
    //     this.fastFeeadbackForm.patchValue({
    //         feedbackType: ''
    //     })
    // }

    this.targetGroupList = this.fastFeeadbackForm.get(
      'targetGroupList'
    ) as FormArray;
    this.whoCanSeeList = this.fastFeeadbackForm.get(
      'whoCanSeeList'
    ) as FormArray;
    this.questionList = this.fastFeeadbackForm.get('questionList') as FormArray;
    this.itemList = this.fastFeeadbackForm.get('itemList') as FormArray;

    if (Array.isArray(this.fastFeedbackData.tags)) {
      this.fastFeedbackData.tags.forEach((item) => {
        this.targetGroupList.push(
          this.fb.group({
            targetType: [{ value: item.targetType, disabled: false }],
            name: [{ value: item.name, disabled: false }],
            targetId: [{ value: item.targetId, disabled: false }],
            tagType: [{ value: item.tagType, disabled: false }],
          })
        );
      });
    }

    if (Array.isArray(this.fastFeedbackData.whoCanSeeResult)) {
      this.fastFeedbackData.whoCanSeeResult.forEach((item) => {
        this.whoCanSeeList.push(
          this.fb.group({
            targetType: [{ value: item.targetType, disabled: false }],
            name: [{ value: item.name, disabled: false }],
            targetId: [{ value: item.targetId, disabled: false }],
            tagType: [{ value: item.tagType, disabled: false }],
          })
        );
      });
    }

    // console.log('this.fastFeedbackData.questions', this.fastFeedbackData.questions);

    if (Array.isArray(this.fastFeedbackData.questions)) {
      this.fastFeedbackData.questions.forEach((item) => {
        let isOther = false;
        let possibleValue = this.fb.array([]);

        if (
          (item.type === 'CHOICE' || item.type === 'DROPDOWN') &&
          Array.isArray(item.possibleValue)
        ) {
          item.possibleValue.forEach((data) => {
            if (data !== 'Other') {
              possibleValue.push(this.fb.group({ data }));
            } else {
              isOther = true;
            }
          });
        }

        this.questionList.push(
          this.fb.group({
            id: [{ value: item.id, disabled: false }],
            question: [{ value: item.question, disabled: false }],
            isRequire: [{ value: item.isRequire, disabled: false }],
            isActive: [{ value: item.isActive, disabled: false }],
            isOther: [
              {
                value: isOther,
                disabled:
                  item.type &&
                  (item.type === 'CHOICE' || item.type === 'DROPDOWN')
                    ? false
                    : true,
              },
            ],
            type: [{ value: item.type, disabled: false }],
            possibleValue: possibleValue,
            possibleText: [
              {
                value: '',
                disabled:
                  item.type &&
                  (item.type === 'CHOICE' || item.type === 'DROPDOWN')
                    ? false
                    : true,
              },
            ],
          })
        );
      });
    } else {
      this.onAddQuestion();
      this.onAddItem();
    }

    if (
      Array.isArray(this.fastFeedbackData.bookingItems) &&
      this.fastFeedbackData.feedbackType === 'booking'
    ) {
      this.fastFeedbackData.bookingItems.forEach((item) => {
        this.itemList.push(
          this.fb.group({
            id: [{ value: item.id, disabled: false }],
            itemName: [{ value: item.itemName, disabled: false }],
            volume: [{ value: item.volume, disabled: false }],
          })
        );
      });
    }

    if (!this.isOwner) {
      this.fastFeeadbackForm.disable();
    }
  }

  onSelectTargetGroupTag(event: TypeaheadMatch) {
    let data = this.fb.group({
      targetType: [{ value: event.item.targetType, disabled: false }],
      name: [{ value: event.item.name, disabled: false }],
      targetId: [{ value: event.item.targetId, disabled: false }],
      tagType: [{ value: 'TG', disabled: false }],
    });
    this.targetGroupList.push(data);
    this.fastFeeadbackForm.patchValue({
      targetGroup: '',
    });
  }

  onRemoveTargetGroup(index) {
    this.targetGroupList.removeAt(index);
  }

  getAllTargetGroups() {
    this.fastFeedbackService
      .getAllTargetGroups()
      .toPromise()
      .then((res) => {
        this.allTargetGroups = res;
      })
      .catch((err) => {
        console.log('err', err);
      });
  }

  getAllWhoCanSee() {
    this.fastFeedbackService
      .getAllTagByTargetType('EMP')
      .toPromise()
      .then((res) => {
        this.allWhoCanSee = res;
      })
      .catch((err) => {
        console.log('err', err);
      });
  }

  getAllEmployee() {
    this.isLoading = true;
    this.employeeService
      .employeeList()
      .toPromise()
      .then((res) => {
        this.employees = res;
        this.isLoading = false;
      })
      .catch((err) => {
        console.log('err', err);
        this.isLoading = false;
      });
  }

  onRemoveWhoCanSee(index) {
    this.whoCanSeeList.removeAt(index);
  }

  onSelectWhoCanSeeTag(event: TypeaheadMatch) {
    let data = this.fb.group({
      targetType: [{ value: event.item.targetType, disabled: false }],
      name: [{ value: event.item.name, disabled: false }],
      targetId: [{ value: event.item.targetId, disabled: false }],
      tagType: [{ value: 'WC', disabled: false }],
    });
    this.whoCanSeeList.push(data);
    this.fastFeeadbackForm.patchValue({
      whoCanSee: '',
    });
  }

  onShowConfirmationModal(template: TemplateRef<any>, status) {
    this.confirmationStatus = status;
    if (
      (status.toUpperCase() === 'SAVE' && this.status === 'create') ||
      status.toUpperCase() === 'IMPORT'
    ) {
      if (this.validateFastFeedbackFrom()) {
        this.confirmationWording =
          status.toUpperCase() !== 'IMPORT'
            ? 'Do you want to save this fast-feedback ?'
            : 'Do you want to import all fast-feedback ?';
        this.modalRef = this.modalService.show(template, {
          class: 'modal-lg1',
        });
      }
    } else if (status.toUpperCase() === 'BACK') {
      this.confirmationWording = 'Do you want to go back ?';
      this.modalRef = this.modalService.show(template, { class: 'modal-lg1' });
    } else if (status.toUpperCase() === 'SAVE' && this.status === 'edit') {
      if (this.validateFastFeedbackFrom()) {
        this.confirmationWording = 'Do you want to update this fast-feedback ?';
        this.modalRef = this.modalService.show(template, {
          class: 'modal-lg1',
        });
      }
    } else if (status === 'delete') {
      this.confirmationWording = 'Do you want to delete this fast-feedback ?';
      this.modalRef = this.modalService.show(template, { class: 'modal-lg1' });
    } else if (status === 'send mail') {
      this.confirmationWording = 'Do you want to send mail notification ?';
      this.modalRef = this.modalService.show(template, { class: 'modal-lg1' });
    } else if (status === 'send noti') {
      this.confirmationWording = 'Do you want to send SETDNA notification ?';
      this.modalRef = this.modalService.show(template, { class: 'modal-lg1' });
    } else if (status === 'delete question') {
      this.confirmationWording = 'Do you want to delete this question ?';
      this.modalRef = this.modalService.show(template, { class: 'modal-lg1' });
    } else if (status === 'delete item') {
      this.confirmationWording = 'Do you want to delete this item ?';
      this.modalRef = this.modalService.show(template, { class: 'modal-lg1' });
    }
  }

  confirm() {
    this.modalRef.hide();
    this.isLoading = true;
    if (
      this.confirmationStatus.toLocaleUpperCase() === 'SAVE' ||
      this.confirmationStatus.toLocaleUpperCase() === 'IMPORT' ||
      this.confirmationStatus.toLocaleUpperCase() === 'SEND MAIL' ||
      this.confirmationStatus.toLocaleUpperCase() === 'SEND NOTI'
    ) {
      this.onSaveFeedback();
    } else if (this.confirmationStatus.toLocaleUpperCase() === 'BACK') {
      this.isLoading = false;
      this.onEmitData.emit({ state: 'list', page: this.pageData });
    } else if (this.confirmationStatus.toLocaleUpperCase() === 'DELETE') {
      this.onDeleteFeedback();
    } else if (
      this.confirmationStatus.toLocaleUpperCase() === 'DELETE QUESTION'
    ) {
      this.onDeleteQuestionData();
    } else if (this.confirmationStatus.toLocaleUpperCase() === 'DELETE ITEM') {
      this.onDeleteItemData();
    } else {
      this.isLoading = false;
    }
    // else if (this.confirmationStatus.toLocaleUpperCase() === 'SEND MAIL') {
    //     this.onSendMail();
    // } else if (this.confirmationStatus.toLocaleUpperCase() === 'SEND NOTI') {
    //     this.onSendNoti();
    // }
  }

  onSaveFeedback() {
    let questionListDetail = [];
    let itemListDetail = [];

    if (Array.isArray(this.fastFeeadbackFormGroup.questionList.value)) {
      this.fastFeeadbackFormGroup.questionList.value.forEach((item) => {
        if (item.question && item.type) {
          let allPossibleValue = [];
          if (Array.isArray(item.possibleValue)) {
            if (item.possibleValue.length > 0) {
              item.possibleValue.forEach((data) => {
                // console.log('data ===> ', data);
                allPossibleValue.push(data.data);
              });
            }
          }

          let questionListData = {
            id: item.id,
            question: item.question,
            isRequire: item.isRequire,
            isActive: item.isActive,
            type: item.type,
            possibleValue: allPossibleValue,
          };

          // console.log('item.isOther', item.isOther);

          if (item.isOther && Array.isArray(item.possibleValue)) {
            questionListData.possibleValue.push('Other');
          }

          questionListDetail.push(questionListData);
        }
      });
    }

    if (Array.isArray(this.fastFeeadbackFormGroup.itemList.value)) {
      this.fastFeeadbackFormGroup.itemList.value.forEach((item) => {
        if (item.itemName && item.volume) {
          let itemListData = {
            id: item.id,
            itemName: item.itemName,
            volume: item.volume,
          };

          itemListDetail.push(itemListData);
        }
      });
    }

    let body = {
      title: this.fastFeeadbackFormGroup.title.value,
      active:
        this.displayStatus === 'import'
          ? true
          : this.fastFeeadbackFormGroup.active.value,
      tags: this.fastFeeadbackFormGroup.targetGroupList.value,
      whoCanSeeResult: this.fastFeeadbackFormGroup.whoCanSeeList.value,
      description: this.fastFeeadbackFormGroup.description.value,
      effectiveDate: this.fastFeeadbackFormGroup.effectiveDate.value,
      expiryDate: this.fastFeeadbackFormGroup.expiryDate.value,
      requiredComment:
        this.fastFeeadbackFormGroup.feedbackType.value.toLocaleLowerCase() !==
          'survey' &&
        this.fastFeeadbackFormGroup.feedbackType.value.toLocaleLowerCase() !==
          'booking'
          ? this.fastFeeadbackFormGroup.requiredComment.value
          : false,
      questions: questionListDetail,
      feedbackType: this.fastFeeadbackFormGroup.feedbackType.value,
      referenceId: this.fastFeeadbackFormGroup.referenceId.value,
      referenceName: this.fastFeeadbackFormGroup.referenceName.value,
      isAllowAnyone: this.fastFeeadbackFormGroup.isAllowAnyone.value,
      bookingItems: itemListDetail,
      recordDepartment: this.fastFeeadbackFormGroup.recordDepartment.value,
      allowOneResponse: this.fastFeeadbackFormGroup.allowOneResponse.value,
    };

    if (this.status === 'edit' && this.fastFeedbackData) {
      let editData = {
        id: this.fastFeedbackData.id,
      };
      Object.assign(body, editData);
    }

    // let fastFeedbackType = {
    //     feedbackType: this.fastFeeadbackFormGroup.feedbackType.value,
    // }
    // Object.assign(body, fastFeedbackType);

    // console.log('body', body);
    // console.log('body JSON 🎩',JSON.stringify(body));
    // this.isLoading = false;

    if (this.status === 'create' && this.displayStatus !== 'import') {
      this.fastFeedbackService
        .createFastFeedback(body, this.selectedFile)
        .toPromise()
        .then((res) => {
          this.isLoading = false;
          this.alert(true, 'This feedback has been created.');
          this.onEmitData.emit({ state: 'list', page: this.pageData });
        })
        .catch((err) => {
          this.isLoading = false;
          console.log('err', err);
          this.alert(false, 'Can not create this feedback, Please try again.');
        });
    } else if (this.status === 'edit') {
      this.fastFeedbackService
        .editFastFeedback(body, this.selectedFile)
        .toPromise()
        .then((res) => {
          if (this.confirmationStatus.toLocaleUpperCase() === 'SEND MAIL') {
            this.onSendMail();
          } else if (
            this.confirmationStatus.toLocaleUpperCase() === 'SEND NOTI'
          ) {
            this.onSendNoti();
          } else {
            this.isLoading = false;
            this.alert(true, 'This feedback has been updated.');
            this.onEmitData.emit({ state: 'list', page: this.pageData });
          }
        })
        .catch((err) => {
          this.isLoading = false;
          console.log('err', err);
          this.alert(false, 'Can not update this feedback, Please try again.');
        });
    } else if (this.displayStatus === 'import') {
      this.fastFeedbackService
        .importFastFeedback(
          body,
          this.selectedFile,
          this.selectedFastFeedbackFile
        )
        .toPromise()
        .then((res) => {
          this.isLoading = false;
          this.alert(true, 'All feedback has been created.');
          this.onEmitData.emit({ state: 'list', page: this.pageData });
        })
        .catch((err) => {
          this.isLoading = false;
          console.log('err', err);
          this.alert(false, 'Can not create all feedback, Please try again.');
        });
    } else {
      this.isLoading = false;
    }
  }

  onDeleteFeedback() {
    if (this.fastFeedbackData.id) {
      this.fastFeedbackService
        .deleteFastFeedbackById(this.fastFeedbackData.id)
        .toPromise()
        .then((res) => {
          this.isLoading = false;
          this.alert(true, 'This feedback has been deleted.');
          this.onEmitData.emit({ state: 'list', page: this.pageData });
        })
        .catch((err) => {
          this.isLoading = false;
          console.log('err', err);
          this.alert(false, 'Can not delete this feedback, Please try again.');
        });
    } else {
      this.isLoading = false;
      this.alert(false, 'Can not delete this feedback, Please try again.');
    }
  }

  onSendMail() {
    if (this.fastFeedbackData.id) {
      this.fastFeedbackService
        .sendMailById(this.fastFeedbackData.id)
        .toPromise()
        .then((res) => {
          this.isLoading = false;
          this.alert(true, 'Mail has been send successfully.');
          // this.onEmitData.emit('list');
        })
        .catch((err) => {
          this.isLoading = false;
          console.log('err', err);
          this.alert(false, 'Can not send mail, Please try again.');
        });
    } else {
      this.isLoading = false;
      this.alert(false, 'Can not send mail, Please try again.');
    }
  }

  onSendNoti() {
    if (this.fastFeedbackData.id) {
      this.fastFeedbackService
        .sendNotiById(this.fastFeedbackData.id)
        .toPromise()
        .then((res) => {
          this.isLoading = false;
          this.alert(true, 'Notification has been send successfully.');
          // this.onEmitData.emit('list');
        })
        .catch((err) => {
          this.isLoading = false;
          console.log('err', err);
          this.alert(false, 'Can not send notification, Please try again.');
        });
    } else {
      this.isLoading = false;
      this.alert(false, 'Can not send notification, Please try again.');
    }
  }

  onSelectFile(event) {
    if (event.target.files && event.target.files.length) {
      if (event.target.files.item(0).size / 1024 / 1024 <= 1) {
        const reader = new FileReader();
        const [file] = event.target.files;
        reader.readAsDataURL(file);
        reader.onload = () => {
          this.imageSrc = reader.result as string;
        };
        this.selectedFile = event.target.files.item(0);
      } else {
        this.alert(false, 'Image size exceeds the allowable limit');
      }
    }
  }

  onSelectFastFeedbackFile(event) {
    if (event.target.files && event.target.files.length) {
      this.selectedFastFeedbackFile = event.target.files.item(0);
    }
    event.target.value = '';
  }

  onRemoveFile() {
    this.selectedFastFeedbackFile = '';
  }

  validateFastFeedbackFrom() {
    let valid: boolean = true;

    let fastFeedbackDetailForm = this.fastFeeadbackForm.getRawValue();

    if (this.fastFeeadbackForm.controls.title.invalid)
      this.fastFeeadbackForm.controls.title.setErrors({ null: true });
    if (
      this.fastFeeadbackForm.value.targetGroupList.length < 1 &&
      this.displayStatus !== 'import'
    )
      this.fastFeeadbackForm.controls.targetGroupList.setErrors({ null: true });
    // if (this.fastFeeadbackForm.value.whoCanSeeList.length < 1 && this.displayStatus === 'import') this.fastFeeadbackForm.controls.whoCanSeeList.setErrors({ null: true });
    if (
      this.fastFeeadbackForm.get('expiryDate').value ||
      this.fastFeeadbackForm.get('effectiveDate').value
    ) {
      if (
        this.fastFeeadbackForm.get('expiryDate').value &&
        !this.fastFeeadbackForm.get('effectiveDate').value
      ) {
        this.fastFeeadbackForm.controls.effectiveDate.setErrors({ null: true });
      } else {
        this.fastFeeadbackForm.controls.effectiveDate.setErrors(null);
      }
      if (
        !this.fastFeeadbackForm.get('expiryDate').value &&
        this.fastFeeadbackForm.get('effectiveDate').value
      ) {
        this.fastFeeadbackForm.controls.expiryDate.setErrors({ null: true });
      } else {
        this.fastFeeadbackForm.controls.expiryDate.setErrors(null);
      }
    }

    if (fastFeedbackDetailForm.questionList.length > 0) {
      // console.log('Here', fastFeedbackDetailForm.questionList);
      this.questionList.controls.forEach((item, index) => {
        if (item.get('question').value || item.get('type').value) {
          item.get('question').setValidators(Validators.required);
          item.get('type').setValidators(Validators.required);

          item.get('question').updateValueAndValidity();
          item.get('type').updateValueAndValidity();

          if (
            item.get('type').value &&
            (item.get('type').value === 'CHOICE' ||
              item.get('type').value === 'DROPDOWN')
          ) {
            if (item.get('possibleValue').value.length > 0) {
              item.get('possibleText').setValidators(null);
              item.get('possibleText').updateValueAndValidity();
            } else {
              item.get('possibleText').setValidators(Validators.required);
              item.get('possibleText').updateValueAndValidity();
            }
          } else {
            item.get('possibleText').setValidators(Validators.required);
            item.get('possibleText').updateValueAndValidity();
          }
        } else {
          item.get('question').setValidators(null);
          item.get('type').setValidators(null);

          item.get('question').updateValueAndValidity();
          item.get('type').updateValueAndValidity();
        }
      });
    }

    if (fastFeedbackDetailForm.itemList.length > 0) {
      this.itemList.controls.forEach((item, index) => {
        item.get('itemName').setValidators(Validators.required);
        item.get('volume').setValidators(Validators.required);

        item.get('itemName').updateValueAndValidity();
        item.get('volume').updateValueAndValidity();
      });
    }

    if (
      this.fastFeeadbackForm.controls.targetGroupList.status.toUpperCase() !==
        'VALID' ||
      this.fastFeeadbackForm.controls.title.status.toUpperCase() !== 'VALID' ||
      this.fastFeeadbackForm.controls.expiryDate.status.toUpperCase() !==
        'VALID' ||
      this.fastFeeadbackForm.controls.effectiveDate.status.toUpperCase() !==
        'VALID' ||
      this.fastFeeadbackForm.controls.questionList.status.toUpperCase() !==
        'VALID'
    ) {
      valid = false;
      this.alert(false, 'Please fill out the form completely.');
    }

    if (
      !this.selectedFastFeedbackFile &&
      this.displayStatus === 'import' &&
      this.fastFeeadbackForm.controls.targetGroupList.status.toUpperCase() ===
        'VALID' &&
      this.fastFeeadbackForm.controls.title.status.toUpperCase() === 'VALID'
    ) {
      valid = false;
      this.alert(false, 'Please select CSV File before import.');
    }

    return valid;
  }

  getFastFeedbackImage() {
    this.fastFeedbackService
      .getFastFeedbackImage(this.fastFeedbackData.image)
      .toPromise()
      .then((res) => {
        let blob = new Blob([res.body], { type: 'image/jpeg' });
        const reader = new FileReader();
        reader.readAsDataURL(blob);
        reader.onload = () => {
          this.imageSrc = reader.result as string;
        };
      })
      .catch((err) => {
        console.error(err);
      });
  }

  getFastFeedbackResult() {
    this.fastFeedbackService
      .getFastFeedbackResult(this.fastFeedbackData.id, this.selectedPeriod)
      .toPromise()
      .then((res) => {
        this.fastFeedbackResult = res;
        // console.log(" this.fastFeedbackResult",  this.fastFeedbackResult)
        this.assignDataToBarChart();
        this.assignDataToLineChart();
        this.assignDataToHorizontalBarChart();
      })
      .catch((err) => {
        console.error('err', err);
      });
  }

  onChangePeriod(data) {
    this.getFastFeedbackResult();
  }

  exportExcel() {
    if (
      this.fastFeedbackResult &&
      Array.isArray(this.fastFeedbackResult.comments)
    ) {
      if (this.fastFeedbackResult.comments.length > 0) {
        const json = this.fastFeedbackResult.comments.map((v) => {
          let dateTime = this.datepipe.transform(v.date, 'dd-MM-yyyy HH:mm');

          let data = {
            ['DATE-TIME']: dateTime ? dateTime : '-',
            ['DEPARTMENT']:
              this.fastFeedbackData.recordDepartment && v.department
                ? v.department
                : '-',
            ['SCORE']: v.rate ? v.rate : 0,
            ['COMMENT']: v.message ? v.message : '-',
          };

          if (Array.isArray(v.fastFeedbackResponseAnswers)) {
            v.fastFeedbackResponseAnswers.forEach((answer, index) => {
              let answerData = {
                [`ANSWER${index + 1}`]: answer.answer ? answer.answer : '-',
              };

              Object.assign(data, answerData);
            });
          }

          return data;
        });
        const csvExporter = new ExportToCsv(this.optionsJsonToEx);
        csvExporter.generateCsv(json);
      }
    }
  }

  exportBooking() {
    if (
      this.fastFeedbackResult &&
      Array.isArray(this.fastFeedbackResult.comments)
    ) {
      if (this.fastFeedbackResult.comments.length > 0) {
        const json = this.fastFeedbackResult.comments.map((v) => {
          let dateTime = this.datepipe.transform(v.date, 'dd-MM-yyyy HH:mm');

          let data = {
            ['EMP ID']: v.empId ? v.empId : '-',
            ['EMP NAME']: v.empName ? v.empName : 0,
            ['DEPT NAME']: v.deptName ? v.deptName : '-',
            ['ITEM NAME']: v.itemName ? v.itemName : '-',
            ['RESPONSE TIME']: dateTime ? dateTime : '-',
          };

          if (Array.isArray(v.fastFeedbackResponseAnswers)) {
            v.fastFeedbackResponseAnswers.forEach((answer, index) => {
              let answerData = {
                [`ANSWER${index + 1}`]: answer.answer ? answer.answer : '-',
              };

              Object.assign(data, answerData);
            });
          }

          return data;
        });
        this.optionsJsonToEx.filename = 'Booking-Detail';
        const csvExporter = new ExportToCsv(this.optionsJsonToEx);
        csvExporter.generateCsv(json);
      }
    }
  }

  assignDataToBarChart() {
    this.barChart = 'bar';
    this.dataOfBarChart = {
      labels: [1, 2, 3, 4, 5],
      datasets: [
        {
          data: [
            this.fastFeedbackResult.summary.rate1,
            this.fastFeedbackResult.summary.rate2,
            this.fastFeedbackResult.summary.rate3,
            this.fastFeedbackResult.summary.rate4,
            this.fastFeedbackResult.summary.rate5,
          ],
          backgroundColor: [
            'rgba(255, 96, 92, 0.5)',
            'rgba(255, 96, 92, 0.5)',
            'rgba(255, 189, 68, 0.5)',
            'rgba(0, 202, 78, 0.5)',
            'rgba(0, 202, 78, 0.5)',
          ],
        },
      ],
    };
    let max = Math.max(
      this.fastFeedbackResult.summary.rate1,
      this.fastFeedbackResult.summary.rate2,
      this.fastFeedbackResult.summary.rate3,
      this.fastFeedbackResult.summary.rate4,
      this.fastFeedbackResult.summary.rate5
    );

    let step = max > 10 ? 2 : 1;

    this.optionsBarChart = {
      responsive: true,
      maintainAspectRatio: false,
      scales: {
        yAxes: [
          {
            ticks: {
              display: true,
              min: 0,
              max: max,
              stepSize: step,
            },
            gridLines: {
              display: true,
            },
          },
        ],
        xAxes: [
          {
            gridLines: {
              display: false,
            },
          },
        ],
      },
      legend: {
        display: false,
      },
      tooltips: {
        callbacks: {
          label: function (tooltipItem, data) {
            // var legend = new Array();
            // for(var i in data.datasets){
            //     legend.push(
            //         data.datasets[i].label + ": " + parseFloat(data.datasets[i].data[tooltipItem.index])
            //     );
            // }

            // return legend;
            return `No. Of Response: ${tooltipItem.yLabel}`;
          },
        },
      },
    };
  }

  assignDataToLineChart() {
    let period = [];
    if (Array.isArray(this.fastFeedbackResult.period)) {
      this.fastFeedbackResult.period.forEach((item) => {
        let date = new Date(item);
        let month = date.toLocaleString('en-GB', { month: 'short' });
        let dateDetail = `${date.getDate()} ${month} ${date.getFullYear()}`;
        period.push(dateDetail);
      });
    }

    let dataOfChart = [];
    if (Array.isArray(this.fastFeedbackResult.rate)) {
      if (this.fastFeedbackResult.rate.reduce((a, b) => a + b, 0) > 0) {
        dataOfChart = this.fastFeedbackResult.rate;
      }
    }

    if (dataOfChart.length === 1 && period.length === 1) {
      dataOfChart.push(null);
      dataOfChart.unshift(null);
      period.push('');
      period.unshift('');
    }

    let min = 0;
    let max = 5;
    let step = 1;
    let isCSI = false;
    if (this.fastFeedbackData.feedbackType?.toLowerCase() === 'csi') {
      min = 0;
      max = 100;
      step = 20;
      isCSI = true;
    }

    this.lineChart = 'line';
    this.dataOfLineChart = {
      labels: period,
      datasets: [
        {
          data: dataOfChart,
          type: 'line',
          borderColor: 'rgb(255, 227, 128)',
          backgroundColor: 'rgb(255, 227, 128)',
          fill: false,
        },
      ],
    };
    this.optionsLineChart = {
      responsive: true,
      maintainAspectRatio: false,
      scales: {
        yAxes: [
          {
            ticks: {
              display: true,
              min: min,
              max: max,
              stepSize: step,
              callback: function (value, index, values) {
                if (value <= 100) {
                  return isCSI ? `${values[index]}%` : values[index];
                }
              },
            },
            gridLines: {
              display: true,
            },
          },
        ],
        xAxes: [
          {
            gridLines: {
              display: false,
            },
          },
        ],
      },
      legend: {
        display: false,
      },
      tooltips: {
        callbacks: {
          label: function (tooltipItem, data) {
            return `Average Score: ${tooltipItem.yLabel}`;
          },
        },
      },
    };
  }

  assignDataToHorizontalBarChart() {
    this.horizontalBarChart = 'horizontalBar';
    this.dataOfHorizontalBarChart = {
      labels: this.fastFeedbackResult.booking.map((item) => item.itemName),
      datasets: [
        {
          data: this.fastFeedbackResult.booking.map((item) => item.volume),
          backgroundColor: this.horizontalBackgroundColor(),
        },
      ],
    };

    let total = this.fastFeedbackResult.booking.map(
      (item) => item.volume + item.available
    );
    let max = Math.max(...total);

    let step = 5;

    this.optionsHorizontalBarChart = {
      responsive: true,
      maintainAspectRatio: false,
      indexAxis: 'y',
      scales: {
        xAxes: [
          {
            ticks: {
              min: 0,
              max: max,
              stepSize: step,
            },
          },
        ],
        yAxes: [
          {
            stacked: true,
          },
        ],
      },
      legend: {
        display: false,
      },
      tooltips: {
        callbacks: {
          label: function (tooltipItem, data) {
            return `No. Of booking: ${tooltipItem.xLabel}`;
          },
        },
      },
    };
  }

  horizontalBackgroundColor(): string[] {
    let colors = [];
    for (let i = 0; i < this.fastFeedbackResult.booking.length; i++) {
      let color =
        'rgba(' +
        Math.floor(Math.random() * 255) +
        ',' +
        Math.floor(Math.random() * 255) +
        ',' +
        Math.floor(Math.random() * 255) +
        ',';
      colors.push(color + '0.9)');
    }

    return colors;
  }

  getFeedbackType() {
    if (this.displayStatus === 'import') {
      return this.allFeedbackType.filter((item) => {
        return item.feedbackType === 'vendor evaluation';
      });
    }
    
    return this.allFeedbackType;
  }

  checkHaveComment(): boolean {
    if (Array.isArray(this.fastFeedbackResult.comments)) {
      if (
        this.fastFeedbackResult.comments.filter((item) => {
          return item.message;
        }).length === 0
      ) {
        return false;
      } else {
        return true;
      }
    }

    return false;
  }

  onAddQuestion() {
    this.questionList.push(
      this.fb.group({
        id: [{ value: 0, disabled: false }],
        question: [{ value: '', disabled: false }],
        isRequire: [{ value: false, disabled: false }],
        isActive: [{ value: true, disabled: false }],
        isOther: [{ value: false, disabled: true }],
        type: [{ value: null, disabled: false }],
        possibleValue: this.fb.array([]),
        possibleText: [{ value: '', disabled: true }],
      })
    );
  }

  onAddItem() {
    this.itemList.push(
      this.fb.group({
        id: [{ value: 0, disabled: false }],
        itemName: [{ value: '', disabled: false }],
        volume: [{ value: 0, disabled: false }],
      })
    );
  }

  onDeleteQuestionData() {
    this.fastFeedbackService
      .deleteQuestion(this.deleteQuestionId)
      .toPromise()
      .then((res) => {
        this.isLoading = false;
        this.alert(true, 'This question has been deleted.');
        this.questionList.removeAt(this.deleteQuestionIndex);
      })
      .catch((err) => {
        this.isLoading = false;
        this.alert(false, 'Can not delete this question, Please try again.');
        console.log('err', err);
      });
  }

  onDeleteItemData() {
    this.fastFeedbackService
      .deleteBookingItem(this.deleteItemId)
      .toPromise()
      .then((res) => {
        this.isLoading = false;
        this.alert(true, 'This item has been deleted.');
        this.itemList.removeAt(this.deleteItemIndex);
      })
      .catch((err) => {
        this.isLoading = false;
        this.alert(false, 'Can not delete this item, Please try again.');
        console.log('err', err);
      });
  }

  onDeleteQuestion(template: TemplateRef<any>, status, index) {
    let formDataAt = (<FormArray>this.questionListData).at(index);
    if (formDataAt.value.id) {
      this.onShowConfirmationModal(template, status);
      this.deleteQuestionId = formDataAt.value.id;
      this.deleteQuestionIndex = index;
    } else {
      this.questionList.removeAt(index);
    }
  }

  onDeleteItem(template: TemplateRef<any>, status, index) {
    let formDataAt = (<FormArray>this.itemListData).at(index);
    if (formDataAt.value.id) {
      this.onShowConfirmationModal(template, status);
      this.deleteItemId = formDataAt.value.id;
      this.deleteItemIndex = index;
    } else {
      this.itemList.removeAt(index);
    }
  }

  onAddPossibleValue(index, data) {
    let formDataAt = (<FormArray>this.questionListData).at(index);
    let possibleValueAt = <FormArray>formDataAt.get('possibleValue');
    // console.log('possibleValueAt', possibleValueAt);
    possibleValueAt.push(this.fb.group({ data }));

    formDataAt.patchValue({
      possibleText: '',
    });
  }

  onRemovePossibleValue(questionIndex, possibleValueIndex) {
    let formDataAt = (<FormArray>this.questionListData).at(questionIndex);
    (<FormArray>formDataAt.get('possibleValue')).removeAt(possibleValueIndex);
  }

  onChangeType(index) {
    let formDataAt = this.fb.group(
      (<FormArray>this.questionListData).at(index)
    );
    if (
      formDataAt.controls.controls.value.type.value === 'CHOICE' ||
      formDataAt.controls.controls.value.type.value === 'DROPDOWN'
    ) {
      formDataAt.controls.controls.value.possibleText.enable();
      formDataAt.controls.controls.value.isOther.enable();
    } else {
      formDataAt.controls.controls.value.possibleText.disable();
      formDataAt.controls.controls.value.isOther.disable();

      formDataAt.controls.controls.value.possibleText.setValue('');
      formDataAt.controls.controls.value.isOther.setValue(false);
      formDataAt.controls.controls.value.possibleValue.clear();
    }
  }

  onChangeFeedbackType() {
    if (
      this.fastFeeadbackForm.get('feedbackType').value === 'survey' ||
      this.fastFeeadbackForm.get('feedbackType').value === 'booking'
    ) {
      this.fastFeeadbackFormGroup.requiredComment.disable();
      this.fastFeeadbackForm.patchValue({
        requiredComment: false,
      });
    } else {
      this.fastFeeadbackFormGroup.requiredComment.enable();
    }
  }

  possilbleListData(index) {
    let formDataAt = (<FormArray>this.questionListData).at(index);
    let possibleValueAt = <FormArray>formDataAt.get('possibleValue');

    return possibleValueAt.getRawValue();
  }

  assignDateToString(dateData) {
    var date = new Date(dateData),
      month = '' + (date.getMonth() + 1),
      day = '' + date.getDate(),
      year = date.getFullYear();

    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;

    let formatDate = [year, month, day].join('-');
    return formatDate;
  }

  importPossibleValue(event, index) {
    if (event.target.files && event.target.files.length) {
      let file = event.target.files.item(0);
      let fileReader: FileReader = new FileReader();
      let self = this;
      fileReader.onloadend = function (x) {
        let text = fileReader.result.toString();
        // let details = text.split("\n");
        let details = text.split(/[\r\n|\r|\n]/);
        for (let item of details) {
          if (item) {
            self.onAddPossibleValue(index, item);
          }
        }
      };

      fileReader.readAsText(file);
    }
  }

  importTargetGroup(event) {
    if (event.target.files && event.target.files.length) {
      let file = event.target.files.item(0);
      let fileReader: FileReader = new FileReader();
      let self = this;
      fileReader.onloadend = function (x) {
        let text = fileReader.result.toString();
        let details = text.split(/[\r\n|\r|\n]/);
        for (let item of details) {
          item = item.replace(' ', '');
          if (item) {
            self.onAddTargetGroupTag(item);
          }
        }
      };

      fileReader.readAsText(file);
    }
  }

  onAddTargetGroupTag(email: string) {
    let empDetail = this.employees.find(
      (item) => item.email.toLowerCase() === email.toLowerCase()
    );
    let emp: TargetGroup = this.allTargetGroups.find(
      (item) => item.targetId === empDetail.empID
    );
    if (emp) {
      let data = this.fb.group({
        targetType: [{ value: emp.targetType, disabled: false }],
        name: [{ value: emp.name, disabled: false }],
        targetId: [{ value: emp.targetId, disabled: false }],
        tagType: [{ value: 'TG', disabled: false }],
      });
      this.targetGroupList.push(data);
    }
  }

  alert(isSuccess: boolean, msg: string) {
    return Swal.fire({
      scrollbarPadding: false,
      text: msg,
      title: isSuccess ? 'Successfully' : 'Failed',
      icon: isSuccess ? 'success' : 'error',
      confirmButtonText: 'OK',
      focusCancel: true,
      buttonsStyling: false,
      customClass: {
        confirmButton: 'btn btn-info btn-lg mr-2 text-light',
      },
    });
  }
}

<app-layout>
    <div class="container-fluid mt-4 pl-5 pr-5">
        <div class="form-group row d-flex justify-content-between mt-4">
            <h4 class="col-sm-10 ">
                <b>
                    <span (click)="onBack()" class="mr-2"
                        [ngClass]="displayItem === 'detail' ?  'cursor-pointer' : ''">{{topic}}</span>
                    <span *ngIf="displayItem === 'detail'">
                        <span *ngIf="!isAdminOfStrategicProject">
                            <fa-icon [icon]="faIcon.faChevronRight" class="text-c-set"></fa-icon>
                            <b> {{strategicProjectData ?  strategicProjectData.projectName : ''}}</b>
                        </span>
                        <span *ngIf="isAdminOfStrategicProject">
                            <form [formGroup]="strategicForm" style="width: 50% !important; display: initial;">
                                <fa-icon [icon]="faIcon.faChevronRight" class="text-c-set"></fa-icon>
                                <input formControlName="projectName" (change)="changeProjectName()" class="form-control ml-3" style="width: 50% !important; display: initial;"
                                [ngClass]="{'is-invalid': strategicForm.controls['projectName'].hasError('null')}"
                                >
                            </form>
                        </span>
                    </span>
                    <span *ngIf="displayItem === 'newProject'">
                        <form [formGroup]="strategicForm" style="width: 50% !important; display: initial;">
                            <fa-icon [icon]="faIcon.faChevronRight" class="text-c-set"></fa-icon>
                            <input formControlName="projectName" (change)="changeProjectName()" class="form-control ml-3" style="width: 50% !important; display: initial;"
                            [ngClass]="{'is-invalid': strategicForm.controls['projectName'].hasError('null')}"
                            >
                        </form>
                    </span>
                </b>
            </h4>
            <div *ngIf="displayItem === 'list'"  class="col-sm-2">
                <select class="form-control" [(ngModel)]="selectedYear"
                    (change)="onChangeYear($event.target.value)">
                    <option *ngFor="let year of allYear" [ngValue]="year">
                        {{year}}
                    </option>
                </select>
            </div>
        </div>
        <hr class="set">

        <ng-container [ngSwitch]="displayItem">
            <ng-container *ngSwitchCase="'list'">
                <div *ngIf="!isAdminOfStrategicProject" class="form-group row d-flex justify-content-between">
                    <!-- <h6 class="col-sm-6 col-form-label"><b>Assigned Projects</b></h6> -->
                    <div class="col-sm-12 pr-0 form-group row d-flex justify-content-end">
                        <button type="button" class="btn btn-primary col-sm-auto mr-2" *ngIf="isApprover" (click)="onShowConfirmationModal(template,'onApprove')">Approve</button>
                        <button type="button" class="btn btn-primary col-sm-auto mr-2" *ngIf="isReviewer" (click)="onShowConfirmationModal(template,'onSubmitForApproval')">Submit For Approval</button>
                        <!-- <button type="button" class="btn btn-success col-sm-auto" (click)='onExportExcel()' [disabled]="!checkSelect()">Export Selected</button> -->
                    </div>
                </div>
                <div *ngIf="isAdminOfStrategicProject" class="form-group row d-flex justify-content-between">
                    <div class="col-sm-6 form-group row">
                        <button type="button" class="btn btn-warning col-sm-auto" style="color: white;" (click)="onNewProject()">New project</button>
                        <div class="col-sm-auto pr-0">
                            <select class="form-control" [(ngModel)]="selectedQuarter"
                                (change)="onChangeQuarter($event.target.value)">
                                <option *ngFor="let quarter of allQuarter" [ngValue]="quarter">
                                    {{quarter}}
                                </option>
                            </select>
                        </div>
                    </div>
                    <div class="col-sm-6 form-group row d-flex justify-content-end">
                        <button type="button" class="btn btn-primary col-sm-auto mr-2" (click)="onShowConfirmationModal(template,'sendUpdate')" [disabled]="!checkSelect()">Send Selected</button>
                        <button type="button" class="btn btn-success col-sm-auto" (click)='onExportExcel()' [disabled]="!checkSelect()">Export Selected</button>
                    </div>
                </div>

                <div class="row" style="overflow-x:auto;">
                    <table class="table respnsive-table-style" id="dataTable">
                        <col style="width: 3%;" />
                        <col style="width: 24%;" />
                        <col style="width: 8%;" />
                        <col style="width: 17%;" />
                        <col style="width: 17%;" />
                        <col style="width: 11%;" />
                        <col style="width: 11%;" />
                        <col style="width: 9%;" />
                        <thead class="dt-head">
                            <tr>
                                <th>
                                    NO.
                                </th>
                                <th>
                                    PROJECT
                                </th>
                                <th>
                                    STATUS
                                </th>
                                <th>
                                    <div class="form-group row mb-0">
                                        <span class="mt-auto col-sm-6">MILESTONE</span>
                                        <div *ngIf="!isAdminOfStrategicProject" class="col-sm-5 pr-0">
                                            <select class="form-control" style="height: auto;" [(ngModel)]="selectedQuarter"
                                                (change)="onChangeQuarter($event.target.value)">
                                                <option *ngFor="let quarter of allQuarter" [ngValue]="quarter">
                                                    {{quarter}}
                                                </option>
                                            </select>
                                        </div>
                                    </div>
                                </th>
                                <th>
                                    CURRENT PROGRESS
                                </th>
                                <th>
                                    OWNER / REPORTER
                                </th>
                                <th>
                                    LAST UPDATED
                                </th>
                                <th>
                                    <div class="form-group row mb-0">
                                        <div class="col-sm-4 pr-0">
                                            <input type="checkbox" *ngIf="isAdminOfStrategicProject" (click)="onSelectAll()" [(ngModel)]="isSelectedAll" class="form-control" style="width: 1.5rem; height: 1.5rem;" /> 
                                            <input type="checkbox"  *ngIf="!isAdminOfStrategicProject && (isApprover || isReviewer)" (click)="onSelectAll()" [(ngModel)]="isSelectedAll" class="form-control" style="width: 1.5rem; height: 1.5rem;" /> 
                                        </div>
                                        <!-- <span class="mt-auto col-sm-7">SELECT</span> -->
                                    </div>
                                    <!-- SELECT<input type="checkbox" (click)="onSelectAll()" [(ngModel)]="isSelectedAll" class="form-control" style="width: 1.5rem; height: 1.5rem;" /> -->
                                </th>
                            </tr>
                        </thead>
                        <tbody *ngIf="strategicProjectList">
                            <tr *ngFor="let strategicProject of strategicProjectListPerPage; let i = index">
                                <td>
                                    <p *ngIf="currentPage">{{ (i)+((currentPage-1)*paginationConfig.itemsPerPage) +1 }}</p>
                                </td>
                                <td>
                                    <!-- <div [ngClass]="checkAccess(strategicProject) ?  'link-style' : ''" (click)="handleOnDetailStrategicProject(strategicProject)">
                                        {{strategicProject.projectName ? strategicProject.projectName : '-'}} 
                                        <img src="assets/images/strategic-project/kpi_meter.png" *ngIf="strategicProject.isCorporateKPIs" class="image-responsive ml-2 mb-1">
                                    </div> -->
                                    <div class="link-style" (click)="handleOnDetailStrategicProject(strategicProject)">
                                        {{strategicProject.projectName ? strategicProject.projectName : '-'}} 
                                        <img src="assets/images/strategic-project/kpi_meter.png" *ngIf="strategicProject.isCorporateKPIs" class="image-responsive ml-2 mb-1">
                                    </div>
                                    <div class="row py-2" *ngIf="checkDisplayBudgetProject(strategicProject)">
                                        <div class="col-sm-6" >
                                            <div class="row">
                                                <div class="col-sm-6 pt-2 pb-2 pl-0 pr-0 text-center" style="background-color: #90c978; color: white; font-size: 12px;">
                                                    <b>CAPEX</b>
                                                </div>
                                                <div class="col-sm-6 pt-2 pb-2 pl-0 pr-0 text-center" style="background-color: #C6DDAF; font-size: 12px;">
                                                    {{(strategicProject.capex / 1000000) | number : '1.2-2'}} MB
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-sm-6 pt-2 pb-2 pl-0 pr-0 text-center" style="background-color: #90c978; color: white; font-size: 12px;">
                                                    <b>OPEX</b>
                                                </div>
                                                <div class="col-sm-6 pt-2 pb-2 pl-0 pr-0 text-center" style="background-color: #C6DDAF; font-size: 12px;">
                                                    {{(strategicProject.opex / 1000000) | number : '1.2-2'}} MB
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-sm-5 pl-0 pr-0" [ngClass]="strategicProject.budgetUtilization < 30 ?  'budget-warning-style' : 'budget-utilization-style'">
                                            <div class="pl-0 pr-0 text-center">
                                                <b style="font-size: 12px;" [ngClass]="strategicProject.budgetUtilization < 30 ?  'budget-warning-text-style' : 'budget-utilization-text-style'">Budget Utilization</b>
                                            </div>
                                            <div class="pt-3 pb-2 pl-0 pr-0 text-center" style="font-size: 12px;" [ngClass]="strategicProject.budgetUtilization < 30 ?  'budget-warning-text-style' : 'budget-utilization-text-style'">
                                                {{strategicProject.budgetUtilization | number : '1.2-2'}} %
                                            </div>
                                        </div>
                                    </div>
                                </td>
                                <td class="text-center" *ngIf="strategicProject.targets">
                                    <div *ngIf="strategicProject.targets.length > 0">
                                        <div *ngFor="let target of strategicProject.targets; let j = index " class="mb-3">
                                            <div *ngIf="getMilestoneStatus(target) == 'G'"
                                                class="circle-status bg-success ml-auto mr-auto row">
                                                <span class="d-flex align-items-center ml-auto mr-auto"
                                                    style="font-size: 1.5rem;">G</span>
                                            </div>
                                            <div *ngIf="getMilestoneStatus(target) === 'Y'"
                                                class="circle-status bg-warning ml-auto mr-auto row">
                                                <span class="d-flex align-items-center ml-auto mr-auto"
                                                    style="font-size: 1.5rem;">Y</span>
                                            </div>
                                            <div *ngIf="getMilestoneStatus(target) === 'R'"
                                                class="circle-status bg-danger ml-auto mr-auto row">
                                                <span class="d-flex align-items-center ml-auto mr-auto"
                                                    style="font-size: 1.5rem;">R</span>
                                            </div>
                                            <!-- <div *ngIf="getMilestoneStatus(target) === '' && j === 0"
                                            class="circle-status bg-secondary ml-auto mr-auto row">
                                            <span class="d-flex align-items-center ml-auto mr-auto"
                                                style="font-size: 1.5rem;">R</span>
                                            </div> -->
                                        </div>
                                    </div>
                                    <div *ngIf="strategicProject.targets.length === 0 || !strategicProject.targets">
                                        <div class="circle-status bg-secondary ml-auto mr-auto row">
                                            <span class="d-flex align-items-center ml-auto mr-auto" style="font-size: 1.5rem;">G
                                            </span>
                                        </div>
                                    </div>
                                </td>
                                <td *ngIf="!strategicProject.targets">
                                </td>
                                <td *ngIf="strategicProject.targets">
                                    <div *ngIf="strategicProject.targets.length > 0">
                                        <div *ngFor="let target of strategicProject.targets" style="white-space: break-spaces;" class="mb-3">
                                            {{getMilestoneDetail(target)}}
                                        </div>
                                    </div>
                                    <div *ngIf="strategicProject.targets.length === 0">
                                        -
                                    </div>
                                </td>
                                <td *ngIf="!strategicProject.targets">
                                    -
                                </td>
                                <td *ngIf="strategicProject.targets">
                                    <div *ngIf="strategicProject.targets.length > 0">
                                        <div *ngFor="let target of strategicProject.targets" style="white-space: break-spaces;" class="mb-3">
                                            <p [innerHTML]="getProgressDetail(target)">{{getProgressDetail(target)}}</p>
                                        </div>
                                    </div>
                                    <div *ngIf="strategicProject.targets.length === 0">
                                        -
                                    </div>
                                </td>
                                <td *ngIf="!strategicProject.targets">
                                    -
                                </td>
                                <td>
                                    <div *ngIf="strategicProject.approver">
                                        <div *ngIf="strategicProject.approver.length > 0">
                                            <div *ngFor="let approver of strategicProject.approver">
                                                {{getNameByEmail(approver)}}
                                            </div>
                                        </div>
                                    </div>
                                    <div *ngIf="strategicProject.coApprover">
                                        <div *ngIf="strategicProject.coApprover.length > 0">
                                            <div *ngFor="let coApprover of strategicProject.coApprover">
                                                {{getNameByEmail(coApprover)}}
                                            </div>
                                        </div>
                                    </div>
                                    <!-- <div *ngIf="strategicProject.reviewer">
                                        <div *ngIf="strategicProject.reviewer.length > 0">
                                            <div style="color: #C0C0C0;" *ngFor="let reviewer of strategicProject.reviewer">
                                                {{getName(reviewer)}}
                                            </div>
                                        </div>
                                    </div>
                                    <div *ngIf="strategicProject.coReviewer">
                                        <div *ngIf="strategicProject.coReviewer.length > 0">
                                            <div style="color: #C0C0C0;" *ngFor="let coReviewer of strategicProject.coReviewer">
                                                {{getName(coReviewer)}}
                                            </div>
                                        </div>
                                    </div> -->
                                    <div *ngIf="strategicProject.creator">
                                        <div *ngIf="strategicProject.creator.length > 0">
                                            <div style="color: #C0C0C0;" *ngFor="let creator of strategicProject.creator">
                                                {{getNameByEmail(creator)}}
                                            </div>
                                        </div>
                                    </div>
                                </td>
                                <td *ngIf="!strategicProject.targets">
                                    <div>
                                        <p>{{findStakeHolder(strategicProject.strategicProjectWorkflowStatus)}}</p>
                                        <p style="color: #C0C0C0;">{{findActionTime(strategicProject.strategicProjectWorkflowStatus) | date: 'dd MMM yyyy HH:mm'}}</p>
                                    </div>
                                </td>
                                <td *ngIf="strategicProject.targets">
                                    <div *ngIf="strategicProject.targets.length > 0">
                                        <div *ngFor="let target of filterDuplicateLastUpdate(strategicProject.targets)" class="mb-3">
                                            <p>{{getLastUpdateBy(target,strategicProject.strategicProjectWorkflowStatus)}}</p>
                                            <p style="color: #C0C0C0;">{{getLastUpdate(target,strategicProject.strategicProjectWorkflowStatus) | date: 'dd MMM yyyy HH:mm'}}</p>
                                        </div>
                                    </div>
                                    <div *ngIf="strategicProject.targets.length === 0">
                                        <p>{{findStakeHolder(strategicProject.strategicProjectWorkflowStatus)}}</p>
                                        <p style="color: #C0C0C0;">{{findActionTime(strategicProject.strategicProjectWorkflowStatus) | date: 'dd MMM yyyy HH:mm'}}</p>
                                    </div>
                                </td>


                                <!-- <td *ngIf="strategicProject.updateDate">
                                    {{strategicProject.updateDate | date: 'dd MMM yyyy'}}
                                </td>
                                <td *ngIf="!strategicProject.updateDate">
                                    -
                                </td> -->
                                <td>
                                    <div *ngIf="!isAdminOfStrategicProject && strategicProject.strategicProjectWorkflowStatus" class="form-group row ml-0">
                                        <input *ngIf="checkReviewable(strategicProject)" type="checkbox" [(ngModel)]="strategicProject.isSelected" (click)="onSelect(strategicProject)" class="form-control col-sm-auto cursor-pointer" style="width: 1.5rem; height: 1.5rem;" />
                                        <input *ngIf="checkApprovable(strategicProject)" type="checkbox" [(ngModel)]="strategicProject.isSelected" (click)="onSelect(strategicProject)" class="form-control col-sm-auto cursor-pointer" style="width: 1.5rem; height: 1.5rem;" />
                                        <p class="link-style pr-0 pl-2" [ngClass]="{'col-sm-9':checkApprovable(strategicProject),'col-sm-auto':!checkApprovable(strategicProject)}"  (click)="handleOnDetailStrategicProject(strategicProject)">{{displayStatus(strategicProject.strategicProjectWorkflowStatus)}}</p>
                                    </div>
                                    <div *ngIf="isAdminOfStrategicProject" class="form-group row ml-0">
                                        <input *ngIf="checkWorkflowStatus(strategicProject)" type="checkbox" [(ngModel)]="strategicProject.isSelected" (click)="onSelect(strategicProject)" class="form-control col-sm-auto cursor-pointer" style="width: 1.5rem; height: 1.5rem;" />
                                        <p class="link-style pr-0 pl-2" [ngClass]="{'col-sm-9':checkApprovable(strategicProject),'col-sm-auto':!checkApprovable(strategicProject)}"  (click)="handleOnDetailStrategicProject(strategicProject)">{{displayStatus(strategicProject.strategicProjectWorkflowStatus)}}</p>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                        <tbody *ngIf="!strategicProjectList">
                            <td class="text-center" *ngIf="!strategicProjectList" colspan="8">
                                No data.
                            </td>
                        </tbody>
                    </table>
                    <div *ngIf="strategicProjectList">
                        <div *ngIf="strategicProjectList.length > 10">
                        <pagination [totalItems]="strategicProjectList.length" [(ngModel)]="currentPage" [itemsPerPage]="paginationConfig.itemsPerPage"
                            (pageChanged)="handlePaging($event)">
                        </pagination>
                        </div>
                    </div>
                </div>
            </ng-container>
            <ng-container *ngSwitchCase="'detail'">
                <app-strategic-project-detail [allName]="allName" [strategicProjectData]="strategicProjectData" [quarterData]="quarter" [pageData]="currentPage"
                    (onEmitData)="switchDisplayItem($event)" [strategicProjectName]="strategicProjectName"></app-strategic-project-detail>
            </ng-container>
            <ng-container *ngSwitchCase="'newProject'">
                <app-new-strategic-project [strategicProjectYear]="selectedYear" [strategicProjectName]="strategicProjectName" (onEmitData)="switchDisplayItem($event)" (onCheckStrategicProjectName)="validateStrategicProjectName()"></app-new-strategic-project>
            </ng-container>
        </ng-container>

    </div>
</app-layout>

<ng-template #template>
    <div class="modal-header thead-primary" style="background-color: #fab232;">
        <h4 class="modal-title pull-left ml-3">Confirmation</h4>
        <button type="button" class="close pull-right text-light" aria-label="Close" (click)="modalRef.hide()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div class="form-group ml-3">
            <div class="d-flex">
                <h5>{{ confirmationWording }}</h5>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <div class="d-flex mt-2 justify-content-end">
            <button class="btn btn-success mr-2" (click)="confirm()" [disabled]="isLoading" >Confirm</button>
            <button class="btn btn-danger" (click)="modalRef.hide()">Cancel</button>
        </div>
    </div>
</ng-template>
